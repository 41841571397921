.export-loading-indicator {
	height: 100%;
	width: 100%;
	display: none;
	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 90vh;
	}
}
