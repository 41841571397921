.advanced-filter-builder {
	display: flex;
	position: relative;
	padding: #{$spacing-unit}px #{$spacing-unit}px #{$spacing-unit * 2}px #{$spacing-unit}px;

	flex-grow: 1;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;

	&__input {
		display: flex;
		&--focused {
			border: 1px solid $border-focus;
			transition: border 0.3s;
		}
		background-color: $textarea-background;
		border: 1px solid $border-foreground;
		transition: border 0.3s;
		padding: 10px #{$spacing-unit}px #{$spacing-unit}px 5px;
		border-radius: 10px;
		height: 100%;
		position: relative;
		box-sizing: border-box;

		&--loading {
			> :not(:first-child) {
				margin-top: 8px;
			}
		}

		.CodeMirror {
			width: 100%;
			max-height: unset;
			&-scroll {
				max-width: 97%;
			}
		}

		&__tools {
			display: flex;
			position: absolute;
			right: 0;
			z-index: 2;
			height: 30px;
			margin: #{$spacing-unit * 0.5}px #{$spacing-unit}px;

			.toolbar-button {
				margin: 0 #{$spacing-unit * 0.5}px;
			}
			&__search-button {
				.icon-button {
					background-color: $primary-color;
					svg {
						color: $white;
					}
					&:hover {
						background: $primary-background-hover;
					}
					&--disabled {
						background: $font-disabled;
					}
				}
			}
		}
	}

	.validation-indicator {
		right: unset;
		left: 27px;
		top: 23px;
		bottom: unset;
		z-index: 1;
	}

	.filter-widget {
		padding: 0px 14px;

		&--edit {
			padding: 0;
		}

		.textfield-container {
			height: 33px;
			> .MuiInputBase-input {
				height: 33px;
			}
			input {
				height: 33px;
				font-size: 14px;
				padding: 0 14px;
			}
		}
		.select {
			&__control {
				min-height: unset;
				height: 33px;
			}
		}
	}

	#filter-widget-portal {
		z-index: 100000;
		position: absolute;

		.select {
			&__menu {
				white-space: pre-wrap;
				margin-top: 0;
				background-color: $app-background-inset;
				border: 1px solid $border-foreground;
			}

			&__value-container {
				padding: 0;
			}

			&__option {
				&--is-disabled {
					color: #999999 !important;
					padding: 8px 12px;
					text-align: center;
					box-sizing: border-box;
				}
				&--is-focused:not(&--is-selected) {
					background-color: darken($app-background-inset, 2%);
					color: $font-color !important;
				}
			}
		}
	}
}

.boolean-fixer-prompt {
	z-index: 1501;
	.confirmation-prompt__container__main {
		width: 400px;
		max-width: unset;
		&__content {
			max-width: unset;
		}
		.MuiAlert-standardSuccess {
			background-color: $confirm-background-light;
			color: $confirm-font-color;
			margin: #{$spacing-unit * 2}px 0 #{$spacing-unit}px;
		}
		.boolean-fixer-loading {
			margin: #{$spacing-unit * 2.5}px 0;
			text-align: center;
		}
	}
}
