@use 'sass:math';

.checkable-select-filter {
	width: 100%;
	&__content {
		display: flex;
		flex-direction: column;
	}
}

.MuiAutocomplete-popperDisablePortal {
	> div {
		border: 1px solid $border-foreground;
	}
}

.checkable-option {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0;
	margin: 0;

	&__icon {
		height: 20px;
		width: 20px;
		margin-right: 8px;
	}

	&__label {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	&:hover {
		cursor: pointer;
		background-color: $app-background-inset-hover;
	}

	.checkbox {
		&__control {
			&__control {
				margin-right: 0 !important;
				background-color: transparent !important;
			}
		}
	}
}
