.sheet-layout {
	height: 100%;
	display: flex;
	flex-direction: column;
	&__header {
		padding: #{$spacing-unit * 3}px #{$spacing-unit * 5}px;
		flex-shrink: 0;
	}
	&__body {
		flex-grow: 1;
		&__content {
			padding: #{$spacing-unit * 3}px #{$spacing-unit * 5}px;
		}
	}
	&__footer {
		padding: #{$spacing-unit * 3}px #{$spacing-unit * 5}px;
		border-top: 1px solid $border-foreground-light;
		flex-shrink: 0;
	}
}
