.carousel {
	display: flex;
	align-items: center;
	max-width: 100%;

	&__arrow {
		font-size: 1.5em;
		color: $grey-3;
		cursor: pointer;

		&:hover {
			color: $font-color;
		}

		&--hidden {
			visibility: hidden;
		}
	}

	&__container {
		flex: 1 0 100%;
		display: flex;
		align-items: stretch;
		overflow: hidden;

		&__child {
			flex: 0 0 auto;
			transition: transform 0.5s;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
		}
	}
}
