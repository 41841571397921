.recipients-activities-table {
	display: flex;

	.select {
		@include selectStyling;
		&__menu {
			.select__menu-list {
				background-color: $app-background;
				color: $font-color;
				border-color: $font-color;
			}
		}
	}
}
