.snackbar {
	svg {
		fill: $white;
		color: $white;
	}
	.icon {
		font-size: 20px;
		&--variant {
			margin-right: #{$spacing-unit}px;
		}
		color: $white;
	}
	.message {
		display: flex;
		align-items: center;
		color: $white;
	}
	&--success {
		background-color: $confirm-background;
	}
	&--warning {
		background-color: $warning-background;
	}
	&--info {
		background-color: $primary-background;
	}
	&--error {
		background-color: $error-background;
	}
}
