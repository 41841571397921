@use 'sass:math';

.benchmark {
	white-space: nowrap;
	&.red {
		color: $red-1;
	}

	&.green {
		color: $green-1;
	}

	&.grey {
		color: $font-light;
	}

	&__indicator {
		margin-right: #{math.div($spacing-unit, 2)}px;
	}
}

.chart-header {
	.date-picker-button {
		border: unset;
	}
}

.chart-container--am-chart {
	.amcharts-Scrollbar-group {
		display: none;
	}
	&:hover {
		.amcharts-Scrollbar-group {
			display: unset;
		}
	}
}

.export-root__html-export__page {
	.dashboard-chart {
		.chart-header {
			.filter-summary,
			.chart-drag-handle,
			.chart-label-container,
			.chart-end-adornment {
				display: none;
			}
		}
		.chart-print-header {
			display: block;
		}
		.amcharts-amexport-menu {
			display: none;
		}
		.am-chart-v4 {
			&--padded {
				padding: 0;
			}
			border: none;
			.chart-container--am-chart {
				margin-top: 40px;
			}
		}
		.custom-chart {
			border: none;
			&__content-container {
				padding-left: 0px;
				.card-chart__content {
					margin-left: 0px;
				}
			}

			&__media-list-chart {
				padding: 0 20px;
				.chart-header {
					padding: 0 0 20px;
				}
			}
		}
		.multi-chart {
			box-sizing: border-box;
			flex-direction: column;
			.custom-chart {
				&__custom-container {
					padding: 0;
					height: 140px;
				}
			}
			.chart-print-header {
				&__content {
					&__left {
						padding-right: #{$spacing-unit}px;
					}
				}
			}
			> .MuiGrid-container {
				margin: 40px auto 0 auto;
				width: 80%;
			}
			&--count-4 {
				> .MuiGrid-container {
					flex-direction: row;
					flex-wrap: wrap;

					> .MuiGrid-item {
						padding: #{$spacing-unit * 4}px;
						flex: 1 0 50%;
						max-width: 350px;
					}
				}
			}
		}
		.media-item-list-item__without_controls__wrapper__headlineWrapper__info-card-hover {
			overflow: hidden;
			max-width: 250px;
		}
	}

	.benchmark {
		&__indicator {
			display: none;
		}
	}
}

.simple-chart {
	&--no-click-event {
		.table-chart__table__body__row:hover,
		.amcharts-Circle-group,
		.amcharts-Slice,
		.amcharts-XYSeries .amcharts-RoundedRectangle-group {
			cursor: default;
		}
	}
	height: 400px;
	width: 100%;
	display: flex;
	flex-direction: column;
	&--border {
		.am-chart-v4,
		.custom-chart {
			box-sizing: border-box;
			border: 1px solid $border-foreground;
			.chart-header {
				box-sizing: border-box;
			}
			&--padded {
				padding: #{$spacing-unit * 3}px;
				box-sizing: border-box;
			}
			&__media-list-chart {
				.chart-header {
					padding: #{$spacing-unit * 3}px #{$spacing-unit * 2}px 0 #{$spacing-unit * 2}px;
				}
			}
		}
	}
	.filter-summary {
		margin-left: #{$spacing-unit}px;
	}
	.card-chart__content__value-container__value {
		margin: 0px;
	}
}

.chart-label-container {
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
	align-self: center;
	.chart-label {
		font-size: $typography-extra-large;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: #{$spacing-unit * 2}px;
	}
	.click-to-edit-field {
		.typography {
			font-size: $typography-extra-large;
		}
	}
}

.multi-chart {
	flex-grow: 1;
}

.custom-chart,
.am-chart-v4 {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.table-chart,
	.distribution-chart,
	.card-chart,
	.chart-container--am-chart {
		flex-grow: 1;
	}

	.distribution-chart {
		&__table__body__row__cell {
			.rounded-bar-chart {
				p {
					word-wrap: normal;
					word-break: normal;
				}
			}
		}
	}
}

.spike-alert-chart {
	height: 300px;
	padding-bottom: #{$spacing-unit * 2}px;
}

.report-chart {
	height: unset;
	.card-chart,
	.distribution-chart,
	.table-chart {
		padding: 0 #{math.div($spacing-unit, 2)}px;
	}
	.card-chart__content__value-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		&__value {
			margin: unset;
		}
	}
	.no-chart-data-message {
		min-height: 200px;
	}
	.amcharts-Tooltip,
	.amcharts-AxisLabel,
	.amcharts-Legend,
	.distribution-chart__table__column,
	.distribution-chart__table td .typography--body,
	.table-chart__table__column,
	.table-chart__table td .typography--body {
		font-size: 15px;
	}
	.table-chart-menu {
		&__menu {
			display: none;
		}
	}

	.chart-container--am-chart {
		padding: 0;
		height: 325px;
	}

	.am-chart-v4,
	.custom-chart {
		.chart-header {
			&__top-row {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}

.multi-chart {
	.no-chart-data-message {
		height: 51px !important;
		min-height: unset;
		svg {
			margin-bottom: 1px;
		}
	}
}

.insights-chart {
	height: unset;
	.chart-container--am-chart {
		margin-top: -#{$spacing-unit * 2}px;
		height: 150px;
	}
	.custom-chart {
		height: 100%;
	}
	.chart-label-container {
		background-color: $app-background-inset;
		padding: #{$spacing-unit}px #{$spacing-unit * 1.5}px;
		border-radius: $border-radius;
		margin-bottom: #{$spacing-unit * 2}px;
	}
	.distribution-chart,
	.table-chart {
		padding: 0 #{$spacing-unit}px;
		box-sizing: border-box;
		&__table {
			td {
				padding: #{$spacing-unit}px 0px 4px;
				border-bottom: 0px;
			}
			&__body {
				tr {
					td:first-child {
						padding-right: #{$spacing-unit * 2}px;
						//in order to support ellipse text, we need to set a fixed width on the left label column of the table
						max-width: 170px;
					}
				}
			}
		}
	}
}

.dashboard-chart {
	height: 100%;

	.chart__custom-multi-container {
		.chart__custom-container--filter-summary-empty {
			padding: 20px #{$spacing-unit * 2}px;
		}
	}
	.am-chart-v4,
	.custom-chart {
		border-radius: $border-radius;
		background-color: $app-background;
		height: 100%;
		.chart-header {
			box-sizing: border-box;
			&__top-row {
				display: flex;
				justify-content: space-between;
			}
		}
		&--padded {
			padding: #{$spacing-unit}px #{$spacing-unit * 2}px;
			.chart-header {
				padding: 0;
			}
		}
		&__media-list-chart {
			.chart-header {
				padding: #{$spacing-unit}px #{$spacing-unit * 2}px 0 #{$spacing-unit * 2}px;
			}
		}
	}
	.card-chart {
		margin-left: #{$spacing-unit}px;
	}
	.distribution-chart {
		margin-left: 4px;
	}
	.chart-loader-container {
		padding: #{$spacing-unit * 2}px #{$spacing-unit * 2}px;
		border-radius: $border-radius;
		border: 1px solid $border-foreground;
	}
	.chart-loader-container--nested-charts {
		padding: unset;
		border-radius: unset;
		border: unset;
	}

	.chart-drag-handle {
		cursor: grab;
		display: flex;
		align-items: center;
		margin-right: #{math.div($spacing-unit, 2)}px;
		svg {
			path {
				fill: $font-color;
				stroke: $font-color;
			}
		}
	}

	&--draggable {
		&:hover {
			background-color: $media-item-selected;
		}
	}
	.add-to-report-anchor-element {
		position: absolute;
		top: 0;
		right: 0;
	}
	&:hover {
		.table-chart-menu__menu {
			opacity: 1;
		}
	}
}
