#appcues-launchpad {
	position: absolute;
	bottom: 60px;
	display: none;
	font-family: Roboto, 'IBM Plex Sans', sans-serif;
	&.appcues-widget {
		display: unset;

		.appcues-icon-bell.appcues-widget-icon.appcues-icon.appcues-icon-visible {
			&:before {
				color: $font-color;
				font-size: 26px;
				content: 'f';
			}
			&:after {
				background: $primary-color;
			}
		}
	}
}
.appcues-widget-container.appcues-active {
	display: none;
	.appcues-widget-dropdown {
		background-color: $app-background;
		font-family: Roboto, 'IBM Plex Sans', sans-serif;
		padding: 5px 15px;
		border-radius: 4px;
		color: $font-color;
		box-shadow: $container-box-shadow;
		border-color: $border-foreground;

		&:after,
		&:before {
			display: none;
		}

		.appcues-widget-header {
			border: none;
		}

		.appcues-widget-content {
			margin-bottom: 10px;
			.appcues-widget-list {
				border: none;

				li {
					border-color: $border-foreground;
				}

				.appcues-nothing-new {
					color: $font-color;
				}
				.appcues-unread {
					a {
						color: $font-color;
					}
				}
				.appcues-read {
					a {
						color: $font-light;
					}
				}
				a {
					font-size: 0.9rem;
					&:hover {
						color: $primary-color;
						background: transparent;
					}

					time {
						font-size: 0.9rem;
					}
				}
			}
		}
		.appcues-widget-header {
			h1 {
				color: $font-color;
				font-size: 1.25rem;
			}
		}
	}
}
