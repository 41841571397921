@use 'sass:math';

$content-explorer-input-border-radius: 20px;

.query-content-explorer {
	background-color: $app-background-inset;
	padding: #{$spacing-unit * 5}px #{$spacing-unit * 4}px;
	box-sizing: border-box;
	border-bottom: 1px solid $border-foreground;

	@include breakpoint-up('md') {
		padding: #{$spacing-unit * 6}px #{$spacing-unit * 4}px;
	}

	.icon-button {
		svg {
			color: $icon-foreground;
		}
	}

	&__title {
		font-size: $typography-h3;
		@include breakpoint-up('md') {
			font-size: $typography-h1;
		}
	}

	&__tabs {
		margin-top: #{$spacing-unit * 2}px;
		.MuiTab-root {
			box-sizing: border-box;
			text-transform: unset;
			padding: 0 #{$spacing-unit * 6}px;
			font-weight: $weight-regular;
			&.Mui-selected {
				font-weight: $weight-bold;
				.MuiChip-root {
					background-color: $primary-background;
				}
			}
		}
		.MuiTabs-root {
			border-bottom: 1px solid $border-foreground;
		}
		&__experimental-feature-tab-label {
			display: flex;
			align-items: center;
			.MuiChip-root {
				margin-left: #{$spacing-unit}px;
				border-radius: 6px;
				height: unset;
				background-color: $tooltip-background-color;
				color: $tooltip-text-color;
			}
			.MuiChip-label {
				font-weight: $weight-bold;
				font-size: $typography-extra-tiny;
				padding: 4px 4px;
			}
		}
		&__content {
			margin-top: #{$spacing-unit * 3}px;
			//these two properties are needed to make the animated borders work. WHY. I DONT KNOW
			position: relative;
			z-index: 0;
		}
	}
}

.simple-search {
	display: flex;
	border: 1px solid $border-foreground;
	background-color: $textarea-background;
	border-radius: $content-explorer-input-border-radius;
	position: relative;
	&:focus-within {
		border-color: $border-focus;
		.simple-search__input-container__or {
			border-color: $border-focus;
		}
	}
	&__input-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		@include breakpoint-up('md') {
			flex-direction: row;
			> * {
				flex: 1 auto 33.34%;
			}
			.simple-search {
				&__input-container {
					&__or {
						border-width: 0 1px;
					}
				}
			}
		}
		.MuiInputBase-inputMultiline {
			padding-left: #{$spacing-unit * 1.75}px;
		}
		.edit-chip {
			.MuiInputBase-input {
				margin-left: #{$spacing-unit}px;
			}
		}
		> * {
			padding: #{$spacing-unit * 2}px #{$spacing-unit * 3}px;
			flex: 1 0 0;
		}
		&__or {
			border-style: solid;
			border-width: 1px 0;
			border-color: $border-foreground;
		}
		.chip-textfield__container {
			border: none;
			.chip-textfield__container__input-container {
				padding: 0;
			}
			.edit-chip {
				max-width: 250px;
				min-width: 30px;
			}
			.MuiChip-label {
				max-width: 250px;
				text-overflow: ellipsis;
			}
		}
	}
	&__end-adornment {
		position: absolute;
		top: 8px;
		right: 8px;
	}
}

.boolean-search {
	border: 1px solid $border-foreground;
	&:focus-within {
		border-color: $border-focus;
	}
	background-color: $textarea-background;
	border-radius: $content-explorer-input-border-radius;
	display: flex;
	position: relative;
	.CodeMirror {
		flex-grow: 1;
		margin: #{$spacing-unit}px;
	}
	&__end-adornment {
		position: absolute;
		right: 0;
		z-index: 2;
		padding: #{$spacing-unit}px #{$spacing-unit * 2}px #{$spacing-unit}px 0;
	}
}

.content-explorer-toolbar-button {
	button.Mui-disabled {
		background-color: $app-background-inset;
	}
	button {
		height: 38px;
		width: 38px;
	}
	&--active {
		.icon-button {
			background-color: $primary-background;
			svg {
				color: $primary-foreground;
			}
		}
	}
	&--invalid {
		padding: 10px 0;
		.icon-button {
			svg {
				color: $white;
			}
		}
	}
	.MuiCircularProgress-root {
		height: 24px !important;
		width: 24px !important;
	}
}
