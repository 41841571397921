button.MuiIconButton-root {
	.MuiSvgIcon-root {
		color: $icon-foreground;
	}
}

.icon {
	color: $icon-foreground;
	height: 20px;
	width: 20px;
	&--md {
		height: 42px;
		width: 42px;
	}
	&--lg {
		height: 70px;
		width: 70px;
	}
}

.MuiIconButton-colorInherit {
	svg {
		color: $icon-foreground;
	}
}
svg.icon {
	&--sm {
		height: 20px;
		width: 20px;
	}
	&--xs {
		height: 16px;
		width: 16px;
	}
}
