@use 'sass:math';

.quick-search {
	display: flex;
	flex-grow: 1;
	align-items: center;
	padding: #{$spacing-unit * 1.25}px #{$spacing-unit * 1.5}px;

	&__end-adornment {
		flex-shrink: 0;
		margin-left: #{$spacing-unit}px;
	}

	&__input-container {
		flex-grow: 1;
		min-width: 0;
		background-color: $quick-search-background;
		border-radius: 25px;
		display: flex;
		align-items: center;
		padding: #{$spacing-unit * 0.25}px #{$spacing-unit}px;
		position: relative;

		&:hover {
			background-color: $quick-search-background-hover;
		}

		div[class*='quick-search-filters'][class*=' ']::after {
			content: '';
			width: 30px;
			height: 46px;
			position: absolute;
			background-image: linear-gradient(to right, rgba(255, 0, 0, 0), $quick-search-background);
			right: 90px;
			@include breakpoint-up('sm') {
				right: 240px;
			}
		}

		div[class*='quick-search-filters'][class*='quick-search-filters--scroll-left-max']::after {
			display: none;
		}

		.quick-search-filters {
			//hide scrollbar under quick search bar
			&::-webkit-scrollbar {
				display: none;
			}
			display: flex;
			align-items: center;
			overflow-y: hidden;
			overflow-x: scroll;
			scroll-behavior: smooth;
			scrollbar-width: none;
			padding: 3px 0;

			> :not(:first-child) {
				margin-left: #{$spacing-unit}px;
			}
		}

		.filter-input {
			flex-grow: 1;
			&__input-wrapper {
				display: flex;
				flex-grow: 1;
				> .MuiInputBase-root {
					min-width: 50px;
					@include breakpoint-up('sm') {
						min-width: 200px;
					}
				}
			}
		}
	}

	.date-picker-button {
		border: unset;
		border-radius: 25px;
	}
}
