.report-move-menu {
	.header {
		color: #8493a6 !important;
	}
	.MuiPopover-paper,
	.xp__summary,
	.xp__details {
		background: white;
		color: #2b4265;
		svg {
			fill: #2b4265;
		}
	}
	.Mui-disabled,
	.MuiTypography-root {
		color: #2b4265;
	}
	.Mui-disabled {
		opacity: 0.4;
	}
	.xp__summary.Mui-expanded,
	.xp__details .MuiListItemButton-root:hover,
	.xp__summary:hover {
		background-color: #f2f5f7 !important;
	}
}
