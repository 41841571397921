@use 'sass:math';

.alerts {
	&__empty-state {
		display: flex;

		div:first-child {
			flex: 1 0;
		}

		&__content {
			.typography--h5 {
				margin-bottom: #{$spacing-unit}px;
			}
			&__link {
				display: flex;
				align-items: center;
				margin-top: #{$spacing-unit * 4}px;

				&__info {
					line-height: 0;
				}

				a {
					cursor: pointer;
					margin-left: 10px;
				}
			}
		}
	}

	&__table {
		margin-top: 50px;
		border: none;

		&__date,
		&__count,
		&__menu {
			color: $font-light;
		}

		&__menu {
			display: flex;
		}

		&--loading {
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			display: flex;
			background-color: darken($app-background, 2%);
		}

		.MuiDataGrid-iconButtonContainer {
			margin: 0 #{math.div($spacing-unit, 2)}px;
			.MuiIconButton-sizeSmall {
				padding: #{math.div($spacing-unit, 2)}px;
			}
		}

		.MuiDataGrid-row,
		.MuiDataGrid-root .MuiDataGrid-cell,
		.rendering-zone {
			max-height: none !important;
		}
		.MuiDataGrid-root .MuiDataGrid-window {
			position: relative !important;
		}
		.MuiDataGrid-root .MuiDataGrid-viewport {
			max-height: none !important;
		}
		.MuiDataGrid-root {
			height: auto !important;
		}

		.MuiDataGrid-columnSeparator {
			display: none;
		}

		.MuiDataGrid-columnHeader {
			&:focus-within,
			&:focus {
				outline: none !important;
			}
		}

		.MuiDataGrid-columnsContainer {
			min-height: none !important;
			max-height: none !important;
			line-height: none !important;
			border-bottom: 0;
		}

		.MuiDataGrid-columnHeaderTitleContainer {
			color: $font-color;
			padding: 0;

			.MuiDataGrid-columnHeaderTitle {
				color: $font-color;
				font-size: $typography-large;
			}
		}

		.MuiDataGrid-cell {
			border-top: 1px solid $border-foreground;
			border-bottom: 0;
			padding: 6px 24px 6px 16px;

			&:focus {
				outline: none;
			}
		}

		.MuiDataGrid-row {
			color: $font-color;
			cursor: pointer;

			&:hover {
				background-color: transparent;
			}
		}

		.MuiDataGrid-cell {
			outline: none;
		}

		&__name-field {
			display: flex;
			align-items: center;
			cursor: pointer;

			&__avatar {
				margin-right: 15px;
			}
		}
	}
}

body.theme--dark {
	.alerts {
		&__table {
			&--loading {
				background-color: lighten($app-background, 5%);
			}
		}
	}
}
