@use 'sass:math';
.popover-select-paper {
	margin-top: #{math.div($spacing-unit, 2)}px;
}
.popover-select {
	width: 100vw;
	position: fixed;
	top: 186px;
	left: 0;
	background-color: $app-background;

	@include breakpoint-up('sm') {
		width: 400px;
		height: auto;
		position: relative;
		top: unset;
		left: unset;
	}

	display: flex;
	flex-direction: column;
	overflow: hidden;

	&__filter-container {
		padding: #{$spacing-unit * 1.5}px;
		box-sizing: border-box;
	}
	&__option-picker-container {
		flex-grow: 1;
		border-style: solid;
		border-color: $border-foreground;
		border-width: 1px 0;
		&__empty-state {
			padding: #{$spacing-unit * 2}px;
		}
	}
	&__toolbar-container {
		padding: #{$spacing-unit * 1.5}px;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-end;
		> :not(:first-child) {
			margin-left: #{$spacing-unit}px;
		}
	}

	.MuiListItemIcon-root {
		color: $icon-foreground;
	}

	.option-picker {
		display: flex;
		justify-content: stretch;
		> div {
			//this is because with autoheight, the width seems to be auto too. so we need to bump the width up so its wider than the area
			//so the browser's vertical scrollbars get hidden
			width: 110%;
		}
		&__no-results {
			padding: #{$spacing-unit * 1.5}px;
			box-sizing: border-box;
		}
		.MuiList-root {
			.MuiListItemIcon-root {
				min-width: 30px;
			}
			.MuiListItemText-secondary {
				font-size: $typography-tiny;
			}
		}
	}

	.option-group {
		align-items: stretch;
		&__check-button {
			flex-grow: 0;
			flex-shrink: 0;
			padding-right: 0;
			.MuiListItemIcon-root {
				min-width: 30px;
			}
		}
		&__expand-button {
			padding-left: #{$spacing-unit}px;
			&--extra-padding {
				//without a checkable button, these need extra padding
				padding-left: #{$spacing-unit * 2}px;
				padding-top: #{$spacing-unit}px;
				padding-bottom: #{$spacing-unit}px;
			}
			&__group-name {
				&__count {
					color: $font-light;
					font-size: $typography-tiny;
				}
			}
		}
		&__checkable-option {
			.checkable-option__button {
				padding-left: #{$spacing-unit * 3}px;
			}
		}
	}

	.checkable-option {
		&__button {
			&__text {
				padding-left: #{$spacing-unit}px;
			}
		}
	}

	.loading-indicator {
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
