.drawer__menu__list {
	.drawer__menu__item__alerts {
		.drawer__menu__item__parent {
			margin-top: 0px;

			&:hover {
				.drawer__menu__item__label {
					color: $primary-color !important;
				}
			}

			.drawer__menu__item__label {
				color: $font-color !important;
				text-transform: capitalize;
				font-size: $typography-small;
			}
			&.drawer__menu__item {
				&--selected {
					.svg_expand {
						color: $font-light !important;
					}
					.drawer__menu__item__icon-wrapper__icon {
						svg {
							color: $font-light !important;
						}
					}
				}
			}
		}

		.MuiCollapse-root {
			padding-left: 20px;
		}
	}
}

@mixin workspaceSettings {
	padding-bottom: 150px;

	@include breakpoint-up('sm') {
		padding-right: 60px;
		padding-left: 60px;
	}

	@include breakpoint-up('lg') {
		padding-right: unset;
		padding-left: unset;
	}

	&__section-body {
		padding-top: #{$spacing-unit * 2.5}px;
		padding-left: 10px;
		padding-right: 10px;
		&__label {
			margin-bottom: #{$spacing-unit}px;
			font-size: $typography-body;
		}
		&__textfield {
			.MuiInputBase-input {
				font-size: $typography-body;
			}
		}

		.MuiOutlinedInput-root:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline {
			border-color: $border-hover;
		}
		.MuiOutlinedInput-notchedOutline {
			border: 1px solid $border-foreground;
		}

		.MuiFormControl-marginDense {
			margin: unset;
		}

		.select {
			&__control {
				background-color: $app-background;
				&--is-focused {
					box-shadow: 0 0 $border-hover;
				}
			}
			&__menu {
				background-color: $app-background;
			}
			&__dropdown-indicator {
				color: $font-light;
			}
		}
	}

	&--error {
		.empty-state {
			height: 95vh;
			margin: auto;
		}
	}
}
