.backfill-prompt {
	&__container {
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 24px;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;
		&__content {
			position: relative;
			background: $app-background;
			border: 1px solid $border-foreground;
			border-radius: $border-radius;
			display: flex;
			flex-direction: column;
			padding: 24px;
			text-align: left;
			@include breakpoint-up(md) {
				max-width: 420px;
			}
			&__check-area {
				display: flex;
				align-items: center;
			}
			&__date-picker-area {
				margin-left: 42px;
				margin-bottom: 8px;
				&__error {
					color: $error-background;
				}
				&__date-picker {
					.select-container {
						width: 100%;
					}
					display: flex;
					align-items: center;
				}
				.more-info {
					margin-left: #{$spacing-unit}px;
				}
			}
			&__title {
				margin-bottom: 8px;
			}

			.select {
				@include selectStyling;
				&__menu {
					.select__menu-list {
						font-family: $font-family;
						font-size: $typography-body;
					}
				}
			}
		}
	}
}
