.content-options {
	@include workspaceSettings;
}

.inbox-preview {
	&__sections-list {
		position: relative;

		&__item {
			display: flex;
			align-items: center;
			padding: #{$spacing-unit}px;
			background-color: $app-background-inset;
			border-radius: 3px;
			margin-bottom: #{$spacing-unit * 2}px;
			height: 28px;
			border: 1px solid $border-foreground;

			&__drag-icon {
				display: flex;
				align-items: center;
				width: 32px;
				padding: 4px;
				svg {
					height: 100%;
					font-size: 1rem;
					path {
						stroke: $font-color;
					}
				}
			}

			&__title {
				flex-grow: 1;
			}

			&--dragging {
				background-color: $border-foreground-light;
			}

			@include breakpoint-up(md) {
				width: 33.333%;
			}
		}
	}
}
