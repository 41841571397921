.quota-prompt {
	&__container {
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 24px;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;
		&__content {
			position: relative;
			background: $app-background;
			border: 1px solid $border-foreground;
			border-radius: $border-radius;
			display: flex;
			flex-direction: column;
			padding: 24px;
			@include breakpoint-up(md) {
				max-width: 420px;
			}
			&__check-area {
				display: flex;
				align-items: center;
			}
			&__date-picker-area {
				margin-left: 42px;
				margin-bottom: 8px;
			}
			&__title {
				margin-bottom: 8px;
			}
		}
	}
}
