.quick-share-modal {
	.select__menu {
		background-color: $app-background-inset;
	}

	.modal__container__content__header {
		padding-left: 40px;
	}

	@include breakpoint-up('md') {
		.modal__container__content__body--scroll {
			//the height/width should probably be set on a per-use basis
			//the scroll viewer in the body will collapse completely if there's not a fixed height/width otherwise
			height: 600px;
			width: 700px;
		}
	}
}

.quick-share {
	cursor: default;
	.select-theme__label,
	&__label {
		margin-bottom: #{$spacing-unit * 1.5}px;
	}
	&__input {
		background-color: $app-background-inset;
		.chip-textfield__container {
			height: 46px;
			&__input-container__scroll {
				padding-top: #{$spacing-unit}px;
			}
		}
		.MuiInputBase-input {
			font-size: $typography-body;
		}
	}

	&__comments {
		margin-bottom: #{$spacing-unit * 1.5}px;
	}

	&__warning {
		background-color: $warning-background-light;
		color: $warning-font-color;
		margin-top: #{$spacing-unit * 1.5}px;
	}
	.textfield-container {
		.MuiInputBase-root:not(.MuiInputBase-multiline) {
			height: 46px;
		}
	}
	.MuiInputBase-inputMultiline {
		padding: #{$spacing-unit * 2}px;
	}
}

.chip-textfield__container__input-container__inline-chips__scrollable {
	overflow: hidden !important;
}
