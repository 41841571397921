.adornment-label {
	display: flex;
	align-items: center;
	text-transform: uppercase;
	white-space: nowrap;
	&__label {
		cursor: default;
		color: $font-color;
		font-size: 11px;
		margin-left: 0px !important;
		font-weight: $weight-bold;
	}

	&__select {
		> .MuiInputBase-input {
			font-size: 12px;
			color: $font-light !important;
			text-transform: initial;
		}
		&__option {
			font-size: 12px;
			color: $font-light !important;
			text-transform: initial;
		}
		> .MuiSelect-icon {
			margin-top: 3px;
		}
	}

	> * {
		margin-left: 6px;
	}

	.icon-button {
		padding: 2px;
		height: 22px;
		width: 22px;
		> svg {
			height: 24px;
			width: 24px;
		}
	}
}
