.query-editor {
	@include layoutWithHeaderToolbar;
	box-sizing: border-box;
	height: 100%;

	.resizable-panel {
		z-index: 2;
		border-bottom: unset;
		height: 100%;
		min-height: 60px;

		&__container__content {
			position: relative;
			overflow: unset;
		}

		.advanced-filter-builder {
			padding-bottom: 0;
			&__input {
				max-height: 160px;
			}
		}
		.filters-strip {
			overflow: hidden;
		}
	}

	.filters-strip {
		margin: #{$spacing-unit * 2}px;
	}

	&__empty {
		height: 100%;
		flex: 1 0 auto;
		margin: 30px 0;
	}

	&__error {
		height: 100%;
		&__link {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	&__header {
		&__toolbar {
			padding: #{$spacing-unit * 1.5}px #{$spacing-unit * 2}px #{$spacing-unit * 1.5}px #{$spacing-unit * 1.5}px;
			&__left__query-name {
				flex: 0 1 auto;
				max-width: 100%;
				min-width: 0;
				&--readonly {
					padding-left: 7px;
				}
			}
		}
	}

	&__builder {
		flex: 0 0 auto;
		background-color: $app-background-inset;
		border-bottom: 1px solid $border-foreground;
		padding-bottom: #{$spacing-unit * 0.25}px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	&__results {
		flex: 1 1 auto;
		display: flex;
		flex-direction: row;

		&__media {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			min-width: 0;

			&__tabs {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				&__content {
					display: flex;
					flex-grow: 1;
				}
				.media-items-viewer-tab {
					display: flex;
					align-items: center;
					.MuiCircularProgress-root {
						margin-right: #{$spacing-unit}px;
					}
				}
				.MuiTabs-root {
					$tabHeight: 58px; //match the height of the insights button
					border-bottom: 1px solid $border-foreground;
					height: $tabHeight;
					.MuiButtonBase-root {
						height: $tabHeight;
					}
				}
				.MuiTabs-flexContainer {
					.MuiTab-root {
						min-width: 160px;
						padding-top: #{$spacing-unit * 2.5}px;
						padding-bottom: #{$spacing-unit * 2}px;
						text-transform: unset;
						opacity: 1;
						&:hover {
							background-color: $app-background-inset;
						}
					}
				}
			}
		}
	}
}

.save-query-prompt {
	.select {
		.select__single-value--is-disabled p {
			color: $font-disabled;
		}
	}
	.select-menu {
		font: $font-family;
	}

	label.Mui-disabled {
		color: $font-light;
	}
}
