.chart-builder {
	height: 100%;
	.select-container {
		.select__menu {
			z-index: 2;
		}
	}

	.simple-chart {
		min-height: 400px;
		height: unset;

		.am-chart-v4 {
			height: 400px;
		}

		.chart-loader-container,
		.no-chart-data-message {
			height: 400px;

			&.chart-loader-container--nested-charts {
				height: unset;
				.chart-loader-container {
					height: unset;
				}
			}
		}
	}
}

.display-selector {
	&__chart-icon-container {
		display: flex;
	}
}
