.typing-indicator {
	position: relative;
	display: inline-block;
	&__dot {
		float: left;
		width: #{$spacing-unit}px;
		height: #{$spacing-unit}px;
		margin: 0 4px;
		background: $primary-color;
		border-radius: 50%;
		opacity: 0;
		animation: loadingFade 1s infinite;

		&:nth-child(1) {
			animation-delay: 0s;
		}
		&:nth-child(2) {
			animation-delay: 0.2s;
		}
		&:nth-child(3) {
			animation-delay: 0.4s;
		}
	}
}

@keyframes loadingFade {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.8;
	}
	100% {
		opacity: 0;
	}
}
