.MuiPickersToolbar-toolbar {
	h2 {
		color: $primary-foreground;
	}
}

input[type='date'] {
	color-scheme: $color-scheme;
	@include date-picker-color-scheme-light-fix($color-scheme);
}
