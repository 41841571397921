@use 'sass:math';

$report-large-font-size: 17px;
$report-subtitle-font-size: 15px;
$am-chart-font-size: 15px;
$report-section-header-font-color: #555555;
$report-settings-sub-menu-font-size: 15px;
$font-color-light: #2b4265;
$border-color-light: #e2e5e7;
$app-background-inset-light: #f2f5f7;
$label-color-light: #8493a6;

.report-template-editor {
	@include layoutWithHeaderToolbar;

	.inline-wrapped {
		display: flex;
		flex-wrap: wrap;
	}

	&__header {
		&__toolbar {
			&__left {
				.click-to-edit-field {
					.MuiInputBase-formControl {
						font-size: $typography-h3;
						font-weight: $weight-bold;
					}
				}
			}
		}
	}

	&__content {
		width: 100%;
		position: relative;
		height: 100%;
		flex-direction: row;

		&__loader {
			width: 100%;
			position: absolute;
			z-index: 1;
		}
		.report-template-settings {
			width: 60%;
			border-left: 1px solid #{$border-foreground};
		}
	}
	&__error {
		padding: 24px 40px;
	}
	.tox-tinymce,
	.tox-statusbar {
		border-color: $tinyice-border-color;
	}
	.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
		border-right: 1px solid #ffffff;
	}
	.tox-toolbar__primary {
		background: none;
		border-bottom: 1px solid $tinyice-border-color;
	}
	.tox-tbtn {
		color: #404040;
		svg {
			fill: #404040;
		}
	}
	.tox-tbtn.tox-tbtn--disabled {
		svg {
			fill: #999999 !important;
		}
	}
	.tox .tox-toolbar-overlord,
	.tox.tox-tinymce-aux .tox-toolbar__overflow {
		background-color: #ffffff;
	}
	.tox.tox-tinymce-aux .tox-toolbar__overflow {
		border-color: $tinyice-border-color;
	}

	@include breakpoint-up('sm') {
		.flex-layout__content .select-container {
			max-width: 66.67%;
		}
	}
}

.report-template {
	--template-font: #{$font-family};
	--template-link-color: #{$primary-color};
	background-color: $white;
	color: #404040;
	font-size: $report-large-font-size;
	line-height: 150%;
	width: 100%;
	display: flex;
	flex-grow: 1;

	.input-element:focus {
		color: #404040;
	}

	.icon-button {
		svg {
			fill: $grey-9;
		}

		&:hover {
			background-color: $grey-2;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #404040;
	}

	.link {
		color: var(--template-link-color);
	}

	.avatar--fallback {
		color: #ffffff;
		background-color: #303030;
	}

	&__content__outer {
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		@include breakpoint-up('sm') {
			width: 600px;
		}

		.media-image {
			&__full {
				img {
					max-width: 100%;
					max-height: 310px;
				}
			}

			&__left {
				float: left;
				margin: 0 20px 10px 0;
			}
			&__right {
				float: right;
				margin: 0 0 10px 20px;
			}

			&__left,
			&__right {
				max-height: 150px !important;
				max-width: 150px !important;
				img {
					min-width: 150px;
					height: 150px;
				}
			}
		}

		&--wide {
			width: 800px;

			.media-image {
				&__full {
					img {
						max-width: 100%;
						max-height: 360px;
					}
				}
			}
		}

		.content-container__content {
			margin-left: 0px;
			margin-right: 0px;
		}
	}

	&__content {
		.section-title-editor {
			&.editable {
				.textfield-container {
					&__mui-control {
						.MuiInputBase-root {
							background-color: #ffffff;
							input {
								color: #404040 !important;
							}
						}
						.MuiOutlinedInput-notchedOutline {
							border-color: $tinyice-border-color;
							&:hover {
								border: 3px solid $primary-color;
							}
						}
					}
				}
				button {
					background-color: #ffffff;
				}
			}
			&:not(.editable):hover {
				background-color: #f8f8f8;
			}
		}
		&__header {
			border-width: 0px 0px 1px 0px;

			a {
				color: #aaaaaa;
				font-size: $report-subtitle-font-size;
			}

			a.link {
				color: var(--template-link-color);
			}

			&__report-title {
				border-style: solid;
				border-color: $tinyice-border-color;
				border-width: 1px 0px 0px 0px;
				&__title {
					color: #000 !important;
					font-size: 30px;
					max-width: 80%;
					text-align: center;
					line-height: 100%;
					padding: #{$spacing-unit}px 0px;
					word-wrap: break-word;
				}
				&__subtitle {
					margin-top: 2px;
					margin-bottom: #{$spacing-unit * 2}px;
					color: #797979 !important;
					max-width: 80%;
					font-size: $report-large-font-size;
				}
				padding: 16px 0;
				display: flex;
				flex-direction: column;
				align-items: center;

				&__badge {
					max-width: 100px;
					max-height: 100px;
					margin-top: 10px;
					margin-bottom: #{$spacing-unit * 2}px;
				}

				&__logo {
					max-width: 250px;
					max-height: 200px;
					margin-top: 10px;
					margin-bottom: #{$spacing-unit * 2}px;
				}
				&__banner {
					max-width: 100%;
					height: auto;
					margin-top: 10px;
					margin-bottom: 20px;
					border-radius: 20px;
				}
			}
			&__links {
				padding: 0 0 16px 0;
			}
			.report-download-links-container {
				margin-bottom: #{$spacing-unit * 4}px;
				.report-download-links {
					display: flex;
					justify-content: center;
				}
			}
		}

		&__body {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: #f7f7f7;
			padding: 0 #{$spacing-unit * 4}px;
			border-radius: #{$spacing-unit * 2}px;
			font-family: var(--template-font);

			&__report-section-divider-container {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: #{$spacing-unit * 2}px;
				width: 675px;
				position: relative;
				cursor: pointer;

				hr {
					border-color: #e2e5e7;
				}

				&--wide {
					width: 875px;
				}

				&__floating-add-button {
					position: absolute;
					top: 0;
					left: 0;

					&__icon-button {
						padding: 4px;
						&:hover {
							background-color: rgba(0, 0, 0, 0.04);
						}
						> svg {
							color: #2b4265;
						}
					}
				}
			}
		}
		&__footer {
			font-family: var(--template-font);
			border-width: 1px 0px 0px 0px;
			padding: #{$spacing-unit * 4}px #{$spacing-unit * 2}px;
			text-align: center;

			&__logo {
				width: 54px;
				padding-bottom: #{$spacing-unit * 3}px;
			}

			.typography {
				color: #aaaaaa !important;
			}
			a {
				color: var(--template-link-color) !important;
			}
			.typography,
			a {
				font-family: var(--template-font);

				font-size: 14px;

				.link {
					text-decoration: underline;
				}
			}
		}
	}
	&__publish-modal {
		max-width: 360px;
		svg {
			flex-shrink: 0;
		}
		&__illustration {
			display: block;
			width: 270px;
			max-width: 100%;
			margin: #{$spacing-unit * 4}px auto 0;
		}
	}

	.typography {
		font-family: var(--template-font);
	}
}

.reports-send-button-group-list {
	z-index: 2;
}

.reports-send-button-group {
	display: flex;
	flex-direction: column;
	align-items: center;
	.button-group {
		margin: #{math.div($spacing-unit, 2)}px #{$spacing-unit}px;
		button {
			height: $rectangular-button-height;
			font-size: $typography-small;
			&:first-child {
				border-radius: 3px 0 0 3px;
				margin-right: 1px;
				padding-right: #{$spacing-unit * 2}px;
				padding-left: #{$spacing-unit * 2}px;
			}
			&:last-child {
				border-radius: 0 3px 3px 0;
				width: 30px;
				> span {
					line-height: 0;
				}
			}
		}
	}
	&-list {
		font-size: $typography-small;
		color: $font-color;
		box-shadow: $container-box-shadow;
		border-bottom: 1px solid $border-foreground;
		border-radius: 2px;
		background: $app-background;
		padding: 0;
		li {
			font-size: inherit;
			border-bottom: 1px solid $border-foreground;
			padding: 15px 40px 15px 15px;

			&:last-child {
				border-bottom: none;
			}
		}
	}
}

.create-report-template {
	.MuiGrid-root {
		margin-bottom: #{$spacing-unit * 2}px;
	}

	.select-container {
		display: inline-flex;
	}
}

.report-template-settings-menu {
	padding: #{$spacing-unit * 4}px;
	&__link {
		//we pad this, because we want it to be easier to click. no padding means you would need to click directly on the text (vertically)
		padding: #{$spacing-unit}px;
		cursor: pointer;
		display: inline-block;

		&:hover {
			span {
				width: 100%;
				-webkit-transition: width 300ms ease-in-out;
				transition: width 300ms ease-in-out;
			}
		}

		span {
			display: block;
			border-bottom: solid 2px $font-color;
			width: 0;
			-webkit-transition: width 300ms ease-in-out;
			transition: width 300ms ease-in-out;
		}
	}
	&__sub-menu {
		margin-top: 10px;
	}
}

.report-template-settings-active-view {
	display: flex;
	flex-direction: column;
	height: 100%;
	font-family: #{$font-family};
	font-size: #{$typography-body};

	&.slide-right-exit-active {
		//to allow the new view to slide-in over the top of this view, we set its positioning to absolute so that it doesnt get pushed around
		position: absolute;
	}

	&__header {
		&--no-breadcrumbs {
			margin-top: #{$spacing-unit * 5}px;
		}
		margin-left: #{$spacing-unit * 5}px;
	}
	&__content {
		flex-grow: 1;
		padding: #{$spacing-unit * 4}px #{$spacing-unit * 5}px #{$spacing-unit * 5}px #{$spacing-unit * 5}px;
		box-sizing: border-box;
		&__section__header {
			display: flex;
			align-items: center;
			.more-info {
				margin-left: #{$spacing-unit}px;
			}
		}
	}
	&__loading-indicator {
		position: relative;
	}
	&__toolbar {
		padding: #{$spacing-unit * 3}px;
		border-top: 1px solid $border-foreground;
	}
	//because the report template wants to keep white colours, we need to set the settings views to use the theme colours (because they appear on the dom INSIDE the report)
	.input-element:focus {
		color: $font-color;
	}
	.switch-container {
		margin-left: -#{$spacing-unit}px;
		@include breakpoint-up('sm') {
			& ~ .switch-container {
				margin-left: unset;
			}
		}
	}
	.boolean-filter {
		.switch-container {
			margin-left: unset;
		}
	}
	.image-upload__image-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.action-buttons-group__secondary-button {
		button.button--secondary {
			background-color: darken($app-background-inset, 3%);
			&:hover {
				background-color: darken($app-background-inset, 5%);
			}
		}
	}
}

.report-template-settings-menu-breadcrumbs {
	position: sticky;
	top: 0;
	margin-top: 0;
	margin-bottom: #{$spacing-unit * 3}px;
	padding: #{$spacing-unit * 3}px 0 #{$spacing-unit * 2.5}px #{$spacing-unit * 5}px;
	z-index: 1;
	background-color: $app-background;

	.typography--body {
		font-size: 11px;
		line-height: initial;
	}
	svg {
		font-size: 21px;
		margin-left: -9px;
	}
	.MuiBreadcrumbs-li {
		cursor: pointer;
		text-transform: uppercase;
	}
	.inline--responsive-wrap > :not(:first-child) {
		margin-left: 3px;
	}
}

.report-template-settings {
	height: 100%;
}

.content-rules-filters {
	border: 1px solid $border-foreground-light;
	border-radius: 2px;
	padding: #{$spacing-unit * 3}px;
	width: 100%;
	box-sizing: border-box;
	background-color: $app-background;

	.textfield-container {
		.MuiOutlinedInput-root:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline {
			border-color: $border-hover;
		}

		.MuiInputBase-root {
			.MuiInputBase-input {
				color: $font-color;
				padding: 2px #{$spacing-unit * 1.25}px;
				height: 24px;
			}
		}
	}

	@include breakpoint-up('sm') {
		.select-container {
			max-width: 66.67%;
		}
	}
}

.report-section-more-menu {
	.popover,
	.MuiPopover-paper {
		background: $white;
		color: #2b4265;
	}
	.MuiMenuItem-root {
		font-size: $typography-body;
	}
}

.section-settings {
	&__sections-list {
		&__item {
			display: flex;
			align-items: center;
			padding: #{$spacing-unit}px;
			background-color: $app-background-inset;
			border-radius: 7px;
			margin-bottom: 20px;
			height: 28px;
			&--drag {
				background-color: $border-foreground-light;
			}
			&__icon {
				margin-right: #{$spacing-unit * 1.5}px;
				height: 18px;
				width: 18px;
				&__action {
					path,
					.icon-button--active svg {
						color: $font-light;
					}
				}
			}
			&__title {
				flex: 1 0;
			}
		}
	}
	&__add-section {
		padding: #{$spacing-unit}px;
		padding-bottom: #{$spacing-unit * 2}px;
		> a {
			display: flex;
			color: $font-color;
			align-items: center;
			> svg {
				margin-right: #{$spacing-unit}px;
			}
		}
		&__menu {
			svg {
				margin-right: #{$spacing-unit}px;
			}
			.Mui-disabled {
				pointer-events: all;
				cursor: pointer;
				opacity: 1;
				color: unset;

				svg {
					opacity: 0.38;
				}

				.section-settings__add-section__menu__ai-summary-option__label {
					span {
						opacity: 0.38;
					}
				}

				.tooltip-with-link {
					display: flex;
					align-items: center;
				}
			}

			&__ai-summary-option__label {
				display: flex;
				flex-direction: row;
				align-items: center;
				font-size: $typography-body;
			}
		}
	}
}

.report-section {
	@include layoutWithHeaderToolbar;

	position: relative;
	background: $white;
	border-radius: #{$spacing-unit * 2}px;
	box-sizing: border-box;
	padding: #{$spacing-unit * 4}px;
	width: 100%;

	&--headline {
		.report-section__item-divider {
			padding: 10px 0;
		}
	}

	&__header {
		height: unset;
		&__toolbar {
			border-bottom: unset;
			padding: unset;
			background-color: unset;
			&__right {
				&__tools {
					align-items: center;
					&__item-count {
						align-items: center;
						display: flex;
						color: #797979;
						margin: 0 #{$spacing-unit * 2}px;
					}
				}
			}
		}
		.click-to-edit-field {
			&__readonly-container > p {
				word-break: break-word;
			}
			.MuiInputBase-input {
				color: #2b4265;
			}
		}
	}

	&__empty-section {
		padding: #{$spacing-unit * 0.5}px #{$spacing-unit}px;

		&__charts {
			display: flex;
			flex-direction: column;
			align-items: center;
			.button {
				padding: #{$spacing-unit}px #{$spacing-unit * 2}px;
				font-weight: 700;
			}
			&__content {
				font-family: var(--template-font);
				margin: #{$spacing-unit * 2}px 0;
			}
			&__no-new-chart-image {
				margin: #{$spacing-unit * 4}px 0 #{$spacing-unit}px 0;
				width: 160px;
			}
		}
	}

	&__html-editor-wrapper {
		&--read-only {
			&__more-menu-container {
				float: right;
				padding: #{$spacing-unit}px;
			}

			&__editor {
				padding: #{math.div($spacing-unit, 2)}px #{$spacing-unit}px;
				transition: background-color 0.4s ease;
				border-radius: #{$spacing-unit * 2}px;

				&__info {
					box-sizing: border-box;
					background-color: #ffffdb;
					border-radius: #{$spacing-unit * 2}px;
					padding: #{$spacing-unit * 2}px #{$spacing-unit * 2}px;
					margin-top: #{$spacing-unit * 2}px;
				}

				&__empty {
					font-size: 17px;
				}

				pre {
					white-space: pre-wrap;
					word-wrap: break-word;
				}

				&:hover {
					background-color: #f8f8f8;
					cursor: pointer;
				}

				a {
					color: var(--template-link-color);
				}
			}
		}
	}

	&__report-section-divider {
		width: 530px;
		min-height: 1px;

		&--wide {
			width: 730px;
			min-height: 1px;
		}
	}

	&__cutouts {
		padding: #{$spacing-unit * 4}px;
		.typography {
			color: #797979;
			font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
				sans-serif;
		}
	}

	&__pagination {
		font-family: $font-family;
		padding: 0 0 #{$spacing-unit * 4}px 0;
		justify-content: center;
		button.button.MuiButton-root {
			padding: 15px 18px;
			background: $grey-0;
			border-radius: 7px;
			color: black;
			text-align: center;
			display: block;
		}
	}

	&__item-divider {
		$parent: '.report-section__item-divider';
		$buttonSpacing: 118px;

		position: relative;
		cursor: pointer;
		padding: 20px 0;

		hr {
			border-color: #e2e5e7;
		}

		&#{$parent}--disabled {
			&#{$parent}--show-on-hover {
				&:hover {
					opacity: 0;
				}
			}
			&:hover {
				hr {
					background-color: $border-foreground;
				}
			}
		}

		&:hover {
			#{$parent}__button {
				z-index: 2;
				opacity: 1;
				transition: 0.5s opacity ease;
			}
			hr {
				background-color: rgba(0, 0, 0, 0.3);
			}
		}

		hr {
			transition: background-color 0.3s ease;
		}

		&--show-on-hover {
			opacity: 0;
			&:hover {
				opacity: 1;
			}
		}

		&--disabled {
			cursor: default;
		}

		&--hidden {
			display: none;
		}

		&__button {
			display: block;
			position: absolute;
			left: calc(-#{$buttonSpacing} - 30px); // offset the padding left
			top: 5px;
			width: calc(100% + #{$buttonSpacing});
			padding-left: 30px;

			z-index: -9;
			opacity: 0;
			transition: 0.5s opacity ease;

			&__icon-button {
				padding: 4px;
				&:hover {
					background-color: rgba(0, 0, 0, 0.04);
				}
				> svg {
					color: #2b4265;
				}
			}
		}
	}

	// the idea is to only add top margin if it is "in this report" section (which is wrapped in a report-section container like other sections)
	// currently there are no ways to select parent class that work across all browsers
	// TODO: later when :has() selector is supported in FF, we can remove this extra class and use :has(.in-this-report-section)
	&.in-this-report-section-container,
	&.report-links-section-container {
		margin-top: #{$spacing-unit * 4}px;
	}

	.report-links-section,
	.in-this-report-section {
		border-radius: #{$spacing-unit * 2}px;

		&__title {
			margin-bottom: #{$spacing-unit * 2}px;
		}

		&__body {
			padding: #{$spacing-unit}px 0;
			&__section-index {
				min-width: #{$spacing-unit * 2}px;
				line-height: #{$spacing-unit * 2}px;
				padding: #{$spacing-unit * 0.5}px;
				border-radius: 50%;
				text-align: center;
				border: 2px solid var(--template-link-color);
				color: $white;
				background-color: var(--template-link-color);
			}

			&__title {
				color: #000;
				width: 63%;
				word-break: break-word;
			}
			&__subtitle {
				color: #797979;
			}

			&:hover {
				cursor: pointer;
			}
		}
	}

	.typography--h2,
	.typography--h3 {
		font-size: #{$spacing-unit * 3}px;
	}
	.typography--h3 {
		color: var(--template-link-color);
	}
	.typography--h5 {
		color: $black;
		font-size: $report-large-font-size;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.typography--h6 {
		font-size: $report-large-font-size;
	}

	&__item-container {
		&__media-item {
			position: relative;
			padding: 0 3px;
			flex-direction: row;

			&:hover {
				$parent: '.report-section__item-container__media-item';
				#{$parent}__toolbar {
					opacity: 1;
					z-index: 1;
					transition: opacity 0.7s, z-index 0s 0s, top 0.1s, bottom 0.1s ease;
				}
			}

			.media-item-metrics {
				display: flex;
				flex-wrap: wrap;
				margin-top: 10px;
				font-size: $report-subtitle-font-size;
				&__metric {
					display: flex;
					flex-wrap: nowrap;
					align-items: baseline;
					margin-right: #{$spacing-unit * 2}px;
					&__title {
						text-transform: capitalize;
						margin-right: #{$spacing-unit}px;
						color: #797979;
					}
					&__data {
						color: #000;
						.media-item-ave {
							font-size: $report-subtitle-font-size;
							color: #000;
						}
					}
				}
				&__metric:last-child {
					margin-right: 0;
				}
			}

			.article {
				.header {
					margin-bottom: 15px;
				}
				&__full {
					.header {
						margin: 15px 0;
					}
				}
				.footer {
					.article-link {
						flex-grow: 1;
						color: var(--template-link-color);
						font-size: $report-subtitle-font-size;
					}
					.share-links-container {
						.MuiLink-root {
							display: flex;
						}
					}
				}
			}

			.media-image {
				border-radius: 15px;
				line-height: 0;
				overflow: hidden;
				display: inline-block;
				max-width: 100%;
			}

			.sentiment {
				&__positive {
					color: #2e9992;
				}

				&__neutral {
					color: #707070;
				}

				&__negative {
					color: #da3763;
				}

				&__mixed {
					color: #3c85cb;
				}
			}

			&__content {
				max-width: 100%;
				min-width: 100%;

				.header-section {
					&__title {
						display: flex;
						align-items: flex-start;
						flex-direction: row;
						.typography {
							color: $report-section-header-font-color;
							font-size: $report-subtitle-font-size;
						}
						&--no-wrap {
							white-space: nowrap;
						}
					}

					&__icon {
						width: 20px;
						margin-right: #{$spacing-unit}px;
					}
				}

				.article-title {
					color: #000000;
					font-weight: bold;
					font-size: $report-large-font-size;
					margin-bottom: 10px;
					.typography {
						color: #000000;
						font-size: $report-large-font-size;
					}
					a {
						text-decoration: none;
					}
				}

				.article-link {
					display: block;
					color: inherit;
				}

				.source-logo {
					img {
						width: 50px;
						height: auto;
					}
				}

				.content {
					width: 100%;
					word-wrap: break-word;
					font-size: $report-large-font-size;
					color: #797979;

					.article-summary {
						margin: 0;
						white-space: pre-wrap;
					}

					.article-text-highlight {
						font-weight: bold;
						padding: 0px 2px;
					}

					.article-text-link {
						color: inherit;
					}

					&__title {
						display: flex;
						.supplier-badge {
							margin-top: 4px;
							margin-right: 3px;
						}
					}
				}
				.heading-colour__highlight {
					background-color: #e1ebf9;
				}

				.footer {
					width: 100%;
					margin-top: #{$spacing-unit * 2}px;
					display: flex;
					align-items: center;
					&:empty {
						margin-top: 0;
					}
				}

				.keyword-list {
					margin-top: 10px;
					font-size: $report-large-font-size;
					color: #797979;
					align-items: unset;
					align-content: unset;
					font-size: $report-subtitle-font-size;

					&__label {
						margin-right: 6px;
					}

					&__list {
						margin-left: 5px;
						color: #000;
						font-weight: normal;
					}
				}

				&__compact {
					.inline--responsive-wrap {
						align-items: flex-start;
					}
					&__content {
						width: 100%;
						.article-title {
							.typography {
								font-size: $report-subtitle-font-size;
							}
						}

						&__metadata {
							margin-bottom: 5px;
							&--icon {
								margin-top: 1px;
								img {
									width: 20px;
									height: auto;
								}
							}
						}

						&__footer {
							width: 100%;
							margin-top: #{$spacing-unit}px;
							display: flex;
							margin-top: 12px;
						}
					}
				}
			}

			&__toolbar {
				$buttonSpacing: 118px;
				display: flex;
				opacity: 0;
				z-index: -9999;
				box-sizing: border-box;
				padding-left: 20px; //we give this 20px padding so users can hover past without losing hover state
				position: absolute;
				left: calc(#{-$buttonSpacing} - 10px);
				width: calc(20px + #{$buttonSpacing}); //button spacing + padding
				transition: opacity 0.7s, z-index 0s 1s ease;

				flex-direction: column;
				> :not(:first-child) {
					margin-top: 4px;
				}

				&__alignment-tools {
					position: relative;
					width: 30px;

					&:hover {
						.sub-tools {
							transition: width 0.5s ease;
							width: 88px;
							flex-wrap: nowrap;
							background-color: $white;
							border-radius: 0 15px 15px 0;
						}
					}

					.sub-tools {
						transition: width 0.5s ease;
						z-index: -1;
						position: absolute;
						top: 0;
						padding-right: 2px;
						left: 30px;
						overflow: hidden;
						display: flex;
						width: 0;

						button > span {
							line-height: 0;
						}

						svg {
							fill: $grey-5;
						}
					}
				}
			}
		}
	}

	&__chart-container {
		position: relative;
		&:hover {
			$parent: '.report-section__item-container__media-item';
			#{$parent}__toolbar {
				opacity: 1;
				z-index: 1;
				transition: opacity 0.7s, z-index 0s 0s, top 0.1s, bottom 0.1s ease;
			}
		}
		.date-picker-button:hover {
			text-decoration: none;
			background-color: rgba(11, 92, 206, 0.04);
		}
	}

	&--empty {
		padding: 0;
		margin: #{$spacing-unit * 4}px 0px #{$spacing-unit * 4}px;
		border: 2px dashed $tinyice-border-color;

		ul {
			li {
				.link {
					&--disabled {
						text-decoration: none;
						color: #797979;
						cursor: pointer;
					}
				}
			}
		}
	}

	.MuiSkeleton-pulse {
		background-color: #f2f5f7;
	}
}

.media-item-display-setting-button {
	margin-top: #{$spacing-unit * 2}px;
}

.html-section-options {
	&__title-wrapper {
		&__inline {
			.MuiFormHelperText-contained {
				margin-left: unset;
			}
		}
	}
}

.collaborator-settings {
	&__table-spacer {
		// fill same height as overflow menu buttons incl. padding
		width: 48px;
		height: 48px;
	}
}

.merge-field {
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	&__children {
		flex-grow: 0;
	}
	&__input {
		flex-grow: 1;
		.adornment--multiline {
			margin-top: #{$spacing-unit * 2}px;
		}
	}
	&__button {
		white-space: nowrap;
		flex-basis: 100%;
		.button {
			&--pill {
				background: $app-background;
				color: $primary-color;
				border-width: 1px !important;
				border-color: $primary-color;
			}
		}
	}

	& > *:last-child {
		margin-top: 12px;
	}

	@include breakpoint-up('sm') {
		flex-direction: row;
		align-items: center;
		flex-wrap: nowrap;

		&__children {
			flex-grow: 0;
		}
		&__input {
			margin-right: 12px;
		}
		&__button {
			flex-basis: unset;
		}
		& > *:last-child {
			margin-top: unset;
		}
	}
}

.media-item-drag-container {
	&--drag {
		background-color: #f8f8f8;
	}
}

.share-link-checkbox {
	&__label-container {
		display: flex;
		align-items: center;
		&__icon {
			height: 20px;
			width: 20px;
		}
		&__label {
			margin-left: #{$spacing-unit}px;
		}
	}
}

.share-links-container {
	display: flex;

	&__icon {
		img {
			height: 24px;
			width: 24px;
		}
		margin-right: #{$spacing-unit * 2}px;
		&:last-child {
			margin-right: 0;
		}
	}
}

.media-items-section-options {
	&__override-defaults {
		border: 1px solid $border-foreground;
		border-radius: $border-radius;
		background-color: $app-background;
		padding: #{$spacing-unit * 4}px;
		box-sizing: border-box;

		.select__control {
			background-color: $app-background;
		}
		&--disabled {
			.select--is-disabled {
				.select__control,
				.select__control--is-disabled {
					background-color: $app-background-inset;
				}
			}
			.MuiTypography-root,
			.MuiFormControlLabel-label,
			.select__placeholder {
				color: $font-disabled;
			}
		}
	}
	&__override-switch {
		.switch-container__display-settings-text {
			margin-left: 6px;
		}
	}
	&__title-switches {
		margin-top: #{$spacing-unit}px;
	}
}

.report-section-divider-menu-ref {
	display: flex;
}

.overridable-media-items-settings__metadata-inclusions {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
	&__checkbox-group {
		display: inline-flex;
		align-items: center;
		margin-right: #{$spacing-unit * 2}px;
		.form-control-label {
			margin-right: #{$spacing-unit}px;
		}
	}
}

.media-item-view-title {
	font-weight: normal;
	color: inherit;

	&--bold {
		font-weight: bold;
		color: #000;
	}
}

.media-item-highlighter {
	&__content-container {
		p {
			margin: 0;
		}
	}
	&__highlight-container {
		display: none;
	}
}

&.theme--light {
	.report-template-settings {
		button.button--secondary {
			background-color: $border-foreground;
			&:hover {
				background-color: lighten($border-foreground, 3%);
			}
		}
		background-color: $app-background-inset;

		.report-template-settings-menu-breadcrumbs {
			background-color: $app-background-inset;
		}

		.select__control,
		.MuiInputBase-root,
		.click-to-edit-field,
		.section-settings__sections-list__item {
			background-color: $app-background;
		}

		.click-to-edit-field {
			border-radius: 4px;
			border-color: $border-foreground;
		}

		.report-template-schedule-settings {
			&__button_group {
				.button--default {
					background: $app-background;

					&:hover {
						background: $primary-color !important;
					}
				}
			}
		}
	}
}

.report-chart .chart-header .filter-summary {
	font-size: $report-subtitle-font-size;
	margin-bottom: #{$spacing-unit * 1.5}px;
}

.report-content-rules-settings {
	.radio-group-form-control {
		flex-direction: row;
		&__radio-group {
			display: contents;
			&__radio-label {
				.MuiFormControlLabel-label {
					margin-top: 2px;
				}
				margin-right: #{$spacing-unit * 3}px;
				.more-info {
					display: inline-flex;
					vertical-align: text-top;
					margin-left: 6px;
					margin-top: 2px;
					justify-content: center;
				}
			}
		}
	}
}
.header-settings {
	&__switch {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: #{$spacing-unit}px;
		.switch-container {
			margin-right: 6px;
		}
	}
}

.textfield-subtitle {
	display: flex;
	align-items: center;
	.more-info {
		margin-left: #{$spacing-unit}px;
	}
}

.button-group-subtitle,
.radio-group-subtitle,
.textfield-subtitle,
.select-subtitle {
	margin-bottom: #{$spacing-unit * 1.5}px;
}
.switch-subtitle,
.checkbox-subtitle {
	margin-bottom: #{$spacing-unit}px;
}
.MuiCheckbox-root.Mui-disabled {
	color: $font-disabled;
}

.design-settings {
	margin-top: -#{$spacing-unit * 2}px;
	.report-template-settings-menu__link {
		padding: 0;
		font-weight: bold;
		font-size: $report-settings-sub-menu-font-size;
	}
}

.format-settings {
	.report-type-options {
		display: flex;
		flex-direction: column;
	}
	&__email-type-options {
		.radio-group-form-control__radio-group {
			margin-top: #{$spacing-unit}px;
			&__radio-label {
				margin-top: #{$spacing-unit * 2}px;
				align-items: flex-start;
				&__radio {
					margin: 0 #{$spacing-unit * 0.5}px;
					padding: 0 #{$spacing-unit}px;
				}
				& > .MuiFormControlLabel-label {
					margin-top: 2px;
				}
			}
		}
	}
}

.report-download-links {
	img:not(:last-child) {
		margin-right: #{$spacing-unit * 1.5}px;
	}
}

.report-chart-date-picker {
	background: $white;
	.date-picker-control-popover__content {
		&__toolbar {
			border-top: 1px solid $border-color-light;
		}
		&__body {
			.select-container {
				&__label-above {
					color: $label-color-light;
				}
				.select {
					@include selectStylingLight;
				}
			}
			&__chart-interval-group {
				.button--regular:not(.button--primary) {
					background-color: $app-background-inset-light;
					color: $font-color-light;
				}
			}
			.textfield-container {
				&__label-above {
					color: $label-color-light;
				}
				&__mui-control {
					.MuiInputBase-root {
						background-color: $app-background-inset-light;
					}
					.MuiInputBase-input {
						color: $font-color-light;
					}
					.MuiOutlinedInput-notchedOutline {
						border-color: $border-color-light;
					}
					&:hover {
						.MuiOutlinedInput-notchedOutline {
							border-color: rgba(0, 0, 0, 0.2);
						}
					}
				}
			}
			input[type='date'] {
				color-scheme: light;
				@include date-picker-color-scheme-light-fix(light);
				-webkit-text-fill-color: $font-color-light;
			}
		}
		&__toolbar {
			button.button--secondary {
				background-color: $app-background-inset-light;
				color: $font-color-light;
				&:hover {
					background-color: #e9eef1;
					color: $font-color-light;
				}
			}
		}
	}
}
