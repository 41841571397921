.MuiDataGrid-root {
	scrollbar-color: darken($app-background-inset, 8%) rgba(0, 0, 0, 0);
	::-webkit-scrollbar {
		width: 7px;
	}
	::-webkit-scrollbar-track {
		display: none;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
	::-webkit-scrollbar-thumb {
		background-color: darken($app-background-inset, 8%);
		border-radius: 10px;
	}
}

_:not(a, b),
.MuiDataGrid-root {
	::-webkit-scrollbar-thumb {
		background-color: rgba($color: #666, $alpha: 0.75);
		border-radius: 10px;
	}
}
