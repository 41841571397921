.export-root {
	display: block;
	&__html-export {
		&__page {
			height: 100%;
			width: 100%;
			page-break-after: always;
			overflow: hidden;
			position: relative;
			&__watermark {
				position: absolute;
				top: 0;
				right: 0;
				&__logo {
					height: 50px;
					width: 200px;
				}
			}
		}
	}
	.table-chart__table {
		max-width: 260mm;
	}
}

@media screen {
	.app.export-root {
		background-color: $grey-5;
	}
	.export-root {
		display: flex;
		justify-content: center;
		height: 100vh;
		width: 100vw;
		overflow-x: hidden;
		overflow-y: auto;
		&__html-export {
			width: 297mm;
			&__page {
				margin-bottom: 10px;
				background-color: white;
				border: 1px solid black;
				&:last-child {
					margin-bottom: 100px;
				}
			}
		}
	}
}
