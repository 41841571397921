.column-benchmark {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	&__current {
		font-weight: $weight-bold;
		color: $font-color;
		font-size: $typography-extra-large;
	}
	&__previous {
		display: flex;
		justify-content: flex-end;
		font-size: $typography-small;
		color: $font-light;

		&__arrow {
			color: $font-light;
			&--green {
				color: $green-1;
			}
			&--red {
				color: $red-1;
			}
		}
		&__amount {
			margin-left: 4px;
		}
	}
	&__previous-label {
		color: $font-light;
		font-size: $typography-small;
	}
}
