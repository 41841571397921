.textfield-container {
	.MuiInputLabel-root {
		font-size: $typography-body;
	}

	.MuiInputLabel-shrink {
		&.Mui-focused {
			color: $primary-color;
		}
	}

	.MuiInputBase-root {
		&:hover {
			.MuiOutlinedInput {
				&-notchedOutline {
					border: 1px solid $border-hover;
				}
			}
		}

		&.Mui-focused {
			.MuiOutlinedInput {
				&-notchedOutline {
					border: 1px solid $primary-color;
				}
			}
		}

		.MuiOutlinedInput {
			&-notchedOutline {
				transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
				border: 1px solid $border-foreground;
			}
		}
	}

	button {
		&.MuiButtonBase-root {
			.MuiSvgIcon-root {
				color: $font-light;
			}
			&:hover {
				.MuiSvgIcon-root {
					color: $font-color;
				}
			}
		}
	}

	&__mui-control {
		.MuiInputBase-root {
			background-color: $app-background-inset;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: $border-foreground;
			&:hover {
				border: 1px solid $border-hover;
			}
		}
	}
}
