.search-assistant-prompt {
	display: flex;
	align-items: center;
	&__container {
		width: 800px;
		&__content {
			height: 100%;
			//these two properties are needed to make the animated borders work.
			position: relative;
			z-index: 0;
			.modal__container__content__body {
				background-color: $app-background-inset;
				border-bottom: 1px solid $border-foreground;
				border-top: 1px solid $border-foreground;
				.content-explorer-toolbar-button button.Mui-disabled {
					svg {
						color: $font-color;
					}
				}
			}
			.search-assistant {
				.CodeMirror,
				.CodeMirror-scroll {
					max-height: 360px;
				}
				@include breakpoint-up('md') {
					.CodeMirror,
					.CodeMirror-scroll {
						max-height: 500px;
					}
				}
				&__plain-text__end-adornment__search-button {
					.Mui-disabled,
					&--active {
						display: none;
					}
				}
			}
		}
	}
}

.search-assistant-button {
	svg {
		color: lighten($font-color, 10%);
	}
	.icon-button {
		height: 30px;
		width: 30px;
		padding: 0;
		background: $app-background-inset;
		&:hover {
			svg {
				color: $white;
			}
			background: $primary-background-hover;
		}
	}
}

.search-assistant {
	border: 1px solid $border-foreground;
	&:focus-within {
		border-color: $border-focus;
	}
	background-color: $textarea-background;
	border-radius: $content-explorer-input-border-radius;
	display: flex;
	flex-direction: column;
	.CodeMirror {
		margin: #{$spacing-unit}px;
		max-height: 120px;

		&-scroll {
			// Scroll max height needs to be the same as .CodeMirror so content doesn't cut off
			max-height: 120px;
		}
	}
	.MuiCollapse-wrapper {
		border-top: 1px solid $border-foreground;
	}
	&__plain-text {
		display: flex;
		flex-direction: row;
		padding: 0.5px 0;
		&__input {
			flex-grow: 1;
			margin: 2px #{$spacing-unit}px 0 #{$spacing-unit * 3}px;
			input::placeholder,
			input.Mui-disabled {
				-webkit-text-fill-color: $font-light;
				color: $font-light;
				text-overflow: ellipsis;
			}
		}
		&__end-adornment {
			display: flex;
			align-items: center;
			> :not(:last-child) {
				margin-right: #{$spacing-unit}px;
			}
			padding: #{$spacing-unit}px #{$spacing-unit * 1.5}px #{$spacing-unit}px 0;
		}
	}
}
