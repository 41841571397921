.resend-report-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	&__content {
		padding: #{$spacing-unit * 4}px;
		background-color: $app-background;
		border-radius: $border-radius;
		max-width: 800px;
	}
}
