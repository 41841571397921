.stepper {
	padding: 0;

	.MuiStepLabel-label {
		color: inherit;
		font-weight: $weight-regular;

		&.Mui-active {
			font-weight: $weight-bold;
			color: $font-color;
		}
	}

	&__horizontal {
		&__first-child {
			&--no-padding-left {
				padding-left: 0;
			}
		}
	}
}
