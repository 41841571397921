.limit-prompt {
	//because its inside an inline, it gets margin'd, so we remove it
	margin-left: 0 !important;

	@include breakpoint-up('md') {
		.modal__container__content__body {
			//the height/width should probably be set on a per-use basis
			//the scroll viewer in the body will collapse completely if there's not a fixed height/width otherwise
			max-width: 700px;
		}
	}
}
