.mentions-alert {
	@include workspaceSettings;

	&__section-body {
		&__label {
			margin-bottom: #{$spacing-unit * 2}px;
		}
		.select-theme__label {
			font-size: $typography-body;
		}
		&__filters {
			padding-bottom: #{$spacing-unit * 3}px;
			.filters-container {
				.add-filter-button {
					background-color: unset;
				}
			}
		}
	}
}
