.media-assistant-modal {
	.modal__container__content__body__content {
		background-color: $app-background-inset;
	}

	&__container__content {
		.tox-tinymce {
			border-radius: 0 0 20px 20px;
			border-top: none;
			border-bottom: 1px solid #e2e5e7;
			border-left: 1px solid #e2e5e7;
			border-right: 1px solid #e2e5e7;
			padding: 1px;
			transition: border-color 0.2s ease-in-out;

			.tox-edit-area {
				&:before {
					display: none;
				}
			}

			.tox-editor-header {
				box-shadow: none;
			}
		}

		&__plain-text {
			display: flex;
			flex-direction: row;
			padding: 0.5px 0;
			border-radius: 20px;
			background-color: $textarea-background;
			border: 1px solid $border-foreground;
			transition: border-color 0.2s ease-in-out;

			&:focus-within {
				border-color: $border-focus;
			}

			&--has-response {
				border-radius: 20px 20px 0 0;
				border-bottom: none;
				border-top: 1px solid #e2e5e7;
				border-left: 1px solid #e2e5e7;
				border-right: 1px solid #e2e5e7;
			}

			&__input {
				flex-grow: 1;
				margin: 2px #{$spacing-unit}px 0 #{$spacing-unit * 3}px;
				input::placeholder,
				input.Mui-disabled {
					-webkit-text-fill-color: $font-light;
					color: $font-light;
					text-overflow: ellipsis;
				}
			}

			&__end-adornment {
				display: flex;
				align-items: center;
				padding: #{$spacing-unit}px #{$spacing-unit * 1.5}px #{$spacing-unit}px 0;
				> :not(:last-child) {
					margin-right: #{$spacing-unit}px;
				}

				&__cancel-button {
					.MuiCircularProgress-root {
						margin-left: 10px;
					}
				}

				&__button {
					button {
						background-color: $primary-background;
						height: 38px;
						width: 38px;
						transition: background-color 0.3s ease-in-out;

						.MuiSvgIcon-root {
							color: $primary-foreground;
							transition: color 0.3s ease-in-out;
						}

						&.Mui-disabled {
							background-color: $app-background-inset;
							.MuiSvgIcon-root {
								color: unset;
							}
						}
					}
				}
			}
		}

		&--focus {
			.media-assistant-modal__container__content__plain-text,
			.tox-tinymce {
				border-color: $border-focus;
			}
		}
	}
}
