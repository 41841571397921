@import './mixins/mixins.scss';
@import './mixins/layout-with-header-toolbar.scss';
@import './mixins/select-styling.scss';
@import './components/accordion.scss';
@import './components/authenticated-view.scss';
@import './components/activity-chart.scss';
@import './components/adornment-label.scss';
@import './components/alert.scss';
@import './components/animated-border.scss';
@import './components/auto-suggest.scss';
@import './components/backfill-prompt.scss';
@import './components/boolean-filter.scss';
@import './components/button.scss';
@import './components/callout.scss';
@import './components/carousel.scss';
@import './components/chart-builder.scss';
@import './components/chart.scss';
@import './components/checkable-select-filter.scss';
@import './components/codemirror.scss';
@import './components/column-layout.scss';
@import './components/confirmation-prompt.scss';
@import './components/container.scss';
@import './components/create-query-prompt.scss';
@import './components/data-grid.scss';
@import './components/date-time-pickers.scss';
@import './components/divider.scss';
@import './components/drawer.scss';
@import './components/dynamic-width-helper.scss';
@import './components/appcues.scss';
@import './components/empty-state-call-to-action.scss';
@import './components/facebook-button.scss';
@import './components/facebook-connect-panel.scss';
@import './components/filter-builder.scss';
@import './components/filter-input.scss';
@import './components/filters-popover.scss';
@import './components/filters-strip.scss';
@import './components/filter-summary.scss';
@import './components/grid-list.scss';
@import './components/export-html.scss';
@import './components/icon.scss';
@import './components/insert-marker.scss';
@import './components/limit-prompt.scss';
@import './components/media-item-toolbar.scss';
@import './components/media-item-comment.scss';
@import './components/media-items-viewer.scss';
@import './components/quick-share.scss';
@import './components/media-assistant-modal.scss';
@import './components/media-item-viewer.scss';
@import './components/media-items-viewer-modal.scss';
@import './components/media-toolbar.scss';
@import './components/more-info.scss';
@import './components/notification.scss';
@import './components/popover.scss';
@import './components/popover-select.scss';
@import './components/query-grid.scss';
@import './components/query-table.scss';
@import './components/quick-search-filter.scss';
@import './components/quick-search.scss';
@import './components/quota-prompt.scss';
@import './components/radio.scss';
@import './components/responsive-modal.scss';
@import './components/section-header.scss';
@import './components/select.scss';
@import './components/sentiment.scss';
@import './components/sheet-layout.scss';
@import './components/snackbar.scss';
@import './components/stepper.scss';
@import './components/nested-menu-option-popover.scss';
@import './components/switch.scss';
@import './components/split-view.scss';
@import './components/table.scss';
@import './components/text-editor.scss';
@import './components/textfield.scss';
@import './components/tree-select.scss';
@import './components/validation-indicator.scss';
@import './components/view-header-content.scss';
@import './components/visibility-aware-component.scss';
@import './components/partial-loading-indicator.scss';
@import './components/plugin-loading-overlay.scss';
@import './components/widget.scss';
@import './components/top-navigation.scss';
@import './components/resizable-panel.scss';
@import './components/select-theme.scss';
@import './components/modal.scss';
@import './components/infoCard/channel-info-card.scss';
@import './views/error/error.scss';
@import './views/dashboard/dashboard-export-prompt.scss';
@import './views/dashboard/dashboard-print-summary.scss';
@import './views/dashboard/dashboard.scss';
@import './views/export-loading-indicator.scss';
@import './views/inbox.scss';
@import './views/insights.scss';
@import './views/join.scss';
@import './views/manage-subscriptions.scss';
@import './views/report-template.scss';
@import './views/reports/ai-summary-options.scss';
@import './views/reports/move-button.scss';
@import './views/reports/activity/reports-activity.scss';
@import './views/reports/activity/reports-activity-chart.scss';
@import './views/reports/activity/activitiesTable/activity-data-grid.scss';
@import './views/reports/activity/activitiesTable/benchmark-column-header.scss';
@import './views/reports/activity/activitiesTable/column-benchmark.scss';
@import './views/reports/activity/activitiesTable/full-data-grid-loader.scss';
@import './views/reports/activity/activitiesTable/partial-data-grid-loader.scss';
@import './views/reports/activity/activitiesTable/recipient/recipients-activities-table.scss';
@import './views/reports/activity/activitiesTable/report/report-activities-table.scss';
@import './views/reports/activity/activitiesTable/report/resend-report-modal.scss';
@import './views/reports/activity/activityStream/activity-stream.scss';
@import './views/reports/activity/activitiesTable/activities-table-select.scss';
@import './views/user-avatar.scss';
@import './views/user-settings.scss';
@import './views/workspaceSettings/workspace-settings.scss';
@import './views/workspaceSettings/Alerts/alerts.scss';
@import './views/workspaceSettings/Alerts/mentions-alerts.scss';
@import './views/workspaceSettings/Alerts/mentions-alert.scss';
@import './views/workspaceSettings/Alerts/spike-alerts.scss';
@import './views/workspaceSettings/Alerts/spike-alert.scss';
@import './views/queries/advanced-filter-builder.scss';
@import './views/queries/boolean-fixer-button.scss';
@import './views/queries/query.scss';
@import './views/queries/query-content-explorer.scss';
@import './views/queries/queries.scss';
@import './views/queries/channels-filter.scss';
@import './views/queries/search-assistant.scss';
@import './views/queries/select-exclude-filter.scss';
@import './views/workspaceSettings/Themes/theme.scss';
@import './views/workspaceSettings/Themes/themes.scss';
@import './views/workspaceSettings/ContentOptions/content-options.scss';
@import './widgets/filterComponents/range-filter.scss';
@import './widgets/filterComponents/checkable-select-filter.scss';
@import './views/discovery/trends/trends.scss';
@import './views/search/assistant/assistant.scss';
@import './views/search/assistant/typing-indicator.scss';
@import './icons/ai-icon.scss';

.menu-small ul li {
	font-size: 13px !important;
}

.menu-small {
	&__group {
		outline: none;
	}
}

.inset {
	padding: #{$spacing-unit}px;
}

.multiline-field {
	min-height: 100px;
}

.border {
	border: 1px solid $border-foreground;
}

.flex-full-height-width {
	display: flex;
	width: 100%;
	height: 100%;
}

.full-height {
	height: 100%;
}

.align-self {
	&--start {
		align-self: start;
	}
}

.slide-left {
	overflow-x: hidden;
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	transform-origin: 100% 50%;
	&-enter {
		animation: slide-in-from-left 0.255s forwards;
	}
	&-exit {
		animation: slide-in-from-left 0.255s reverse;
	}
}
.slide-left-with-width {
	overflow-x: hidden;
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	transform-origin: 100% 50%;
	&-enter {
		animation: slide-in-from-left-with-width 0.255s forwards;
		&-done {
			width: var(--widthEnd);
		}
	}
	&-exit {
		animation: slide-in-from-left-with-width 0.255s reverse;
		&-done {
			width: var(--widthStart);
		}
	}
}

.avatar--fallback {
	color: $app-background;
	background-color: $font-color;
	font-weight: $weight-bold;
}

.Mui-disabled {
	color: $font-disabled;
}

.MuiTabs-root {
	.Mui-selected {
		color: $primary-color;
	}
	.MuiTabs-indicator {
		background-color: $primary-color;
	}
}

@keyframes slide-in-from-left {
	0% {
		opacity: 0;
		transform: translateX(-100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

@keyframes slide-in-from-left-with-width {
	0% {
		width: var(--widthStart);
		opacity: 0;
		transform: translateX(-100%);
	}
	100% {
		width: var(--widthEnd);
		opacity: 1;
		transform: translateX(0%);
	}
}

@keyframes breath-in-out {
	0% {
		opacity: 0.8;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.8;
	}
}

.impersonation-icon {
	opacity: 1;
	animation: breath-in-out 1s infinite;
}

.icon-button--disabled {
	svg {
		color: $font-disabled;
	}
}
