.reports-activity {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;
	&__header {
		background-color: $app-background;
		border-bottom: 1px solid $border-foreground;
		padding: #{$spacing-unit * 2}px #{$spacing-unit * 1}px #{$spacing-unit * 2}px #{$spacing-unit * 2}px;
		@include breakpoint-up('sm') {
			padding: #{$spacing-unit * 2}px #{$spacing-unit * 4}px;
		}
	}
	&__body {
		position: relative;
		display: flex;
		flex-grow: 1;
		background-color: $app-background-inset;

		.empty-state {
			background-color: $app-background;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 5;
		}

		&__content {
			box-sizing: border-box;
			padding: #{$spacing-unit * 2}px;
			&__overview-panel {
				border-radius: 4px 4px 0 0;
				border-width: 1px 1px 0px 1px;
				border-style: solid;
				border-color: $border-foreground;
				background-color: $app-background;
				padding: #{$spacing-unit * 2.5}px;
			}
			&__activity-table-panel {
				border-radius: 0 0 4px 4px;
				border: 1px solid $border-foreground;
				background-color: $app-background;
			}
		}
		&__activity-stream-panel {
			background-color: $app-background;
			border-left: 1px solid $border-foreground;
		}
	}
}
