.sentiment__text {
	.typography--subtitle {
		color: $font-color;
	}
}
.sentiment__clickableToEdit {
	&:hover {
		background-color: $app-background-inset;
	}
}
.sentiment__color {
	transform: rotate(-38.2deg);
	&--positive {
		background-color: $positive;
	}
	&--neutral {
		background-color: $neutral;
	}
	&--negative {
		background-color: $negative;
	}
}
