$col-width-small: 50px;

.query-table {
	> table > thead > tr {
		> th:first-child {
			width: auto;
		}
		> th:not(:first-child) {
			width: $col-width-small;
		}
	}
}

.query-list-table {
	.MuiTableCell-root {
		border: unset;
		padding: #{$spacing-unit}px;
		.loading-indicator-container {
			height: 1px;
			width: 100%;
			position: relative;
			> .linear-progress {
				bottom: -3px;
			}
		}
	}
	.MuiTableCell-head {
		.typography,
		.link {
			color: $font-color;
			font-weight: $weight-bold;
		}
	}
	.MuiTableCell-body {
		.link {
			color: $font-color;
		}
	}
	.activity-chart {
		&__content {
			.chart-container--am-chart {
				height: 28px;
			}
			&__numbers {
				&__sum {
					margin-left: 8px;
				}
			}
		}
	}
}
