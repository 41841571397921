.responsive-modal {
	&__container {
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 24px;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;
		&__content {
			background: $app-background;
			border: 1px solid $border-foreground;
			border-radius: $border-radius;
		}
	}
}
