.themes {
	@include workspaceSettings;

	&__section-body {
		&__table {
			.MuiTableRow-root.MuiTableRow-hover:hover {
				background-color: transparent;
			}
			td,
			th {
				border-color: $border-foreground;
			}
			.MuiTableCell-head {
				color: $font-color;
				font-size: $typography-large;
			}

			&__row {
				&__theme-name-group {
					display: flex;
					align-items: center;
					color: $font-color;
					margin-right: 20px;

					&__badge {
						cursor: pointer;
						font-size: 1rem;
						text-transform: uppercase;
					}

					&__title {
						cursor: pointer;
						margin-left: #{$spacing-unit * 2}px;
					}

					.MuiSkeleton-root {
						margin-right: #{$spacing-unit}px;
					}
				}

				&__timestamp {
					color: $font-light;
					font-size: 0.875rem;
				}
			}
		}
	}
}

@include breakpoint-up(md) {
	.themes__table__row {
		&__theme-name-group {
			&__badge-container {
				padding: unset;
				width: 45px;
				height: 45px;

				&__badge {
					font-size: $typography-h1;
					width: 35px;
					height: 35px;
				}
			}
		}
	}
}
