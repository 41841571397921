.accordion {
	&.MuiPaper-elevation1 {
		box-shadow: unset;
	}
	.MuiAccordionSummary-root {
		padding: unset;
		min-height: 48px;
		background-color: $app-background-inset;
		padding: 0px 10px;
		border-radius: $border-radius;
		color: $font-color;
		&.Mui-expanded {
			min-height: 48px;
		}
	}
	.MuiAccordionSummary-content {
		margin: 0px;
	}
	.MuiAccordionDetails-root {
		padding: #{$spacing-unit * 3}px 10px #{$spacing-unit * 3}px;
	}

	//for any nested tables, we want to remove the padding so the content is flush in the accordion
	.MuiTableContainer-root {
		//important: for the visibility aware component to work in a table, you need to make sure the table isn't the scroll root
		overflow: unset;
		.MuiTableRow-root {
			.MuiTableCell-root:first-child {
				padding-left: 0px;
			}
			.MuiTableCell-root:last-child {
				padding-right: 0px;
			}
		}
	}
}

.xp {
	&__summary {
		background-color: lighten($app-background-inset, 2%);
		.MuiTypography-body1 {
			font-weight: 500;
			color: $font-color;
		}
		svg {
			fill: $font-color;
		}
		&.Mui-expanded {
			background-color: $app-background-inset;
		}
		&:hover {
			background-color: $app-background-inset !important;
		}
	}
	&__details {
		label {
			color: $font-color;
		}
	}
	.MuiAccordionSummary-content {
		margin: #{$spacing-unit * 2}px 0px !important;
	}
}
