@mixin selectStyling {
	&__control {
		background-color: $app-background-inset;
		border-color: $border-foreground;
		color: $font-color;
		flex-wrap: nowrap;
		.select {
			&__multi-value {
				background-color: $primary-color;
				color: $white;
				padding: 1px 2px 2px 4px;
				&__label {
					color: $white;
					font-weight: $weight-regular;
				}
				&__remove {
					&:hover {
						background-color: transparent;
					}
					svg {
						color: $white;
					}
				}
			}
		}
	}
	&__value-container {
		.css-b8ldur-Input {
			color: $font-color;
		}
	}
	&__menu {
		background-color: $app-background-inset;
		border-color: $border-foreground;
		color: $font-color;
		z-index: 2;
		.select__option--is-focused:not(.select__option--is-selected) {
			background-color: darken($app-background-inset, 2%);
			color: $font-color !important;
		}
	}
}

@mixin selectStylingLight {
	&__control {
		background-color: #f2f5f7;
		border-color: #e2e5e7;
		color: #2b4265;
		&:hover {
			border-color: rgba(0, 0, 0, 0.2);
		}
	}
	&__single-value {
		color: #2b4265;
	}
	&__menu {
		background-color: #f2f5f7;
		border-color: #e2e5e7;
		color: #2b4265;
		z-index: 2;
		.select__option--is-focused:not(.select__option--is-selected) {
			background-color: darken($grey-1, 2%);
			color: #2b4265 !important;
		}
		.date-options-group {
			border-bottom: 1px #e2e5e7 solid;
		}
	}
}
