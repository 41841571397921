.widget-tooltip {
	background-color: $app-background;
	color: $font-color;
}
.widget-selector {
	&__widget-container {
		display: flex;
		flex-wrap: wrap;
		&__widget {
			margin: #{$spacing-unit * 2}px 2%;
			padding-bottom: #{$spacing-unit * 1.5}px;
			padding-top: #{$spacing-unit}px;
			background-color: $app-background-inset;
			border: 2px solid $app-background-inset;
			border-radius: $border-radius;
			width: 28%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: $font-light;
			@include breakpoint-down('xs') {
				width: 100%;
			}

			&__icon {
				margin-bottom: #{$spacing-unit * 1.5}px;
			}
			&__info {
				margin-top: 4px;
				svg {
					path {
						stroke: $font-light;
					}
				}
			}
			&__label {
				text-align: center;
				font-size: $typography-large;
			}

			&:hover {
				background-color: $app-background-inset-hover;
				color: $font-color !important;
				cursor: pointer;
				> * {
					color: $font-color !important;
				}
				p {
					color: $font-color !important;
				}
				svg {
					path {
						stroke: $font-color;
					}
				}
			}

			&--selected {
				border-color: $primary-color;
				> * {
					color: $font-color;
				}
			}
		}

		margin: -#{$spacing-unit * 2}px;
	}
}
