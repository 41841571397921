@use 'sass:math';

.add-filter-button {
	color: $primary-color;
	background-color: $app-background-inset;
	padding-right: #{$spacing-unit}px;
	padding-left: #{$spacing-unit}px;
	&:hover {
		background-color: $app-background-inset-hover;
	}
	&--focus {
		background-color: $app-background-inset;
	}
}

.filters-popover {
	min-width: 230px;
	height: $popover-select-height;

	&__content {
		&__scroll-view {
			height: $popover-select-height !important;

			&__list {
				&__item {
					padding: 0;
					padding-left: #{$spacing-unit}px;
					.MuiListItemIcon-root {
						min-width: unset;
					}
					.MuiListItemText-primary {
						font-size: $typography-body;
					}
					.checkbox__control--disabled {
						color: $default-background-disabled;
					}
				}
			}
		}
	}
}

.filters-container {
	.full-width {
		min-width: 0;
	}
	&__filter {
		margin: #{$spacing-unit}px #{$spacing-unit * 2}px #{$spacing-unit}px 0px;

		.MuiFormControl-marginDense {
			//material ui is enforcing it's own margin on the text field components. we need to override and remove them, so the filter components lay out nicely
			margin: 0;
		}

		.simple-filter {
			width: 100%;
			.chip-textfield {
				&__label--shrinky {
					&__background {
						display: none;
					}
				}
			}
			.MuiInputLabel-shrink {
				.chip-textfield__label--shrinky__background {
					display: flex;
				}
			}
		}

		.range-filter {
			width: 100%;
		}

		.select__placeholder {
			color: $font-color;
		}
	}
	&--inline {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(auto-fit, 100%);

		@include breakpoint-up('md') {
			grid-template-columns: repeat(auto-fit, minmax(200px, 320px));
		}

		.filters-container__filter {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.checkable-autocomplete__selected-values {
			max-width: 50%;
		}
	}
}

.selected-filters {
	&__container {
		display: flex;
		align-items: center;
		.checkable-autocomplete {
			min-width: 0;
		}
		.MuiInputBase-root {
			height: 38px;
		}
		.MuiInputBase-input {
			padding-top: 0px;
			padding-bottom: 2px;
		}
		.chip-textfield {
			&__container {
				height: 38px;
				background-color: $app-background;
				.MuiInputBase-input {
					font-size: $typography-small;
					display: flex;
					justify-content: center;
					height: 100%;
				}
				&__input-container {
					&__scroll {
						padding-top: 0;
					}
					&__input {
						height: 100%;
					}
				}
			}
			&__label-shrinky {
				color: $shrinky-label-color;
				background-color: unset;
				&::after {
					background-color: $app-background;
					position: absolute;
					content: '';
					width: 50px;
					height: 5px;
					top: 9px;
					left: 2px;
					z-index: -1;
					border-radius: 20px;
				}
			}
		}
		.MuiInputLabel-root.Mui-disabled {
			color: $shrinky-label-color;
		}
	}
}
