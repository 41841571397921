.dashboard-print-summary {
	display: none;
	position: relative; //this hides the watermark
	height: 100%;

	&__left {
		padding: 50px 50px;
		flex-basis: 50%;
		box-sizing: border-box;
		background-color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		&__content {
			padding: 40px 20px;

			&__logo {
				position: absolute;
				top: 50px;
				left: 50px;
				max-height: 150px;
				max-width: 400px;
			}
			&__subtitle {
				color: $font-light;
				font-weight: $weight-regular;
			}
		}
	}
	&__right {
		padding: 50px 50px;
		flex-basis: 50%;
		box-sizing: border-box;
		background-color: $grey-2;
		display: flex;
		align-items: center;
		justify-content: center;
		&__content {
			border-radius: 20px;
			padding: 30px;
			background-color: $white;
			&__chart-list {
				font-size: $typography-body;
				color: $font-light;
				font-weight: $weight-regular;
				margin-top: #{$spacing-unit * 2}px;
				&__list-item {
					margin: 4px 0px;
					display: flex;
					align-items: center;

					&__bullet {
						display: inline-block;
						border-radius: 50%;
						height: 28px;
						width: 28px;
						line-height: 28px;
						font-weight: 700;
						text-align: center;
						margin-right: #{$spacing-unit * 1.5}px;
						color: #fff;
						background-color: var(--theme-color, $primary-color);
						flex-shrink: 0;
					}

					&__chart-name {
						display: inline-block;
						a {
							font-size: $typography-body;
							color: $font-color;
							font-weight: $weight-regular;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

.export-root__html-export__page {
	&__container {
		display: flex;
		flex-direction: column;
		height: 100%;
		box-sizing: border-box;
		padding: 50px 50px;
	}

	.dashboard-print-summary {
		display: flex;
	}

	.dashboard-chart {
		flex: 1 1 auto;
		align-items: normal;
		.filter-summary {
			padding: #{$spacing-unit}px;
			&__container {
				overflow: unset;
				text-overflow: unset;
				white-space: unset;
			}
		}

		&__am-chart-container,
		&__custom-container,
		&__custom-multi-container {
			padding: 0;
		}
		&__custom-multi-container {
			> .MuiGrid-container {
				height: unset;
			}
		}

		&__custom-container {
			.chart-print-header__content__left__title__title {
				font-weight: 700;
			}
		}

		.chart-print-header {
			&__content {
				&__right {
					&__logo {
						display: none;
						max-height: 100px;
						max-width: 250px;
						width: auto;
					}
				}
			}
		}
	}

	.dashboard-print-page-footer {
		flex: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__left {
			&__content {
				&__page-count {
					color: $font-light;
				}
			}
		}
		&__right {
			&__content {
				&__logo {
					max-width: 150px;
					height: auto;
				}
			}
		}
	}
}
