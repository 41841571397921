.range-filter {
	&:hover {
		.textfield-container__mui-control {
			.MuiOutlinedInput-notchedOutline {
				border-color: $border-hover;
			}
		}
	}
	&__input {
		.MuiInputLabel-shrink {
			&.Mui-focused {
				border-width: 1px;
				color: $font-light;
			}
		}

		input {
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: -moz-none;
			-o-user-select: none;
			user-select: none;
			cursor: pointer;
			caret-color: $app-background;
		}

		&__remove-icon,
		&__open-icon {
			margin-right: 6px;

			&__button {
				line-height: 0;
				cursor: pointer;
				color: $font-light;
				font-size: $typography-body;
				padding: 2px;

				.MuiSvgIcon-root {
					color: $font-light;
				}
				&:hover {
					.MuiSvgIcon-root {
						color: $font-color;
					}
				}
			}
		}

		.MuiInputLabel-root {
			font-size: $typography-body;
			&[data-shrink='false'] {
				color: $grey-4;
			}
		}

		.MuiInputBase-root {
			background-color: $app-background;
		}

		&.open {
			.range-filter__input__open-icon {
				display: none;
			}
			.MuiInputBase-root {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

			.MuiOutlinedInput-notchedOutline {
				border: 0;
				margin: -1px 0;
				box-shadow: 0px 5px 5px -3px $app-background, 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
			}
		}
	}

	&__popover {
		.MuiPaper-root {
			max-width: unset;
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.2), 0px 14px 10px 1px rgba(0, 0, 0, 0.14), 0px 14px 14px 2px rgba(0, 0, 0, 0.12);
		}
		&__container {
			&__content {
				padding: 0 #{$spacing-unit * 2}px #{$spacing-unit * 2}px;

				&__input {
					display: flex;
					align-items: flex-end;

					&:first-child {
						margin-top: #{$spacing-unit * 2}px;
						margin-bottom: #{$spacing-unit * 3}px;
					}

					.MuiInputBase-root {
						&::before {
							border-color: $border-foreground;
						}
					}

					&__label {
						white-space: nowrap;
						min-width: 80px;
						flex: 1 0;
						margin-right: #{$spacing-unit * 3}px;
					}
				}

				&__button-container {
					margin-top: 30px;
					display: flex;

					&__button {
						display: flex;
						justify-content: center;
						width: 50%;

						&--close {
							& .button {
								background: $app-background-inset;
								color: $font-color;
							}
						}
						& .button {
							width: 75%;
						}
					}
				}
			}
		}
	}
}
