.media-items-viewer {
	flex-grow: 1;
	min-width: 0;
	.viewer-container {
		.content-container {
			box-sizing: border-box;
			padding-top: #{$spacing-unit * 4}px;
		}
	}
	.media-item-list-container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		.media-item-list {
			.media-item-list-item {
				&:not(.media-item-list-item--unread, .media-item-list-item--loading, :hover) {
					background-color: $inbox-media-item-read;
				}
			}
		}
	}

	.media-toolbar {
		&__tools {
			.ai-icon {
				height: 17px;
				width: 17px;
			}
		}
	}
}

@include custom-breakpoint-up(inboxMd) {
	.media-item-list-item {
		&__content {
			&__with_controls {
				.media-toolbar__tools--expanded {
					display: none;
				}
			}
		}
	}

	.media-item-list-item--hovering {
		.media-item-list-item__with_controls {
			&__wrapper {
				&__headlineWrapper {
					&__info-card-hover {
						flex: 0 1 calc(100% - 215px);
						min-width: 0;
					}

					&--junk {
						p {
							flex: 0 1 calc(100% - 132px);
						}
					}

					&__date,
					button.sentiment {
						display: none;
					}
				}
			}

			.media-toolbar__tools--expanded {
				visibility: visible;
				display: block;

				.MuiButtonBase-root {
					padding: 8px;
					height: 36px;
					width: 36px;
				}
			}
		}
	}
}
