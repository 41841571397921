.ai-summary-section-options {
	.ai-summary-content-options {
		.radio-group-form-control__radio-group__radio-label {
			margin-top: #{$spacing-unit * 1.5}px;
			&__radio {
				margin-right: unset;
			}
			@include breakpoint-up('sm') {
				margin-top: unset;
			}
		}
		.MuiFormControlLabel-root {
			margin-right: #{$spacing-unit}px;
		}
	}
	.media-item-section-options {
		display: flex;
		flex-direction: column;
		margin-top: 6px;
		@include breakpoint-up('sm') {
			margin-top: unset;
		}
	}
}

.ai-summary-section-builder {
	&__footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		.inline {
			width: unset;
		}
		&__checkbox {
			margin-left: #{$spacing-unit * 3}px;
		}
	}
}

.report-workspace-content-options {
	&__label {
		display: flex;
		flex-direction: row;
		align-items: center;
		.more-info {
			margin-left: #{$spacing-unit * 0.5}px;
		}
	}
}

.report-media-assistant-content-options {
	&__question {
		&__label {
			display: flex;
			align-items: center;
			margin-bottom: 12px;

			.more-info {
				margin-left: #{$spacing-unit}px;
			}
		}

		.MuiInputBase-input {
			padding: #{$spacing-unit}px;
		}
	}
}
