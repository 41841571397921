$color-scheme: dark;
$black: #000000;
$white: #ffffff;
$grey-0: #f7f8f9;
$grey-1: #f1f1f3;
$grey-2: darken($grey-1, 1.5%);
$grey-3: darken($grey-1, 33%);
$grey-4: #8493a6;
$grey-5: #838395;
$grey-9: #202e4c;
$red-1: #f44336;
$green-1: #4caf50;
$yellow-1: #ff9800;
$purple-1: #b270db;
$purple-2: #7775e2;

$chart-blue: #1786d1;
$chart-yellow: #ffc119;
$chart-orange: #ff8745;
$chart-teal: #14b1c3;
$chart-pink: #f55aa2;
$chart-purple-light: $purple-1;
$chart-purple-heavy: $purple-2;
$chart-red-pink: #ff6158;
$chart-aqua: #06b4e8;

$positive: #2e9992;
$neutral: #aaafb5;
$negative: #da3763;
$mixed: #bbdbfc;

$font-color: #ffffff;
$font-light: rgb(193, 205, 223);
$font-disabled: #6d7786;
$font-heading-color: rgb(0, 23, 55);
$primary-color: #007fff;
$shrinky-label-color: $grey-4;

$primary-background: $primary-color; //#1976d2;
$primary-foreground: $white;
$primary-background-disabled: lighten($grey-9, 5%);

$error-background: $red-1;
$error-foreground: $white;

$confirm-background: $green-1;
$confirm-foreground: $white;
$confirm-background-light: darken($confirm-background, 40%);
$confirm-font-color: lighten($green-1, 40%);

$warning-background: $yellow-1;
$warning-foreground: $white;
$warning-background-light: darken($warning-background, 40%);
$warning-font-color: lighten($yellow-1, 40%);

$disabled-background: $grey-5;
$disabled-foreground: $white;

$default-background: $grey-0;

$table-toolbar-background: $white;
$table-toolbar-background-active: rgb(250, 224, 233);
$table-toolbar-foreground-active: rgb(220, 0, 78);

$textarea-background: $grey-9;
$textarea-color: $white;
$code-mirror-keyword: $purple-1;
$code-mirror-highlight-background: lighten($textarea-background, 10%);

//colour usage
$overlay: rgba(0, 0, 0, 0.75);
$icon-foreground: $font-color;
$field-background: darken($white, 50%);
$form-background: darken($white, 50%);
$app-background: rgb(16, 24, 39);
$drawer-background: $app-background;
$border-foreground: #2d3c58;
$border-foreground-light: darken($border-foreground, 5%);
$border-hover: lighten($app-background, 20%);
$border-focus: #1976d2;
$app-background-inset: #18223a;
$app-background-inset-2: #162135;
$app-background-inset-hover: #212f50;
$form-label: darken($grey-5, 50%);
$keyword-highlight-color: #1e3b68;
$tinyice-border-color: #eaeaea;

$tooltip-background-color: $font-light;
$tooltip-text-color: $app-background;

$media-item-hover: lighten($app-background, 5%);
$media-item-selected: lighten($app-background, 3%);
$inbox-media-item-read: darken($media-item-selected, 1%);

$quick-search-background: lighten($app-background, 5%);
$quick-search-background-hover: darken($quick-search-background, 1%);

$twitter-embed-background: #15202b;
$twitter-embed-background-hover: #1e2732;
$twitter-font-color-blue: rgb(107, 201, 251);
$twitter-font-color-grey: rgb(139, 152, 165);
$twitter-icon-pink: rgb(249, 24, 128);
$twitter-icon-background-pink: rgb(249, 24, 128, 0.1);
$twitter-icon-blue: rgb(29, 155, 240);
$twitter-icon-background-blue: rgb(29, 155, 240, 0.1);
$twitter-icon-green: rgb(0, 186, 124);
$twitter-icon-background-green: rgb(0, 186, 124, 0.1);
