.query-grid {
	width: 100%;
	.MuiImageList-root {
		font-size: 0; //some kind of weird mui bug that creates padding on the image tile!!
		overflow-x: hidden;
		justify-content: flex-start;

		.MuiImageListItem-root {
			font-size: $typography-body;
			line-height: unset;
		}
	}
}

.query-grid-card {
	height: 200px;
	display: flex;
	border: 1px solid $border-foreground;
	flex-direction: column;
	&__content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		&__title {
			padding: #{$spacing-unit * 2}px #{$spacing-unit * 2}px 0 #{$spacing-unit * 2}px;
			box-sizing: border-box;
			p {
				display: grid;
			}
			.link {
				color: $font-color;
				text-decoration: none;
				overflow: hidden;
				text-overflow: ellipsis;
				&:hover {
					opacity: 0.9;
				}
			}
		}
		.activity-chart {
			flex-grow: 1;
			display: flex;
			&__content {
				flex: 1;
				box-sizing: border-box;
				display: flex;
				flex-direction: column-reverse;
				align-items: flex-start;
				.chart-container--am-chart {
					height: 41px;
					width: 100%;
					z-index: 1;
				}
				&__numbers {
					display: flex;
					&__sum {
						font-size: 2.2rem;
						font-weight: $weight-bold;
						margin-left: #{$spacing-unit * 2}px;
					}
					&__benchmark {
						&__indicator {
							margin-top: 4px;
						}
						&__previous {
							margin-top: 4px;
						}
					}
				}
			}
			&__error {
				padding: #{$spacing-unit}px #{$spacing-unit * 2}px;
			}
		}
	}

	&__toolbar {
		padding: 10px 0;
		display: flex;
		border-top: 1px solid $border-foreground;
		justify-content: space-around;
	}
}
