.column-layout {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: auto;

	> .column {
		height: 100%;
		width: 100%;

		&:not(:first-child) {
			margin-top: #{$spacing-unit * 2}px;
			margin-left: 0;
		}
	}

	@include breakpoint-up(sm) {
		flex-wrap: nowrap;
		height: 100%;

		> .column {
			&:not(:first-child) {
				margin-top: 0;
				margin-left: #{$spacing-unit * 3}px;
			}

			&--1 {
				width: 100%;
			}
			&--2 {
				width: 50%;
			}
			&--3 {
				width: 33.334%;
			}
			&--4 {
				width: 25%;
			}
			&--5 {
				width: 20%;
			}
			&--6 {
				width: 16.667%;
			}
		}
	}
}
