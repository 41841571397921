.trends-loading-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background: rgba($app-background, 0.8);
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.trends-chart {
	position: relative;

	#trends-news-social-search {
		height: 600px;
	}
}

$collapseButtonSize: 50px;

.trends__arrow {
	color: $font-light;
	cursor: pointer;
	font-size: 37px;
}

.trends-search {
	background-color: $app-background-inset;
	min-height: 100%;
	display: flex;
	flex-direction: column;

	&__header {
		border-bottom: 1px solid $border-foreground;
		background-color: $app-background;
		min-height: 62px;
		display: flex;
		align-items: center;
		padding-left: #{$spacing-unit * 2}px;

		&__left {
			margin-right: #{$spacing-unit}px;
		}
	}

	&__content {
		position: relative;
		flex: 1;
		display: flex;
		overflow: hidden;
		min-height: 100%;

		&__left {
			flex: 1 0 auto;

			&__container {
				padding: #{$spacing-unit * 3}px;
				padding-bottom: #{$spacing-unit * 8}px;
				min-width: calc(100vw - 50px - #{$spacing-unit * 6}px);

				@include breakpoint-up('md') {
					min-width: 600px;
				}

				@include breakpoint-up('lg') {
					padding: #{$spacing-unit * 3}px;
					min-width: 0;
				}
			}

			&__top-bar {
				display: flex;
				justify-content: space-between;
				padding-bottom: #{$spacing-unit * 3}px;
				flex-direction: column;

				@media only screen and (min-width: 1289px) {
					flex-direction: row;
					align-items: center;
				}

				&__search {
					display: flex;
					flex: 1 0;
					align-items: center;
					padding-bottom: #{$spacing-unit * 3}px;

					@media only screen and (min-width: 1289px) {
						padding-bottom: 0;
					}

					&__label {
						margin-right: #{$spacing-unit * 2.3}px;
						p {
							white-space: nowrap;
						}
					}

					&__input {
						height: 45px;
						background-color: $textarea-background;
						border-color: $textarea-background;

						.MuiOutlinedInput-root {
							height: 45px;
						}
						.MuiOutlinedInput-inputMarginDense {
							&:focus {
								border: none !important;
								box-sizing: initial;
							}
						}
					}
				}

				&__date-country {
					display: flex;

					&__date {
						max-width: 300px;
						margin-right: #{$spacing-unit * 2}px;
					}

					&__country {
						max-width: 300px;

						.select-container {
							.select {
								.select__control {
									max-height: 45px;
									background: $app-background;
								}

								.select__menu {
									z-index: 3;
								}
							}
						}
					}
				}
			}

			&__news-social-search-chart {
				&__container {
					&--blur {
						filter: blur(20px);
						pointer-events: none;
					}

					&__word-cloud {
						background-color: $app-background;
						position: absolute;
						transform: translate(-50%, -50%);
						width: 95%;
						height: 95%;
						top: 50%;
						left: 50%;
						border: 1px solid $border-foreground;
						border-radius: 5px;
						padding: #{$spacing-unit * 2}px #{$spacing-unit * 2}px #{$spacing-unit * 2}px #{$spacing-unit * 4}px;
						display: flex;
						flex-direction: column;

						@include breakpoint-up('md') {
							width: 70%;
							height: 70%;
						}

						.inline {
							flex: 0 0 auto;
						}

						svg {
							cursor: pointer;
						}

						.chart {
							min-height: unset;

							&__am-chart-container {
								padding: 0 0 0 #{$spacing-unit * 2}px;
							}
						}
					}
				}
			}

			&__news-social-search-chart,
			&__location-affinity,
			&__terms-charts__related-news-terms,
			&__terms-charts__related-search-terms {
				padding: #{$spacing-unit * 3}px;
				background: $app-background;
				margin-bottom: #{$spacing-unit * 3}px;

				&__container {
					position: relative;
				}
			}

			&__terms-charts {
				&__related-news-terms {
					&__container {
						min-height: 500px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				.chart {
					display: flex;
					align-items: unset;
					min-height: 500px;
					padding: 0;
					width: 100%;

					&__am-chart-container {
						&.key-phrases {
							height: auto;
							padding: 0;
						}
					}
				}

				@include breakpoint-up('lg') {
					display: flex;
					&__related-search-terms {
						flex: 1;
					}
					&__related-news-terms {
						flex: 1;
						margin-right: #{$spacing-unit * 3}px;

						&__container {
							min-height: 400px;
						}
					}

					.chart {
						display: flex;
						min-height: 400px;
					}
				}
			}
		}

		&__right {
			max-width: 100%;
			display: flex;
			background: $app-background;

			@include breakpoint-up('lg') {
				max-width: 500px;
			}

			.trends-search__content__right__container {
				transition: width 1s ease;
				overflow: hidden;
				height: 100%;
			}

			&.open {
				.trends-search__content__right__container {
					width: 100vw;

					@include breakpoint-up('lg') {
						max-width: 450px;
					}
				}
			}
			&.closed {
				.trends-search__content__right__container {
					width: 0px;
				}
			}

			@include breakpoint-up('lg') {
				position: unset;
				right: unset;
				width: unset;
				height: unset;
				display: unset;
			}

			&__collapse-button {
				max-width: $collapseButtonSize;
				border-left: 1px solid $border-foreground;
				padding: #{$spacing-unit * 2}px;
				font-size: 20px;
				display: flex;

				svg {
					cursor: pointer;
					transition: color 0.5s ease-in-out;

					&:hover {
						color: $primary-color;
					}
				}

				@include breakpoint-up('lg') {
					display: none;
				}
			}

			.trends-top-stories {
				width: 100%;
				height: 100%;
				min-width: 400px;

				@include breakpoint-up('lg') {
					width: 450px;
					min-width: 0;
				}

				&__header {
					padding: #{$spacing-unit * 3}px #{$spacing-unit * 3}px #{$spacing-unit * 2}px;
					position: sticky;
					top: 0;
					background: $app-background;
					z-index: 1;
				}

				&__content {
					position: relative;

					&__media-items {
						&__media-item {
							cursor: default;
							padding: #{$spacing-unit * 3.5}px;
							border-bottom: 1px solid $border-foreground;

							&:hover {
								cursor: pointer;
								background-color: lighten($media-item-selected, 1%);
							}

							.media-item-list-item {
								border-bottom: none;
								padding: 0;

								&:hover {
									background-color: unset;
								}
							}
						}
					}
				}
			}
		}
	}
}

.trends-top-stories {
	display: flex;
	flex-direction: column;

	&__header {
		flex: 0;
	}

	&__tabs {
		height: 100%;

		.MuiTabs-flexContainer {
			border-bottom: 1px solid $border-foreground;
		}

		&__content {
			&__child {
				&--hidden {
					display: none;
				}
			}
		}
	}

	&__content {
		flex: 1;
		padding-bottom: #{$spacing-unit * 8}px;

		.trends-loading-container {
			&__content {
				.trends-top-stories__content__emptyState {
					padding: #{$spacing-unit * 3.5}px;
					text-align: center;

					&__icon {
						font-size: 50px;
						color: $primary-color;
					}
				}
			}
		}
	}
}

.trends-top-stories-media-item {
	display: flex;
	outline: none;

	&__content {
		&__source-headline {
			display: flex;
			> :not(:first-child) {
				margin-left: 10px;
			}

			&__headline {
				.typography--body {
					word-break: break-word;
				}
			}
		}

		&__title {
			margin-top: 10px;
		}
	}

	&__image {
		padding-left: 10px;
		img {
			max-width: 100px;
			height: auto;
			border-radius: 3px;
		}
	}
}

.trends-location-affinity {
	flex-direction: column;
	overflow: hidden;

	@include breakpoint-up('lg') {
		display: flex;
		flex-direction: row;
	}

	.trends-location-affinity-table,
	.trends-location-affinity-map {
		flex: 1 0 100%;

		@include breakpoint-up('lg') {
			flex: 1 0 50%;
			margin-bottom: 0;
		}
	}

	.trends-location-affinity-table {
		height: 350px;
		position: relative;
		max-height: 400px;
		width: 100%;
		max-width: 100%;

		@include breakpoint-up('md') {
			display: flex;
		}

		@include breakpoint-up('lg') {
			height: 500px;
			max-height: unset;
		}

		&__container {
			overflow: hidden;
			position: relative;
			flex: 1;
			max-width: 100%;
			width: 100%;
			height: 100%;

			&__table {
				&__no-data {
					background-color: transparent;
				}
			}
		}

		.MuiDataGrid-root {
			border: none;
			color: $font-color;
			width: 100%;
			position: relative;

			.MuiDataGrid-columnHeaderTitleContainer {
				padding: 0;
				.MuiDataGrid-columnHeaderTitle {
					color: $font-light;
				}
			}
			.MuiDataGrid-columnHeaders {
				border-bottom: 1px solid $border-foreground;
			}
			.MuiDataGrid-columnsContainer {
				position: sticky;
				top: 0;
				left: 0;
				width: 100%;
				background-color: $app-background;
				z-index: 1;
			}
			.MuiDataGrid-columnSeparator {
				display: none;
			}
			.MuiDataGrid-cell {
				border: none;
			}
		}
	}

	.trends-location-affinity-map {
		margin-bottom: #{$spacing-unit * 4}px;

		#trendsMapChart {
			height: 100%;
		}
	}
}

.trends-related-news {
	position: relative;
	min-height: 300px;
}

.trends {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__header {
		background: $app-background;
		flex: 1 0 auto;
		border-bottom: 1px solid $border-foreground;
		display: flex;
		position: sticky;
		top: 0;
		left: 0;
		align-items: center;
		padding: #{$spacing-unit * 2}px #{$spacing-unit * 3}px;
		z-index: 1;

		@include breakpoint-up('md') {
			top: 0;
		}
	}

	&__search-label {
		background-color: $app-background-inset;
		font-size: $typography-h3;
		z-index: 1;
		position: relative;
		margin: auto;
		padding: #{$spacing-unit * 3}px #{$spacing-unit * 4}px #{$spacing-unit}px #{$spacing-unit * 4}px;
		text-align: center;

		@include breakpoint-up('md') {
			font-size: $typography-h1;
			padding: #{$spacing-unit * 9.5}px #{$spacing-unit * 2}px #{$spacing-unit * 0.5}px #{$spacing-unit * 2}px;
			text-align: left;
		}
	}

	&__search {
		background-color: $app-background-inset;
		position: sticky;
		top: 0;
		margin: auto;
		z-index: 2;
		padding: #{$spacing-unit * 2}px;
		border-bottom: 1px solid $border-foreground;

		@include breakpoint-up('md') {
			padding: #{$spacing-unit * 3}px;
			position: relative;
			height: 110px;
		}

		&__content {
			position: relative;

			&__error {
				color: $red-1;
				margin-top: 20px;
			}

			.textfield-container__mui-control .MuiInputBase-root {
				background: $textarea-background;
				border-color: $textarea-background;
				border-radius: 10px;
				height: $search-input-height;
				z-index: 3;
			}

			&__input {
				padding: #{$spacing-unit * 1.1}px;

				&__adornment {
					margin-top: 0;
					margin-right: #{$spacing-unit}px;
					.icon-button {
						svg {
							color: $icon-foreground;
						}
					}
				}
			}
		}
	}
}

.trending-now {
	flex: 1 0 auto;

	&__loader {
		display: flex;
		justify-content: center;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: #{$spacing-unit * 4.5}px;
		margin-top: #{$spacing-unit * 3.5}px;
	}

	&__item {
		display: flex;
		margin-bottom: #{$spacing-unit * 3}px;

		&:last-child {
			padding-bottom: 180px;

			@include breakpoint-up('md') {
				padding-bottom: 100px;
			}
		}

		&__image {
			display: flex;
			align-items: center;
			margin-right: #{$spacing-unit * 3}px;

			img {
				max-height: 80px;
				width: auto;
				border-radius: 50px;
			}
		}

		&__content {
			display: flex;
			justify-content: center;
			flex-direction: column;
			flex: 1 auto;

			&__title {
				display: flex;
				p {
					cursor: pointer;
				}
			}

			&__subtitle {
				display: flex;
				flex-direction: column;
				margin-top: #{$spacing-unit}px;

				@include breakpoint-up('sm') {
					align-items: center;
					flex-direction: row;
				}

				&__title {
					cursor: pointer;
					margin-top: #{$spacing-unit}px;

					@include breakpoint-up('sm') {
						margin-top: 0;
					}
				}

				&__source {
					display: flex;
					align-items: center;
					margin-right: #{$spacing-unit * 1.5}px;
					cursor: pointer;

					.avatar {
						margin-right: #{$spacing-unit * 1.2}px;
						max-width: 22px;
						height: auto;
						z-index: 0;
					}
				}
			}
		}

		&__count {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: right;
		}
	}
}
