@use 'sass:math';

$inbox-lg-up: 1600px;
$inbox-lg-down: calc(#{$inbox-lg-up} - 1px);

$inbox-md-up: 1200px;
$inbox-md-down: calc(#{$inbox-md-up} - 1px);

@mixin custom-breakpoint-up($customBreakpoint) {
	@if $customBreakpoint == inboxLg {
		@media only screen and (min-width: $inbox-lg-up) {
			@content;
		}
	} @else if $customBreakpoint == inboxMd {
		@media only screen and (min-width: $inbox-md-up) {
			@content;
		}
	} @else {
		@warn "Custom breakpoint mixin supports: inbox";
	}
}

@mixin custom-breakpoint-down($customBreakpoint) {
	@if $customBreakpoint == inboxLg {
		@media only screen and (max-width: $inbox-lg-down) {
			@content;
		}
	} @else if $customBreakpoint == inboxMd {
		@media only screen and (max-width: $inbox-md-down) {
			@content;
		}
	} @else {
		@warn "Custom breakpoint mixin supports: inbox";
	}
}

@mixin date-picker-color-scheme-light-fix($color-scheme) {
	@if $color-scheme == light {
		-webkit-text-fill-color: $font-color;

		&::-webkit-datetime-edit-day-field,
		&::-webkit-datetime-edit-month-field,
		&::-webkit-datetime-edit-year-field {
			&:focus {
				background-color: #90caf9;
			}
		}
	}
}
