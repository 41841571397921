.dashboard__export-prompt {
	&__modal {
		&__container {
			max-width: 600px;

			&__content {
				&__buttons-container {
					margin-top: #{$spacing-unit * 2}px;
				}

				.select-theme__label,
				&__label {
					font-size: $typography-large;
					margin-bottom: #{$spacing-unit * 2}px;
					color: $font-color;
				}

				&__text-input {
					.Mui-error {
						color: $yellow-1;
					}

					.input-element {
						padding: #{$spacing-unit * 1.7}px;
					}
				}
			}
		}
	}
}
