@use 'sass:math';

.user-settings {
	&__user-fields {
		&:last-child {
			margin-bottom: 24px;
		}
		&__text-field {
			&--disabled {
				margin: #{math.div($spacing-unit, 2)}px #{$spacing-unit * 1.5}px 0 0;

				&__lock-icon {
					transform: scale(1.4);
					color: $grey-5;
				}
			}
		}
	}
	//todo: delete this, i dont think its being used
	.image-upload-cropper {
		&__container {
			height: 150px;
			max-width: 150px;
			border: unset;
			background-color: unset;
		}

		&__controls {
			flex-direction: row;
			div {
				justify-content: flex-start;
			}
		}
	}
	.image-upload {
		&__controls {
			bottom: 39px;
			right: 39px;
			display: none;
			border-radius: 50%;
			background-color: rgba($app-background, 0.7);
		}
		&__image-container {
			&__image {
				border-radius: 50%;
				object-fit: cover;
			}
		}
		&--image-empty {
			&__default-icon {
				display: flex;
				justify-content: center;
				border-radius: 50%;
				object-fit: cover;

				.avatar {
					&--lg {
						//Match width/height of the image cropper component
						width: 150px;
						height: 150px;
						font-size: 2rem;
					}
				}
			}
		}
		&:hover {
			.image-upload {
				&__controls {
					display: block;
				}
			}
		}
		.button {
			border-radius: $input-border-radius;
			border: 0px !important;
			height: 42px;
			width: 42px;
			background-color: rgba($app-background, 0.7);
			svg {
				font-size: 1.2rem;
				margin-left: 4px;
			}
		}
		.button-group {
			border-radius: $input-border-radius;
			background-color: rgba($app-background, 0.7);
		}
	}
	input:disabled {
		color: $font-light !important;
	}
	.textfield-container__mui-control,
	.select-container {
		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
