@use 'sass:math';

.select {
	&__input {
		color: $font-color;
	}
	&__control {
		.select {
			&__multi-value {
				border-radius: 20px;
				background-color: darken($field-background, 8%);
				padding: 0 0 0 2px;

				.workspace-user-option {
					p {
						color: $white;
					}
				}

				&__label {
					font-size: $typography-small;
					font-weight: $weight-light;
					color: $font-color;
				}

				&__remove {
					border-radius: 0 20px 20px 0;
					cursor: pointer;
					&:hover {
						background-color: darken($field-background, 5%);
						color: $border-hover !important;
					}
				}

				&:hover {
					border-color: $border-hover;
				}
			}
		}
	}
}

.select-container {
	.select {
		@include selectStyling;
	}
}

.adorned-content {
	display: flex;
	position: relative;
	align-items: center;
	flex-grow: 1;
	margin-left: 4px;
}
