.filter-input {
	transition: max-width 200ms cubic-bezier(0.25, 0.1, 0.25, 1), border-width 200ms linear, margin 200ms cubic-bezier(0.25, 0.1, 0.25, 1),
		border-width 200ms linear;
	max-width: 0;
	display: flex;
	height: 40px;

	&__input-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
		max-width: 0;
		transition: max-width 200ms cubic-bezier(0.25, 0.1, 0.25, 1), border-width 200ms linear,
			margin 200ms cubic-bezier(0.25, 0.1, 0.25, 1), border-width 200ms linear;
		&__input {
			//set min-width for input base to make sure there will be enough space to display auto-suggestion popper
			min-width: 200px;
			width: 100%;
			font-size: $typography-small;
			.MuiInputBase-input::placeholder {
				color: $font-light !important;
			}
		}
	}

	&--in {
		max-width: 100%;
		.filter-input {
			&__input-wrapper {
				max-width: 100%;
				margin: 0 0 0 4px;
			}
		}
	}
}
