.insights-view {
	transition: max-height 200ms linear, width 200ms cubic-bezier(0.25, 0.1, 0.25, 1), border-width 200ms linear;
	width: $insights-collapsed-width;
	border-left: 1px solid $border-foreground;
	flex-shrink: 0;
	background: $app-background;
	z-index: 1;
	height: 100%;
	overflow: hidden;
	.chart-label {
		font-size: $typography-body;
	}

	&--disabled {
		.icon-button {
			svg {
				color: $icon-foreground;
			}
		}
	}

	&--open {
		width: 100%;
		.insights-view {
			&__header {
				&__title {
					display: block;
				}
			}
			&__content {
				&__cover {
					width: 0;
				}
			}
		}
	}

	&__header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid $border-foreground;
		&__title {
			display: none;
			flex-grow: 1;
			margin-left: #{$spacing-unit * 3}px;
		}
		&__toggle {
			margin: 6px 8px 6px 0;
			.icon-button {
				border-radius: 3px;
				svg {
					height: 22px;
					width: 22px;
				}
			}
		}
	}

	&__content {
		height: 100%;
		position: relative;
		&__cover {
			background-color: $app-background;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			width: $insights-collapsed-width;
			transition: width 200ms cubic-bezier(0.25, 0.1, 0.25, 1), border-width 200ms linear;
		}
		&__chart-wrapper {
			padding: #{$spacing-unit * 3}px;
			box-sizing: border-box;
			margin-bottom: 100px;
		}
	}

	@media only screen and (min-width: $screen-width-sm-min) {
		&--open {
			width: $insights-width;
		}
	}
}
