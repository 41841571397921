$top-nav-height: 64px;
$inbox-width: 400px;
$insights-width: 300px;
$insights-collapsed-width: 60px;
$overlay-transparency: 0.75;
$popover-select-width: 280px;
$popover-select-height: 350px;
$input-border-radius: 50px;
$rectangular-button-height: 40px;
$popovers-width: 400px;
$search-input-height: 54px;

body {
	margin: 0;
	overflow: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.debug {
		*:focus {
			border: 1px solid red !important;
			box-sizing: border-box;
		}
	}
}

.app {
	display: flex;
	box-sizing: border-box;
	&--impersonation {
		border: 8px solid #005cdd;
	}
	height: 100vh;
	width: 100vw;

	.lazy-loader {
		transform: translate(50%, 50%);
		width: 100%;
		height: 100%;
	}
}

//temporary fix to get around this super annoying issue.
//https://github.com/facebook/create-react-app/issues/11773
//TODO check back in 3 months time to see if this is still an issue. around 01/06/22
body > iframe {
	pointer-events: none;
}
