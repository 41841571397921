@use 'sass:math';

button.button {
	&.MuiButton-root {
		border-width: 0;
	}
	&--pill {
		border-radius: 20px;
		padding: #{math.div($spacing-unit, 2)}px #{$spacing-unit * 2}px;
	}
	&--outlined {
		border: 1px solid $primary-color !important;
		color: $primary-color;
		> span {
			font-weight: $weight-bold;
		}
	}
	&--secondary {
		background-color: $app-background-inset;
		color: $font-color;
		&:hover {
			background-color: darken($app-background-inset, 3%);
			color: $font-color;
		}
	}
	&--secondary[disabled] {
		background-color: $app-background-inset;
		color: $font-light;
	}
	&.button--xs {
		width: 26px;
		height: 26px;
		.MuiFab-label {
			height: 15px;
			width: 15px;
		}
	}
}

.link {
	color: $font-light;
	cursor: pointer;
	&--body {
		color: $font-color;
	}
	&--primary {
		color: $primary-color;
	}
	&:hover {
		text-decoration: underline;
	}
}

.button-group {
	.button--default {
		background-color: $app-background-inset;
		color: $font-color;
		&:hover {
			background-color: darken($primary-color, 6%);
		}
	}
	.button--primary {
		background-color: $primary-color;
	}
	.button {
		border-radius: 0px;
	}
	.button:first-child {
		border-top-left-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
	}
	.button:last-child {
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
}

.clickable {
	outline: none !important;
}
