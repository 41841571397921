.theme-settings {
	@include workspaceSettings;

	&__section-body {
		&__label {
			text-transform: capitalize;
		}

		&__textfield-theme-name {
			input {
				font-size: $typography-body;
			}
			@include breakpoint-up(md) {
				width: 44.5%;
			}
		}
		&__select-font-family {
			@include breakpoint-up(md) {
				width: 90%;
			}
		}
		&__color-picker {
			.color-picker {
				&__swatch {
					border: 1px solid $app-background-inset;
				}
				&__swatch-button {
					background-color: $app-background;
					.MuiSvgIcon-root {
						fill: $font-light;
					}
				}
			}
		}

		&__image-cropper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			max-width: 800px;

			&__label {
				text-transform: capitalize;
			}

			.image-upload-cropper {
				&__container {
					.cropper-bg {
						background-image: none;
					}
					.cropper-modal {
						opacity: 0.1;
					}
				}
			}

			&__banner {
				margin-top: #{$spacing-unit * 2}px;
				width: 100%;
				max-width: 800px;
				.image-upload-cropper__container {
					max-width: 800px;
				}
			}

			&__badge {
				width: 100%;
				max-width: 200px;
				.image-upload-cropper__container {
					max-width: 200px;
					height: 200px;
				}
			}
			&__logo {
				width: 100%;
				max-width: 500px;
				.image-upload-cropper__container {
					max-width: 500px;
					height: 200px;
				}
			}
		}

		&__toolbar-buttons {
			margin-bottom: 60px;
		}

		.image-upload__image-container {
			position: relative;
			width: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
		}
	}
}
