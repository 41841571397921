.filters-strip {
	margin: #{$spacing-unit * 2}px #{$spacing-unit * 2}px 0;
	display: flex;
	flex-direction: column;

	@include breakpoint-up('md') {
		flex-direction: row;
	}

	&__filters {
		flex-grow: 1;
		margin-left: #{$spacing-unit}px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@include breakpoint-up('md') {
			flex-direction: row;
		}
		.add-filter-button {
			margin-top: #{$spacing-unit * 0.5}px;
		}

		&__date-filter {
			display: flex;
			padding-left: #{$spacing-unit * 2}px;
			flex-shrink: 0;
		}
	}
	&__date-filter {
		flex-shrink: 0;
		padding: #{$spacing-unit}px;
		padding-right: 0px;
		padding-left: 0px;
		.icon-button {
			svg {
				color: $icon-foreground;
			}
			&:hover {
				svg {
					color: $font-color;
				}
			}
		}
	}
}
