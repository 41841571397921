.top-navigation {
	position: relative;
	width: 100%;
	&__nav-bar {
		border-bottom: 1px solid $border-foreground;
		display: flex;
		justify-content: space-between;
		padding: 0 #{$spacing-unit}px;
		z-index: 1000;
		height: #{$top-nav-height};
		background-color: $app-background;
	}

	&__drawer {
		top: $top-nav-height;
		left: 0;
		position: absolute;
		padding-top: #{$spacing-unit * 2}px;
		height: calc(100vh - $top-nav-height);
		width: 100vw;
		z-index: 1099;
		box-sizing: border-box;
		background-color: $app-background-inset;
	}
}

.top-navigation-item {
	svg {
		height: 14px;
		width: 14px;
		color: $grey-4;
	}
	a {
		text-decoration: none;
		&:visited,
		&:link {
			color: $font-color;
		}
	}
	&__button {
		width: 100%;
		display: flex;
		&__expander {
			margin-right: #{$spacing-unit * 3}px;
		}
		&__label {
			flex-grow: 1;
			text-align: left;
			padding: #{$spacing-unit * 2}px #{$spacing-unit * 4}px;
			font-size: $typography-h3;
			font-weight: $weight-bold;
			color: $font-color;
		}
	}
	&__content {
		box-sizing: border-box;
		padding-left: #{$spacing-unit * 4}px;
	}
}

.view {
	@include breakpoint-down(sm) {
		.inbox {
			height: calc(100% - #{$top-nav-height});
		}
	}
}
