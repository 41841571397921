.activity-stream {
	display: flex;
	flex-direction: column;
	height: 100%;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: #{$spacing-unit * 0.8}px;
		border-bottom: 1px solid $border-foreground;
		box-sizing: border-box;
		&__toggle-visibility-button {
			.MuiTouchRipple-root {
				color: $icon-foreground;
			}
			.icon-button {
				border-radius: 3px;
				svg {
					height: 22px;
					width: 22px;
				}
			}
		}
		&__label {
			padding: #{$spacing-unit}px 0 #{$spacing-unit}px #{$spacing-unit}px;
			white-space: nowrap;
			font-size: 1rem;
		}
	}
	&__body {
		flex-grow: 1;
		display: flex;
	}
	.icon-button {
		svg {
			color: $icon-foreground;
		}
	}
}

.activity-stream-search-panel {
	width: 100vw;
	@include breakpoint-up('sm') {
		width: 300px;
	}
	height: 100%;
	display: flex;
	flex-direction: column;
}

.activity-stream-search {
	padding: #{$spacing-unit * 2}px #{$spacing-unit * 2}px #{$spacing-unit * 2}px #{$spacing-unit * 2}px;
	.MuiInputBase-root {
		background-color: $app-background-inset;
	}
}

.activity-stream-list {
	display: flex;
	flex-grow: 1;
	flex-direction: column;

	&__no-items-found {
		position: relative;
		height: 500px;
		padding: #{$spacing-unit * 2}px;

		&__content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			text-align: center;

			img {
				width: 100%;
			}
		}
	}
}

.activity-stream-list-item {
	border-bottom: 1px solid $border-foreground;
	padding: #{$spacing-unit * 2}px;
	box-sizing: border-box;
	&__header {
		display: flex;
		align-items: center;
		> :first-child {
			flex-grow: 1;
			margin-right: #{$spacing-unit}px;
		}
		.icon-button {
			svg {
				height: 16px;
				width: 16px;
			}
		}
	}
	&__body {
		display: flex;
		flex-direction: column;
		&__row {
			display: flex;
			align-items: center;
			margin-top: #{$spacing-unit * 0.5}px;
			> :first-child {
				width: 65px;
				flex-shrink: 0;
			}

			&__link {
				display: flex;
				align-items: center;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				> a {
					color: $primary-color;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}
}
