.boolean-fixer-button {
	&.icon-button--loading {
		height: 18px;
	}
	.icon-button {
		svg {
			color: $white;
		}
		background: $error-background;
		&--disabled {
			background: $error-background-disabled;
		}
		&:hover {
			background: $error-background-hover;
		}
	}
}
