.queries {
	height: calc(100% - #{$top-nav-height});
	display: flex;
	flex-direction: column;

	.MuiInputBase-root {
		font-size: $typography-body;
	}

	&__header {
		border-bottom: 1px solid $border-foreground;
		padding: #{$spacing-unit * 1.5}px #{$spacing-unit * 3}px;
		background-color: $app-background;
		position: sticky;
		top: 0;
		z-index: 1;

		@include breakpoint-up('md') {
			padding: #{$spacing-unit * 2}px #{$spacing-unit * 3}px;
		}
	}

	&__loading {
		box-sizing: border-box;
		padding-top: #{$spacing-unit * 2}px;
		display: flex;
		flex-direction: column;
		> :not(:first-child) {
			margin-top: #{$spacing-unit * 2}px;
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		height: calc(100% - #{$spacing-unit * 4}px);

		margin: #{$spacing-unit * 4}px 0;
		> :not(:first-child) {
			margin-top: #{$spacing-unit * 2}px;
		}

		.empty-state {
			margin: 0;
			height: unset;
			padding: 30px 0;

			&__image-box {
				max-width: 280px;
			}
		}
	}

	&__tools {
		position: relative;
		&__query-search-input {
			background-color: $textarea-background;
			width: 100%;
			@include breakpoint-up('md') {
				width: 300px;
			}
			.MuiCircularProgress-root {
				margin-right: #{$spacing-unit * 1.5}px;
			}
		}

		&__menu {
			width: 200px;
			&__list-item {
				display: flex;
				align-items: center;

				&__icon {
					width: 20px;
					margin-right: 40px;
				}

				&__text {
					font-size: $typography-body;
				}
			}
		}
	}

	&__workspace {
		&__container {
			&:last-child {
				padding-bottom: 50px;
			}
		}
	}

	.MuiAccordionSummary-content {
		max-width: 100%;
	}

	&__query-group-header {
		display: flex;
		align-items: center;
		flex-grow: 1;
		max-width: 100%;
		&__chevron-container {
			display: flex;
			justify-content: flex-end;
			flex-grow: 1;
		}
		&__workspace-query-group-name-readonly {
			margin-left: #{$spacing-unit}px;
		}

		.click-to-edit-field {
			min-width: 0;
		}
	}
}

.view {
	@include breakpoint-up(md) {
		.queries {
			height: 100%;
		}
	}
}
