.resizable-panel {
	max-height: 80vh;
	min-height: 8px;
	border-bottom: 1px solid $border-foreground;
	height: 180px;

	&--hidden {
		.resizable-panel__container__resize-bar {
			display: none;
		}
	}

	&__container {
		position: relative;
		height: 100%;

		&__resize-bar {
			position: absolute;
			cursor: ns-resize;
			width: 100%;
			z-index: 2;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			transition-duration: 0.22s;

			&__resize-handler {
				background-color: $app-background-inset;
				border-radius: 4px;
				border: 2px solid $border-foreground;
				width: 40px;
				height: 10px;
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				transition-duration: 0.22s;

				&__resize-handler-icon {
					background-color: $neutral;
					height: 2px;
					width: 20px;
					transition-duration: 0.22s;
				}
			}

			&--focused {
				box-shadow: 0 1px 2px 1px lighten($border-focus, 20%);

				.resizable-panel__container__resize-bar__resize-handler {
					border: 2px solid lighten($border-focus, 40%) !important;
					box-shadow: 0 0px 2px lighten($border-focus, 10%);

					&__resize-handler-icon {
						background-color: lighten($border-focus, 20%) !important;
					}
				}
			}
		}

		&__content {
			height: 100%;
			overflow: hidden;
		}
	}
}
