.confirmation-prompt {
	&__container {
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;

		&__main {
			background: $app-background;
			border: 1px solid $border-foreground;
			border-radius: $border-radius;
			padding: #{$spacing-unit * 2.5}px #{$spacing-unit}px #{$spacing-unit * 3}px #{$spacing-unit * 3}px;
			max-width: 350px;

			&__header {
				display: flex;
				&__title {
					flex-grow: 1;
					// Apply ellipsis to multiline text (up to 3 lines)
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
				&__close-button {
					align-self: flex-start;
				}
			}

			&__content {
				display: flex;
				flex-direction: column;
				padding: #{$spacing-unit}px #{$spacing-unit * 3}px 0 0;
				@include breakpoint-up(md) {
					max-width: 400px;
				}
			}
		}
	}
}
