.empty-state {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	&__center {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		width: 85%;
		max-width: 350px;
	}

	&__image-box {
		&--with-shadow {
			background-color: $app-background-inset;
			border-radius: 50%;
			width: 100%;
			max-width: 360px;
			margin-bottom: #{$spacing-unit * 3}px;
		}

		img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	&__main-message {
		font-size: $typography-h3;
	}

	&__sub-message {
		font-size: $typography-body;
		color: $font-light;
	}

	&__help-text-link {
		color: $font-light;
		background: none;
		border: none;
		padding: 0;
		text-decoration: underline;
		cursor: pointer;
	}
}
