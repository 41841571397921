.facebook-button {
	button {
		padding: 6px 10px;
		&.button--primary {
			background: #1877f2;
			color: #fff;
		}
		> span {
			display: flex;
		}
	}

	&--disabled {
		background: $disabled-background;
		color: darken(#fff, 5);
		cursor: none;
	}

	&--loading {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.4);
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__icon {
		line-height: 0;
		svg {
			vertical-align: middle;
			padding-right: 5px;
		}
	}
	&__label {
		vertical-align: middle;
		text-transform: uppercase;
	}
}
