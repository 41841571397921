.quick-search-filter {
	flex: 1 0 auto;

	&--no-min-width {
		min-width: 0;
	}

	.select-container {
		min-width: unset;
		.select__menu {
			max-width: 70vw;
		}
		.select__control {
			background-color: $app-background;
			border-radius: $input-border-radius;
			border: 1px solid $border-foreground;
			transition: all 100ms;

			&:hover {
				border: 1px solid $border-hover;
			}

			&:focus,
			&--is-focused,
			&--is-focused:hover {
				box-shadow: none;
				border-color: $primary-color;
			}
		}
		.select__placeholder {
			font-size: $typography-tiny;
		}
	}

	.chip-textfield__container__end-adornment span[title='Clear'] {
		display: none;
	}

	&__custom-filter {
		min-width: 0;
		padding-right: #{$spacing-unit * 2}px;
		border-radius: $input-border-radius;
		border: 1px solid $border-foreground;
		background-color: $app-background;
		display: flex;
		height: 38px;
		margin-top: 0px !important;

		&__adornment {
			display: flex;
			align-items: center;
			&__label {
				margin-left: #{$spacing-unit}px;
				font-size: $typography-small;
				color: $font-light;
				height: 100%;
			}
		}
	}

	> .textfield-container {
		.MuiOutlinedInput-adornedStart {
			padding-left: 0;
		}
	}

	&__custom-query-filter {
		margin-top: 0px !important;
		min-width: 0;
		padding-right: #{$spacing-unit * 2}px;
		background-color: $field-background;
		border-radius: $input-border-radius;
		border: 1px solid $border-foreground;
		display: flex;
		height: 36px;
		> :not(:first-child) {
			margin-left: #{$spacing-unit}px;
		}
		&__input-wrapper {
			display: flex;
			align-items: center;
			&__input {
				font-size: $typography-small;
			}
		}
	}

	.MuiFormControl-root {
		margin: 0;
	}

	&__custom-query-filter,
	&__text-filter,
	&__number-filter,
	&__custom-filter {
		background-color: $app-background;
		.MuiOutlinedInput-notchedOutline {
			border-radius: $input-border-radius;
			border-color: $border-foreground;
			border-width: 0px;
		}
		.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-width: 1px;
			}
		}
		.MuiInput-underline::before,
		.MuiInput-underline::after {
			display: none;
		}
		.MuiInput-underline {
			&.Mui-focused .MuiInput-input {
				background-color: transparent;
			}
			.MuiSelect-icon {
				color: $font-light;
			}
		}
		border-radius: $input-border-radius;
	}

	> .select-container {
		.select__value-container {
			padding-left: 0px;
			padding-right: 0px;
			display: flex;
			flex-wrap: nowrap;
			.select {
				&__multi-value {
					margin-left: 4px;
					min-width: unset !important;
				}

				&__placeholder {
					margin-left: 6px;
					position: initial;
					transform: none;
				}
				&__input {
					> input {
						margin-left: 4px;
						font-size: $typography-small !important;
					}
				}
			}
			.css-b8ldur-Input {
				margin: 0px 0px 2px 2px;
			}
		}
		.select__indicators {
			.select__clear-indicator {
				display: none;
			}
		}
	}

	input.input-element {
		font-size: $typography-small;
		font-weight: $weight-semi;
		background-color: transparent;
	}

	&__number-filter {
		&__adornment {
			display: flex;
			align-items: center;
			&__label {
				font-family: monospace;
				font-size: $typography-small;
				color: $font-light;
				height: 100%;
			}
		}
		.input-element {
			font-weight: $weight-semi;
		}
	}

	.css-1pahdxg-control {
		box-shadow: 0 0 0 1px $border-foreground;
	}

	.checkable-autocomplete {
		&__text-field {
			border-radius: $input-border-radius;
		}
		border-radius: $input-border-radius;
		.MuiAutocomplete-inputRoot {
			background-color: $app-background;
			border-radius: $input-border-radius;
			height: 36px;
			.MuiAutocomplete-input {
				min-width: 100px;
				&::placeholder {
					font-size: $typography-tiny;
				}
			}
		}

		&--has-chips {
			.MuiAutocomplete-inputRoot {
				.MuiAutocomplete-input {
					min-width: 2px;
				}
			}
		}

		.MuiAutocomplete-tag {
			background-color: $primary-color;
			color: $white;
			margin-left: 8px;
			svg {
				color: $white;
			}
		}
	}

	.chip-textfield__container {
		border-radius: $input-border-radius;
		&__input-container {
			margin-left: #{$spacing-unit}px;
		}
	}

	svg {
		height: 16px;
		width: 16px;
	}
}

.custom-query-popover {
	&__content {
		padding: #{$spacing-unit * 2}px;
		box-sizing: border-box;
		min-width: 400px;
		&__text-wrapper {
			display: flex;
			width: 100%;
		}
	}
}
