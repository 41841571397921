@use 'sass:math';

.media-item-toolbar {
	border: 1px solid $border-foreground;
	padding: #{math.div($spacing-unit, 2)}px #{$spacing-unit}px #{math.div($spacing-unit, 2)}px #{$spacing-unit * 1.5}px;
	border-radius: $border-radius;
	&__metadata {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		&__audience,
		&__engagement {
			svg {
				width: 20px;
			}
		}

		.sentiment {
			&__text {
				p {
					color: $font-light;
				}
			}
			&__color__single {
				width: 15px;
				height: 15px;
				@include breakpoint-up('sm') {
					width: 20px;
					height: 20px;
				}
			}
		}

		> div {
			margin: #{$spacing-unit}px #{$spacing-unit * 2}px #{$spacing-unit}px 0;
		}

		> :not(:first-child) {
			margin-left: 6px;
		}
	}
}
