.activity-chart {
	color: $font-disabled;
	&__error {
		display: flex;
		padding: #{$spacing-unit}px;
		align-items: center;
	}
	&__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
	}
	&__content {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		.chart-container--am-chart {
			display: flex;
		}
		&__numbers {
			display: flex;
			flex-wrap: nowrap;
			&__benchmark {
				&__indicator {
					margin-left: 12px;
				}
				&__previous {
					margin-left: 12px;
					color: $font-light;
				}
			}
		}
	}
	&--active {
		color: $font-color;
	}
}
