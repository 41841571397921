.partial-data-grid-loader {
	display: flex;
	justify-content: stretch;
	padding: 0;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	align-items: flex-end;
	.MuiLinearProgress-root {
		width: 100%;
	}
}
