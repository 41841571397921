.spike-alerts {
	&--error {
		position: relative;
		height: 95%;

		.empty-state {
			position: absolute;
			height: 100vh;
			transform: translate(-50%);
			left: 50%;
			top: 0;
		}
	}

	&__title {
		margin-bottom: 40px;
	}
}
