.channels-filter {
	display: flex;
	justify-content: center;
	padding: 30px 30px;
	flex: 1;
	flex-direction: column;

	@media screen and (min-width: 960px) {
		flex-direction: row;
	}

	&__container {
		display: flex;
		flex-flow: column;
		flex-grow: 1;
		flex-basis: 0;
		margin: 0 10px 10px 10px;
		max-width: 500px;

		&__label {
			margin-bottom: 15px;

			@media screen and (min-width: 960px) {
				white-space: nowrap;
			}
		}

		.droppable {
			overflow: hidden;
			display: flex;
			flex-direction: column;
			flex: 1;
			border: 1px solid $border-foreground;
			border-radius: 5px;
			transition: border 0.5s ease;
			min-height: 300px;
			height: 300px;
			max-height: 300px;

			@media screen and (min-width: 960px) {
				max-height: unset;
				height: 100%;
			}

			.empty {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex: 1;
				padding: 40px;
				text-align: center;

				svg {
					path {
						fill: $font-light;
					}
				}
			}

			&--dragging {
				border-color: $primary-color;
			}

			&__item {
				display: flex;
				align-items: center;
				padding: 10px 20px;

				&__content {
					display: flex;
					align-items: center;
				}

				&__hover {
					display: flex;
					align-items: center;
					flex: 1 0 auto;
					margin: 0 #{$spacing-unit}px;
					svg {
						path {
							stroke: $font-light;
						}
					}
				}

				&__avatar {
					margin-right: 5px;
				}

				&__label {
					flex: 1;
				}

				&__count {
					color: $font-light;
				}
			}
		}
	}
}
