.activity-data-grid {
	border: unset;

	//disable blue highlight
	.MuiDataGrid-columnHeader:focus,
	.MuiDataGrid-columnHeader:focus-within,
	.MuiDataGrid-cell:focus,
	.MuiDataGrid-cell:focus-within {
		outline: none !important;
	}

	//set all icon buttons to a smaller size
	.MuiIconButton-root {
		height: 30px;
		width: 30px;
	}

	//set all svgs to the right colour
	svg {
		color: $icon-foreground;
	}

	//fix columns from getting line heights injected into them
	.MuiDataGrid-columnHeaderTitleContainerContent {
		* {
			line-height: 1.25rem !important;
		}
	}

	//override font colours
	color: $font-color;
	.MuiTablePagination-root {
		color: $font-color;
	}

	//fix background colour of pinned columns
	.MuiDataGrid-pinnedColumnHeaders,
	.MuiDataGrid-pinnedColumns {
		background-color: $app-background;
	}

	//override background for inset colours
	.MuiDataGrid-detailPanel {
		background-color: $app-background;
		box-sizing: border-box;
		padding: 0 0 0 #{$spacing-unit * 8}px;

		//set the background colours of the nested tables
		.nested-table {
			background-color: $app-background-inset-2;
			.MuiDataGrid-pinnedColumnHeaders,
			.MuiDataGrid-pinnedColumns {
				background-color: $app-background-inset-2;
			}
		}
	}

	//hide the column separators that appear when hovering on column headers
	.MuiDataGrid-iconSeparator {
		visibility: hidden;
		display: none;
	}

	//set the borders and border colours of the grid
	.MuiDataGrid-withBorderColor {
		border-color: $border-foreground;
	}
	.MuiDataGrid-columnHeader {
		border-bottom: 1px solid $border-foreground;
		border-right: 1px solid $border-foreground;
	}
	.MuiDataGrid-columnHeaderTitleContainer {
		border: unset !important;
	}
	.MuiDataGrid-cell {
		border-bottom: 1px solid $border-foreground;
		border-right: 1px solid $border-foreground;
	}

	//override the existing scrollbar styles
	.MuiDataGrid-virtualScroller::-webkit-scrollbar {
		width: 6px;
		height: 0px;
		scrollbar-width: thin;
	}

	//stop pinned columns from having drop shadows
	.MuiDataGrid-pinnedColumnHeaders,
	.MuiDataGrid-pinnedColumns {
		box-shadow: none;
	}

	.MuiDataGrid-virtualScroller {
		background-clip: text;
		-webkit-background-clip: text;
		transition: all 0.8s;

		&:hover {
			background-color: $border-hover;
		}
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
			scrollbar-width: thin;
		}
		&::-webkit-scrollbar-track {
			display: none;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: inherit;
		}
	}
}

//push the nested tables in so the first column aligns with the parent column
.report-activity-table {
	margin-left: #{-$spacing-unit * 1.9}px;
}
.recipient-activity-table {
	margin-left: #{-$spacing-unit * 1.9}px;
}
