.reports-activity-chart {
	display: flex;
	flex-direction: column;
	&__header {
		font-size: $typography-extra-large;
		font-weight: $weight-bold;
	}
	&__content {
		height: 350px;
	}
	&__metric-options {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-top: #{$spacing-unit * 2}px;
		flex-wrap: wrap;
		.select-container {
			width: 140px;
			min-width: unset;
			.select__control {
				background-color: $app-background;
			}
			div[class*='css-'] {
				z-index: 2;
			}
		}
		.select__menu {
			background-color: $app-background;
		}
		&__left-option__start-adornment,
		&__right-option__start-adornment {
			width: 15px;
			height: 15px;
			margin-left: #{$spacing-unit}px;
			border-radius: 50%;
		}
		&__left-option__start-adornment {
			background-color: $chart_blue;
		}
		&__right-option__start-adornment {
			background-color: $chart_orange;
		}
		&__label {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 #{$spacing-unit}px;
		}
	}
}
