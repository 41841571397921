.select-theme {
	font-family: #{$font-family};
	&__label {
		margin-bottom: #{$spacing-unit * 2}px;
		font-size: #{$typography-small};
	}

	&__link {
		color: $primary-color;
		white-space: nowrap;
		cursor: pointer;
		font-size: $typography-body;
	}
}
