@use 'sass:math';

.modal {
	z-index: 1001; //z-index of top navigation in mobile view is 1000
	&__container {
		outline: 0;
		@include breakpoint-up('md') {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&__content {
			position: relative;
			background: $app-background;
			display: flex;
			flex-direction: column;
			max-height: 100vh;
			height: 100vh;
			width: 100vw;

			@include breakpoint-up('md') {
				height: auto;
				width: auto;
				border: 1px solid $border-foreground;
				border-radius: $border-radius;
			}

			&__header {
				flex-shrink: 0;
				flex-grow: 0;
				padding: #{$spacing-unit * 4}px #{$spacing-unit * 4}px 10px;
				box-sizing: border-box;
			}
			&__body {
				flex-grow: 1;
				&__content {
					padding: #{$spacing-unit * 2}px #{$spacing-unit * 5}px;
					box-sizing: border-box;
				}
				&--scroll {
					//this is a weird hack for windows. for some reason, in chrome/ff, the horizontal scrollbar appears. we almost never want to use
					//the horizontal scrollbar. so its probably safe to disable it completely in the modal body
					.scroll-view {
						> div:first-child {
							overflow-x: hidden !important;
						}
					}
				}
			}
			&__footer {
				flex-shrink: 0;
				flex-grow: 0;
				padding: #{$spacing-unit * 3}px;
			}
		}
	}
}
