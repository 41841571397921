.media-item-comments {
	margin: 0px #{$spacing-unit}px;
	&__filters {
		.toolbar-button {
			margin-right: #{$spacing-unit}px;
		}
		.MuiOutlinedInput-notchedOutline {
			border-radius: $input-border-radius;
			border-color: $border-foreground;
			border-width: 0px;
		}
		.input-element {
			font-size: $typography-large;
		}
		.inline .select-container {
			min-width: 210px;
			width: 210px;
		}
	}
	&__pagination {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.media-item-comment {
	display: flex;
	&__left {
		flex-grow: 1;
	}
	&__right {
		width: 100px;
		flex-shrink: 0;
	}
	&__engagement {
		display: flex;
		> :not(:first-child) {
			margin-left: #{$spacing-unit}px;
		}
	}
	&__attachment {
		&__image {
			width: 100%;
		}
	}
}
