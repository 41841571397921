@use 'sass:math';

$drawer-nav-menu-width: 240px;
$user-settings-menu-width: 300px;

.drawer {
	height: 100%;
	display: flex;
	flex-direction: row;
	z-index: 99;

	&__buttons {
		z-index: 2;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-style: solid;
		border-color: $border-foreground;
		border-width: 0 1px 0 0;

		> div {
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			> :not(:first-child) {
				margin-top: #{$spacing-unit * 1.2}px;
			}
		}

		&__top {
			padding: #{$spacing-unit}px 0;
		}

		&__bottom {
			padding: #{$spacing-unit * 1.75}px 0;
		}
	}

	&__content {
		width: 0;
		&--expanded {
			width: $drawer-nav-menu-width;
		}
	}
}

.drawer-button {
	padding: 0 #{$spacing-unit}px;
	position: relative;

	&__button {
		.MuiTouchRipple-child {
			background-color: $grey-5;
		}

		display: flex;
		align-items: center;
		justify-content: center;
		height: 44px;
		width: 44px;
		border-radius: $border-radius;
		svg {
			color: $font-color;
			height: 22px;
			width: 22px;
		}
	}

	&:hover:not(.drawer-button--active, .drawer-button--disabled) {
		.hover-panel {
			&--open {
				z-index: 1001;
			}
		}
		.drawer-button__button {
			background-color: $app-background-inset;
		}
	}

	&--disabled {
		.drawer-button__button {
			svg {
				color: $font-disabled;
			}
		}
	}

	&--active {
		.drawer-button__button {
			background-color: $primary-color;
			svg {
				color: $white;
			}
		}
	}

	&--home {
		.drawer-button__button {
			img {
				width: 32px;
				height: 32px;
				margin-left: 4px;
			}
		}
	}
}

.navigation-panel {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	&__header {
		z-index: 0;
		padding: #{$spacing-unit * 2}px #{$spacing-unit * 2}px #{$spacing-unit * 2}px #{$spacing-unit * 3}px;

		&__tools {
			> :not(:first-child) {
				margin-left: #{$spacing-unit}px;
			}
		}
	}

	&__content {
		flex-grow: 1;

		&__scrollable {
			//we give this a bit of leeway so that the last item isn't stuck to the bottom of the screen
			padding-bottom: 50px;
		}
	}
}

.navigation-item {
	svg {
		height: 14px;
		width: 14px;
		color: $grey-4;
	}
	a {
		text-decoration: none;
		&:visited,
		&:link {
			color: initial;
		}
	}
	display: flex;
	flex-direction: column;

	&__button {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		box-sizing: border-box;
		height: 42px;
		align-items: center;
		padding-right: #{$spacing-unit}px;

		.MuiTouchRipple-child {
			background-color: $grey-5;
		}

		&__icon {
			height: 30px;
			width: 30px;
			display: flex;
			flex-shrink: 0;
			justify-content: center;
			align-items: center;
			margin-right: #{math.div($spacing-unit, 2)}px;
			border-radius: 100%;
			&--show-on-hover {
				display: none;
			}
			&:hover {
				color: $primary-color;
				background-color: $app-background;
			}
		}

		&__icon-placeholder {
			height: 30px;
			width: 30px;
		}

		&__label {
			flex-grow: 1;
			text-align: left;
			font-size: $typography-small;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			margin-right: 12px;
			color: $font-color;
		}

		&__hover-menu {
			visibility: collapse;
		}

		&__expander {
			justify-self: flex-end;
			margin-right: #{$spacing-unit}px;
		}
	}

	&--active {
		.navigation-item {
			&__button {
				&__label {
					font-weight: $weight-bold;
					color: $primary-color;
				}
				&__icon {
					color: $primary-color;
				}
			}
		}
	}

	&:hover:not(.navigation-item--group) {
		.navigation-item {
			&__button {
				&__icon {
					&--hide-on-hover {
						display: none;
					}
					&--show-on-hover {
						display: flex;
					}
				}
				&__label {
					color: $primary-color;
				}
				&__hover-menu {
					visibility: visible;
				}
			}
		}
		svg {
			color: $primary-color;
		}
	}
	&--group {
		.navigation-item {
			&__button {
				padding-left: #{$spacing-unit * 3}px;
				&__icon-placeholder {
					display: none;
				}
				&__label {
					text-transform: uppercase;
					color: $grey-4;
				}
				&__icon {
					color: $grey-4;
				}
			}
		}
	}
	&--indentation-0 {
		.navigation-item__button {
			padding-left: #{$spacing-unit * 2}px;
		}
	}
	&--indentation-1 {
		.navigation-item__button {
			padding-left: #{$spacing-unit * 2.5}px;
		}
	}
	&--indentation-2 {
		.navigation-item__button {
			padding-left: #{$spacing-unit * 3.5}px;
		}
	}
}

@keyframes animateHoverPanelIn {
	0% {
		width: 0;
	}
	100% {
		width: $drawer-nav-menu-width;
	}
}

@keyframes animateHoverPanelOut {
	0% {
		width: $drawer-nav-menu-width;
	}
	100% {
		width: 0;
	}
}

.hover-panel {
	width: 0;
	background-color: $app-background-inset;
	position: fixed;
	height: 100vh;
	z-index: 999;
	left: 60px;
	top: 0;
	animation-delay: 300ms;
	animation: animateHoverPanelOut 0.25s;

	&--open {
		width: $drawer-nav-menu-width;
		border: 1px solid $border-foreground;
	}
}

.user-settings-drawer-button {
	.hover-panel--open {
		width: 300px;
		top: unset;
		bottom: 5px;
		height: auto;
		box-shadow: $container-box-shadow;
	}
}

.user-settings-menu {
	width: $user-settings-menu-width;
	overflow: hidden;
	.user-name .MuiListItemText-primary {
		font-weight: $weight-bold;
		font-size: $typography-large;
	}
	svg {
		margin-left: #{$spacing-unit}px;
		color: $font-light;
		font-size: 1.3rem;
	}
	.avatar {
		svg {
			margin: unset;
		}
	}

	&__list-item {
		display: flex;
		align-items: center;
		font-size: $typography-body;

		&__text {
			padding-left: 50px;

			&__primary {
				font-size: $typography-large;
			}

			&__secondary {
				font-size: $typography-body;
			}
		}
	}

	&__switch {
		display: flex;
		align-items: center;

		&__text {
			font-size: $typography-extra-tiny;
			font-weight: bold;
		}
	}

	&__user-role {
		background-color: $grey-5;
		padding: 3px 7px;
		border-radius: 5px;
		font-size: $typography-extra-tiny;
		font-weight: bold;
		color: $white;
	}

	@include breakpoint-up('md') {
		width: unset;
	}
}

.navigation-empty-state {
	margin: 0 #{$spacing-unit * 4}px #{$spacing-unit}px 0;
	&__primary-button {
		margin-top: #{$spacing-unit * 2}px;
	}
	&__help-section {
		margin-top: #{$spacing-unit * 2}px;
		&__link {
			color: $font-light;
			background-color: none;
			border: none;
			padding: 0;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	@include breakpoint-up('sm') {
		margin: 0 #{$spacing-unit}px #{$spacing-unit}px #{$spacing-unit}px;
	}
}
