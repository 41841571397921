.facebook-connect-panel {
	width: auto;
	margin: 0 50px;
	padding: 20px;
	background: $app-background-inset;
	border-style: dashed;
	border-color: $border-foreground;
	border-width: 2px;

	.typography {
		&--h2 {
			padding-bottom: 10px;
		}
	}

	ul {
		padding: 0;
		li {
			list-style-type: none;
			padding-left: 15px;
			position: relative;
			margin-left: 5px;
			list-style-type: none;
			padding-bottom: 5px;
			&:before {
				content: '\00b7';
				font-size: 60px;
				line-height: 20px;
				position: absolute;
				left: -10px;
				top: 0;
			}
		}
	}

	&__controls {
		&__skip {
			color: $font-light;
			font-weight: bold;
			text-transform: uppercase;
			padding-top: #{$spacing-unit * 2}px;

			@include breakpoint-up('md') {
				text-align: right;
			}
		}
	}
}
