.nested-menu-option-popover {
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	width: 250px;

	&__list-item {
		font-size: $typography-body;

		&__text {
			padding-left: 30px;
		}
	}
}
