$color-scheme: light;

//units and sizes
$spacing-unit: 8;
$drawer-width: 240px;
$drawer-width-collapsed: 56px;
$overlay-transparency: 0.55;
$border-radius: 3px;

//colours
$black: #000000;
$white: #ffffff;
$grey-0: #f8f9fa;
$grey-1: #f2f5f7;
$grey-2: darken($grey-1, 1.5%);
$grey-3: darken($grey-1, 33%);
$grey-4: #8493a6;
$grey-5: #838395;
$grey-9: #2b4265;
$red-1: #f44336;
$red-2: #d43a45;
$green-1: #4caf50;
$green-2: #3f9654;
$yellow-1: #ff9800;
$yellow-2: #cc8000;
$purple-1: #b270db;
$purple-2: #7775e2;
$chart-blue: #1786d1;
$chart-yellow: #ffc119;
$chart-orange: #ff8745;
$chart-teal: #14b1c3;
$chart-pink: #f55aa2;
$chart-purple-light: $purple-1;
$chart-purple-heavy: $purple-2;
$chart-red-pink: #ff6158;
$chart-aqua: #06b4e8;

$positive: #2e9992;
$neutral: #aaafb5;
$negative: #da3763;
$mixed: #bbdbfc;

$font-color: #2b4265;
$font-light: #8493a6;
$font-disabled: #b9c3d0;
$font-heading-color: #2b4265;
$primary-color: #005cdd;
$shrinky-label-color: $grey-4;

$primary-background: $primary-color;
$primary-foreground: $white;
$primary-background-hover: darken($primary-color, 7%);
$primary-foreground-hover: $white;
$primary-foreground-disabled: rgba(255, 255, 255, 0.65);
$primary-background-disabled: $grey-5;

$secondary-background: $grey-5;
$secondary-foreground: $white;
$secondary-background-hover: darken($secondary-background, 7%);
$secondary-foreground-hover: $white;
$secondary-foreground-disabled: rgba(255, 255, 255, 0.65);
$secondary-background-disabled: desaturate($secondary-background, 20%);

$error-background: $red-1;
$error-foreground: $white;
$error-background-hover: darken($red-1, 7%);
$error-foreground-hover: $white;
$error-foreground-disabled: rgba(255, 255, 255, 0.65);
$error-background-disabled: desaturate($red-1, 20%);

$default-background: $grey-0;
$default-foreground: darken($grey-5, 15%);
$default-background-hover: rgba(0, 0, 0, 0.2);
$default-foreground-hover: $white;
$default-foreground-disabled: lighten($grey-2, 3%);
$default-background-disabled: lighten($grey-5, 3%);

$confirm-background: $green-1;
$confirm-foreground: $white;
$confirm-background-hover: darken($green-1, 7%);
$confirm-foreground-hover: $white;
$confirm-foreground-disabled: rgba(255, 255, 255, 0.65);
$confirm-background-disabled: desaturate($green-1, 20%);
$confirm-background-light: lighten($confirm-background, 40%);
$confirm-font-color: darken($green-1, 15%);

$warning-background: $yellow-1;
$warning-foreground: $white;
$warning-background-hover: darken($yellow-1, 7%);
$warning-foreground-hover: $white;
$warning-foreground-disabled: rgba(255, 255, 255, 0.65);
$warning-background-disabled: desaturate($yellow-1, 20%);
$warning-background-light: lighten($warning-background, 40%);
$warning-font-color: darken($yellow-1, 15%);

$disabled-background: $grey-5;
$disabled-foreground: $white;
$disabled-background-hover: darken($grey-5, 7%);
$disabled-foreground-hover: $white;
$disabled-foreground-disabled: rgba(255, 255, 255, 0.65);
$disabled-background-disabled: desaturate($grey-5, 20%);

$table-toolbar-background: $white;
$table-toolbar-background-active: rgb(250, 224, 233);
$table-toolbar-foreground-active: rgb(220, 0, 78);

$textarea-background: $white;
$textarea-color: $black;
$code-mirror-keyword: $purple-2;
$code-mirror-highlight-background: rgba($purple-2, 20%);

//colour usage
$overlay: rgba(0, 0, 0, 0.75);
$icon-foreground: $font-light;
$border-foreground: #e2e5e7;
$border-hover: rgba(0, 0, 0, 0.2);
$border-focus: #1976d2;
$drawer-background: $white;
$field-background: $grey-2;
$form-background: $white;
$app-background: $white;
$app-background-inset: $grey-1;
$app-background-inset-2: #f7f8fa;
$app-background-inset-3: #f4f6f8;
$app-background-inset-hover: darken($grey-1, 3%);
$form-label: $grey-5;
$border-foreground-light: lighten(#e2e5e7, 3%);
$keyword-highlight-color: #e1ebf9;
$tinyice-border-color: #eaeaea;

$tooltip-background-color: $font-light;
$tooltip-text-color: $app-background;

$media-item-hover: darken($media-item-selected, 1%);
$media-item-selected: $app-background-inset-3;
$inbox-media-item-read: lighten($media-item-selected, 2%);

$quick-search-background: lighten($app-background-inset-3, 2%);
$quick-search-background-hover: darken($quick-search-background, 1%);

$twitter-embed-background: $white;
$twitter-embed-background-hover: #f7f9f9;
$twitter-font-color-blue: rgb(0, 111, 214);
$twitter-font-color-grey: rgb(83, 100, 113);
$twitter-icon-pink: rgb(249, 24, 128);
$twitter-icon-background-pink: rgb(249, 24, 128, 0.1);
$twitter-icon-blue: rgb(29, 155, 240);
$twitter-icon-background-blue: rgb(29, 155, 240, 0.1);
$twitter-icon-green: rgb(0, 186, 124);
$twitter-icon-background-green: rgb(0, 186, 124, 0.1);
