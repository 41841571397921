.popover,
.MuiPopover-paper {
	background: $app-background;
	color: $font-color;
	font-family: $font-family;
	font-size: $typography-body;
	font-weight: $weight-regular;
	max-width: $popovers-width;
	li {
		font-size: $typography-body;
	}
}
