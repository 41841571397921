.activities-table-select {
	display: flex;
	box-sizing: border-box;
	background-color: $app-background-inset;
	width: 100%;
	.select {
		&__control {
			background-color: $app-background;
		}
		&__value-container {
			line-height: 1rem;
		}
	}
}
