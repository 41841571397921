.tox-tinymce,
.tox-statusbar {
	border-color: $border-foreground;
}
.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
	border-right: 0px;
}
.tox-toolbar__primary {
	background: none;
	border-bottom: 1px solid $border-foreground;
}
.tox-tbtn {
	color: $font-color;
	svg {
		fill: $font-color;
	}
}
.tox-tbtn.tox-tbtn--disabled {
	svg {
		fill: $font-light !important;
	}
}

.tox .tox-toolbar-overlord,
.tox.tox-tinymce-aux .tox-toolbar__overflow {
	background-color: $white;
}
.tox.tox-tinymce-aux .tox-toolbar__overflow {
	border-color: $tinyice-border-color;
}
