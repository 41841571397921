.select-exclude-filter {
	height: 100%;
	display: flex;
	flex-direction: column;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 24px;

		svg {
			cursor: pointer;
		}
	}

	&__content {
		height: 200px;
		flex: 1 0 auto;
		padding: 0 16px 0 16px;
		border-top: 1px solid $border-foreground;

		@include breakpoint-up(lg) {
			flex: 2;
		}

		&__show-more {
			text-align: center;
			display: block;
			padding: 10px 0;
			cursor: pointer;
		}

		&__item {
			display: flex;
			align-items: center;
			padding-right: 16px;

			@include breakpoint-up(lg) {
				padding-right: unset;
			}

			&__label {
				display: flex;
				align-items: center;
				flex: 1;
				position: relative;

				&:hover {
					.select-exclude-filter__content__item__label__show-recent {
						max-width: 100%;
						opacity: 1;
						transition: max-width 1s ease-in-out, opacity 0.5s ease-in-out;
					}
				}

				&__show-recent {
					margin-left: 15px;
					cursor: pointer;
					position: absolute;
					bottom: 0;
					opacity: 0;
					overflow: hidden;
					transition: max-width 0.5s ease-in-out, opacity 1s ease-in-out;
					max-width: 0;
					animation: growInOut 1.5s;

					&__text {
						display: block;
						color: $primary-color;
						width: 90px;
					}
				}

				&__text {
					flex: 0 75%;
					p {
						position: relative;
					}
				}

				&__avatar {
					height: 18px;
					width: 18px;
					margin: 0 10px 0 5px;

					&.avatar--fallback {
						font-size: 10px;
					}
				}
			}
		}
	}

	&__excluded {
		padding: 0 0 0 24px;
		flex: 0 0 150px;
		border-top: 1px solid $border-foreground;

		&__chip {
			height: 28px;
			margin: 0 10px 10px 0;
			.MuiChip-deleteIcon {
				height: 16px;
			}

			&__avatar {
				height: 14px;
				width: 14px;
				padding: 6px;

				&.avatar--fallback {
					height: 10px;
					width: 10px;
					font-size: 8px;
				}
			}
		}

		p {
			margin: 16px 0;
		}

		@include breakpoint-up(lg) {
			flex: 1 0 auto;
		}
	}
}

@keyframes growInOut {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}
