.plugin-loading-overlay {
	position: relative;
	z-index: 10;
	&__overlay {
		padding-top: 50px;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 400px;
		display: flex;
		border-radius: 12px;
		background-color: #ffffffcc;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		> :not(:first-child) {
			margin-top: #{$spacing-unit * 2}px;
		}
	}
}
