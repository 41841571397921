.split-view {
	width: 100%;
	height: 100%;
	display: flex;

	&__left-pane {
		flex: 1 0 0%;
		height: 100%;
		overflow: hidden;
		min-width: 0;
	}
	&__right-pane {
		flex: 1 0 0%;
		height: 100%;
		overflow: hidden;
		min-width: 0;
	}

	&__resize_wrapper {
		position: relative;
		cursor: col-resize;
		align-self: stretch;
		display: flex;
		align-items: center;
		width: 1px;
		background: $border-foreground;

		&__resize-handler {
			background-color: $app-background-inset;
			border-radius: 4px;
			border: 2px solid $border-foreground;
			width: 40px;
			height: 10px;
			right: -22.5px;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			transition-duration: 0.22s;
			transform: rotate(90deg);
			z-index: 99;

			&__icon {
				background-color: $neutral;
				height: 2px;
				width: 20px;
				transition-duration: 0.22s;
			}
		}
	}
}
