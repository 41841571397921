.media-toolbar {
	&__tools {
		.icon-button--sm > svg {
			height: 16px;
			width: 16px;
		}
		&__add-to-queries {
			//the MdSavedSearch icon is weird and not sized properly. so we need to specifically size it up
			.icon-button {
				> svg {
					height: 20px;
					width: 20px;
				}
			}
		}
	}
}

.collapsed-actions-button.button {
	border: unset;
	border-radius: 25px;
	width: 100%;
	background: $app-background;
	padding: 6px #{$spacing-unit * 1.5}px #{$spacing-unit}px #{$spacing-unit * 2}px;

	&:hover {
		background: $app-background-inset;
	}
	&:active {
		background-color: rgba($primary-color, 30%);
		path {
			fill: rgba($primary-color, 60%);
		}
	}
	path {
		fill: $font-light;
	}
}
