@use 'sass:math';

$manage-subscriptions-header-height: 60px;
$manage-subscriptions-section-spacing: 30px;

.manage-subscriptions {
	height: 100%;
	width: 100%;

	&__loading {
		margin-left: #{math.div($spacing-unit, 2)}px;
		margin-right: #{math.div($spacing-unit, 2)}px;
	}

	&__header {
		display: flex;
		flex-direction: column;
		justify-content: center;

		border-width: 0px 0px 1px 0px;
		border-color: $border-foreground;
		border-style: solid;
		box-sizing: border-box;

		height: $manage-subscriptions-header-height;

		&__image-box {
			text-align: center;
			max-height: 73%;

			img {
				max-height: 100%;
			}
		}
	}

	&__alert-box-success {
		color: $confirm-foreground;
		background-color: $confirm-background;
		margin-bottom: $manage-subscriptions-section-spacing;

		> .MuiAlert-icon {
			color: $confirm-foreground;
		}
	}

	&__alert-box-error {
		color: $error-foreground;
		background-color: $error-background;
		margin-bottom: $manage-subscriptions-section-spacing;

		> .MuiAlert-icon {
			color: $error-foreground;
		}
	}

	&__main-content {
		margin: 70px 0 30px 0;
	}

	&__description {
		margin-bottom: $manage-subscriptions-section-spacing;
	}

	&__switch-label {
		min-width: 35%;
	}

	&__schedule-info {
		margin: 0 0 15px 0;
	}

	&__alerts {
		margin-top: #{$spacing-unit * 6}px;
	}

	&__subscription {
		padding: 0 10px;
		margin-top: #{$spacing-unit * 2}px;
	}

	&__loading-container {
		width: 39px;
		padding: 7px;
	}
}
