.linear-progress {
	width: 100%;
	height: 3px;
	position: absolute;
	left: 0;

	&--bottom {
		bottom: 1px;
	}

	&--top {
		top: 0;
	}
}
