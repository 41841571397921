.insert-marker {
	&__marker {
		display: none;
		position: relative;
		border-left: 1px dashed $icon-foreground;
		height: 20px;
		box-sizing: border-box;
		> svg {
			top: -15px;
			z-index: 500;
			left: -7px;
			position: absolute;
			color: $icon-foreground;
		}
	}
	&--active {
		.insert-marker {
			&__marker {
				display: block;
			}
		}
	}
	width: 1px;
	box-sizing: border-box;
}
