$dashboard-header-toolbar-height: 70px;

.dashboard {
	@include layoutWithHeaderToolbar;

	&__header {
		&__toolbar {
			&__left {
				.click-to-edit-field {
					.MuiInputBase-formControl {
						font-size: $typography-h3;
						font-weight: $weight-bold;
					}
				}
			}
		}
	}

	&__scroll-view {
		background-color: $app-background-inset;
	}

	&__error {
		padding: 24px 40px;
	}

	&__header__toolbar__right__tools {
		justify-content: flex-end;
		&__tool__lock {
			cursor: pointer;
		}
	}

	&__content {
		padding-bottom: 100px;
		.filters-strip {
			margin-top: 0;
		}

		&__linear-progress {
			height: 3px;
			padding-bottom: 13px;
		}
		.filters-container {
			z-index: 1;
		}
		.react-draggable-dragging {
			z-index: 1;
			margin-top: -60px;
		}
		.react-grid-placeholder {
			background-color: $app-background !important;
			border: 1px dashed $border-foreground !important;
			z-index: 0;
		}
		.tooltip {
			font-size: 12px;
			line-height: '18px';
			border-radius: 3px;
			background-color: $app-background-inset !important;
			border-color: $border-foreground !important;
			color: $font-color !important;
			padding: 12px 16px;
		}

		// TREEMAP
		.treemap #nivo {
			background-color: $app-background !important;
		}
		.treemap span {
			text-align: center;
			font-size: 9px;
			color: $font-color;
		}
		.grid-item {
			border: 0px;
		}
	}

	//grid related styling
	.react-grid-layout {
		position: relative;
		transition: height 200ms ease;
	}
	.react-grid-item {
		z-index: 0;
		transition: all 200ms ease;
		transition-property: left, top;
	}
	.react-grid-item.cssTransforms {
		transition-property: transform;
	}
	.react-grid-item.resizing {
		z-index: 1;
		will-change: width, height;
	}
	.react-grid-item.react-draggable-dragging {
		transition: none;
		z-index: 3;
		will-change: transform;
	}
	.react-grid-item.dropping {
		visibility: hidden;
	}
	.react-grid-item.react-grid-placeholder {
		background: $primary-color !important;
		opacity: 0.2;
		transition-duration: 100ms;
		z-index: 2;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
	.react-grid-item > .react-resizable-handle {
		position: absolute;
		width: 20px;
		height: 20px;
		bottom: 0;
		right: 0;
		cursor: se-resize;
	}
	.react-grid-item > .react-resizable-handle::after {
		content: '';
		position: absolute;
		right: 3px;
		bottom: 3px;
		width: 5px;
		height: 5px;
		border-right: 2px solid $font-light;
		border-bottom: 2px solid $font-light;
	}
	.react-resizable-hide > .react-resizable-handle {
		display: none;
	}
	.react-grid-item {
		box-sizing: border-box;
	}
	.react-grid-item.resizing {
		opacity: 0.9;
	}
	.react-grid-item .add {
		cursor: pointer;
	}
	.react-grid-dragHandleExample {
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
	}
}

/*
when you hit 'export', we add this class to the dashboard. it allows charts to be resized to proper landscape A4 sizes, before export.
It also hides the charts so the user can't see this happening. The reason is because amcharts has some live javascript that allows charts
to be resized. If you clone the html, you lose this resizing functionality. If you need to change the export size of each amchart, it
needs to be done here, and then also in html export.
*/
.dashboard--export {
	.dashboard-chart {
		height: 160mm; // minus the dashboard footer
		width: 290mm;
		.chart-header {
			display: none;
		}
		.chart-container--am-chart,
		.table-chart,
		.distribution-chart,
		.card-chart,
		.chart-container--am-chart {
			padding: 100px 50px;
		}
	}
	.export-loading-indicator {
		display: block;

		&__content {
			height: calc(100vh - $dashboard-header-toolbar-height);
		}
	}
	.dashboard {
		&__content {
			//important: dont remove this, otherwise the charts will not render properly
			visibility: hidden;
		}
	}
}

.grid-item {
	border-color: $border-foreground;
	border-style: solid;
	border-width: 0 1px 1px 0;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	&.react-draggable-dragging {
		cursor: grabbing;
	}
}

.share-dashboard {
	margin-top: #{$spacing-unit * 2}px;
}

.dashboard-builder {
	height: 100%;
}

.dashboard-chart-viewer-sheet {
	width: 100%;
	.media-item-viewer {
		padding: #{$spacing-unit * 4}px;
	}
}
