.full-data-grid-loader {
	display: flex;
	align-items: center;
	justify-content: stretch;
	padding: #{$spacing-unit * 2}px;
	box-sizing: border-box;
	width: 100%;
	.MuiLinearProgress-root {
		width: 100%;
	}
}
