.alert {
	&__title {
		font-size: $typography-h1;
		font-weight: $weight-bold;
	}
	&__content {
		padding: #{$spacing-unit}px #{$spacing-unit * 3}px;
		@include breakpoint-down('xs') {
			min-width: unset;
		}
	}
}
