.filter-widget {
	font-family: 'Roboto';
	display: inline-flex;
	flex-wrap: nowrap;

	min-width: 0;
	padding: #{$spacing-unit}px #{$spacing-unit * 2}px;
	border-radius: $input-border-radius;
	border: 1px solid $border-foreground;
	background-color: $app-background-inset;
	align-items: center;

	.quick-search-filter {
		min-width: 0;

		&__number-filter {
			input {
				padding-left: 0 !important;
			}
		}
	}

	&--error {
		border-color: red;
		&:hover {
			border-color: red;
		}
	}

	&__name {
		text-transform: uppercase;
		font-size: 11px;
		color: $font-color;
		margin-right: #{$spacing-unit * 1.8}px;
		font-weight: $weight-bold;
	}
	&__value {
		color: $font-color;
		font-size: 13px;
	}

	&-hints {
		background: $textarea-background;
		z-index: 1;
		> li {
			color: $font-color;
		}
	}

	&--edit {
		padding: 0;
		border: 0;
		.quick-search-filter {
			border: 0;
			z-index: 9999;

			.adornment-label {
				&__label {
					font-size: 11px;
				}
				&__value {
					font-size: 13px;
				}
			}

			.select-container {
				.select {
					font-family: 'Roboto';
					&__control {
						padding: 0px 14px;
						font-size: $typography-small;
					}

					&__dropdown-indicator {
						svg {
							fill: $font-color;
							width: 16px;
							height: 16px;
						}
					}
				}
			}
		}
	}
}
