.animated-border {
	$animated-border-width: var(--border-width, 2px);
	$animated-border-radius: var(--border-radius, $border-radius);

	position: relative;
	box-sizing: border-box;
	border-radius: $animated-border-radius;

	&--animate {
		&:before,
		&:after {
			content: '';
			position: absolute;
			border-radius: $animated-border-radius;
			left: calc(-1 * $animated-border-width);
			top: calc(-1 * $animated-border-width);
			background: linear-gradient(135deg, #1786d1, #ffc119, #ff8745, #8fc94b, #3bad7d, #14b1c3, #7d88e3, #6d64db, #2d64bd, #1ca1f1);
			background-size: 800%;
			width: calc(100% + ($animated-border-width * 2));
			height: calc(100% + ($animated-border-width * 2));
			z-index: -1;
			animation: steam 15s linear infinite;
		}
	}
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}

	50% {
		background-position: 400% 0;
	}

	100% {
		background-position: 0 0;
	}
}

.animated-border:after {
	filter: blur(4px);
}
