.tree-select {
	.popper-anchor {
		position: relative;
		width: 100%;

		&__popper {
			position: absolute;
			top: 6px;
			left: 0;
			z-index: 1201;
			width: 100%;
		}
	}

	&__popover {
		&__field-container {
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			height: $popover-select-height;
			width: $popover-select-width;

			&:hover {
				border-color: $border-hover;
			}

			&__close {
				justify-content: right;
				display: flex;
				padding: 4px;
				border-style: solid;
				border-color: $border-foreground;
				border-width: 0 0 1px 0;
			}
			&__list {
				padding: 8px !important;
				&__item {
					&--nested {
						> :first-child {
							margin-left: 16px;
						}
					}
				}
				overflow-x: hidden;
				overflow-y: auto;
				box-sizing: border-box;
			}
		}
	}
}
