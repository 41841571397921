.validation-indicator {
	position: absolute;
	right: #{$spacing-unit}px;
	bottom: #{$spacing-unit}px;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		height: 20px;
		width: 20px;
	}
	> div {
		height: 20px;
		width: 20px;
	}
	&__valid {
		color: $confirm-background;
	}
	&__checking {
		svg {
			height: unset;
			width: unset;
		}
	}
	&__invalid {
		color: $error-background;
	}
	z-index: 999;
}
