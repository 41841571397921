.callout__container {
	padding: #{$spacing-unit * 2}px;
	border: 2px dashed $border-foreground;
	border-radius: $border-radius;
	background-color: transparent;
	color: $font-color;
	.MuiTypography-paragraph {
		margin-bottom: unset;
	}
}
