@import '../../app.scss';

.theme--dark {
	@import '~@truescope-web/react/lib/scss/theme.scss';
	@import 'colours.scss';

	@import '~@truescope-web/react/lib/scss/typography.scss';
	@import '../../typography.scss';

	@import '~@truescope-web/react/lib/scss/styles.scss';

	@import '../../partials.scss';

	.app {
		background: $app-background;
		color: $font-color;
		font-family: $font-family;
		font-size: $typography-body;
		font-weight: $weight-regular;
	}

	.tags__tag {
		&--blue {
			background: $primary-color;
			color: $white;
			border-color: #42a2d6;
		}
	}

	button,
	.drawer__master__list__item {
		&:hover {
			background-color: lighten($app-background-inset, 0%);
		}
	}

	.MuiClockPicker-root {
		span {
			color: $font-color;
		}
	}
}
