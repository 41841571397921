@use 'sass:math';

.boolean-filter {
	display: flex;
	min-width: 0;
	justify-content: stretch;
	align-items: center;
	flex-grow: 1;
	background-color: $app-background;
	border: 1px solid $border-foreground;
	border-radius: $border-radius;
	height: 37px;
	padding-left: #{$spacing-unit}px;
	&__label {
		border-radius: $border-radius;
		padding: #{math.div($spacing-unit, 2)}px;
		background-color: $warning-background;
	}
	cursor: pointer;
}
