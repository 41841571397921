@use 'sass:math';

.channel-info-card {
	width: 80vw;
	padding: #{$spacing-unit * 2.5}px;

	@media only screen and (min-width: $screen-width-xs-max) {
		width: 300px;
	}

	&-hover {
		display: inline-block;
	}

	&__header {
		display: flex;
		align-items: center;

		&__source {
			margin-right: #{$spacing-unit * 2}px;
		}

		&__info {
			flex: 1;
			&__name {
				cursor: default;
			}
			&__link {
				cursor: pointer;
				text-overflow: ellipsis;
				display: block;
				width: 200px;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	&__social {
		display: flex;
		flex-wrap: wrap;
		margin: #{$spacing-unit * 2}px 0;
		&__icon {
			img {
				margin: 0 #{$spacing-unit * 1.25}px 0 0;
				max-width: 25px;
			}
		}
	}

	&__description {
		margin-top: #{$spacing-unit * 2}px;
	}

	.channel-card-info-averages {
		display: flex;
		align-items: center;
		margin-top: #{$spacing-unit * 2}px;

		&:not(:first-child) {
			margin-top: #{$spacing-unit}px;
		}

		&__icon {
			font-size: 22px;
			color: $font-light;
			line-height: 0;
			align-self: baseline;
			margin-right: #{$spacing-unit}px;
		}
		&__content {
			display: flex;

			&__title {
				text-transform: lowercase;
			}
			p {
				&:first-child {
					margin-right: #{math.div($spacing-unit, 2)}px;
				}
			}
		}
	}

	&__empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;

		:first-child {
			margin-bottom: #{$spacing-unit * 2}px;
		}
	}
}
