$header-toolbar-height: 70px;

@mixin layoutWithHeaderToolbar {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	&__header {
		height: $header-toolbar-height;
		display: flex;
		position: relative;
		&__toolbar {
			border-bottom: 1px solid $border-foreground;
			background-color: $app-background;
			width: 100%;
			padding: #{$spacing-unit * 1.5}px #{$spacing-unit * 2}px #{$spacing-unit * 1.5}px #{$spacing-unit * 4}px;
			box-sizing: border-box;
			z-index: 1;
			&__left {
				display: flex;
				align-items: center;
				&__tools {
					display: flex;
					align-items: center;
				}
				.click-to-edit-field {
					max-width: 100%;
				}
			}
			&__right {
				&__tools {
					display: flex;
					margin: auto;
					margin-right: unset;
					&__tool {
						display: flex;
						align-items: center;
						@include breakpoint-up('md') {
							white-space: nowrap;
							&:not(:last-child) {
								height: $rectangular-button-height;
								padding-left: #{$spacing-unit * 2}px;
								padding-right: #{$spacing-unit * 2}px;
								margin: auto #{$spacing-unit}px;
							}
						}
					}
				}
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
	}
}
