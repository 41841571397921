.CodeMirror {
	background-color: $textarea-background;
	border-color: $textarea-background;
	color: $textarea-color;
	z-index: 1;
	font-family: 'Roboto';
	height: auto;
	max-height: 120px;
	line-height: 33px; //if change this, also update where codeMirrorRef.current.doc.height is used (e.g ResizablePanel in Editor.js)

	&-focused {
		.CodeMirror-selected {
			background: $code-mirror-highlight-background !important;
		}
	}

	&-selected {
		background: rgba($grey-5, 20%) !important;
	}

	&-cursor {
		border-color: $font-light;
	}

	&-gutters {
		background-color: $textarea-background;
		border-color: $textarea-background;
	}

	&-scroll {
		max-width: 99%;
		height: 100%;

		&::-webkit-scrollbar {
			display: none; /* Hide scrollbar for Chrome, Safari and Opera */
		}
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	&-lines {
		padding: 3px 10px;
	}

	//we need pre to have greater precendence
	pre.CodeMirror-placeholder {
		color: $font-light;
		font-size: $typography-body;
		font-weight: $weight-regular;
	}

	&-hints {
		background-color: $app-background;
		font-family: 'Roboto';
		.CodeMirror-hint {
			&-active,
			&:hover {
				background: $app-background-inset;
			}

			.hint {
				display: flex;
				min-width: 100px;
				padding: 2px 2px;
				color: $font-color;
				> :not(:first-child) {
					margin-left: 4px;
				}
				&__icon {
					color: $font-light;
				}
				&__type {
					&__operator {
						font-weight: bold;
					}
				}
			}
		}
	}

	.cm-string,
	.cm-number,
	.cm-variable {
		color: $font-color;
	}

	.cm-string,
	.cm-number,
	.cm-variable,
	.cm-keyword {
		display: inline-block;
	}

	.cm-keyword,
	.cm-keyword-negative {
		color: $primary-color;
	}

	.CodeMirror-widget {
		> span {
			display: inline-block;
		}
	}
}

/* Scroller styles */
.CodeMirror-overlayscroll-vertical div {
	transition: 0.3s ease;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 3px;
}
