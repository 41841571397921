.create-query-prompt {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	&__content {
		padding: #{$spacing-unit * 4}px;
		background-color: $app-background;
		border-radius: $border-radius;
		max-width: 350px;
	}
}
