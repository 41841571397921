@use 'sass:math';

.auto-suggest {
	display: flex;
	position: fixed;
	background-color: $app-background;
	border: 1px solid $border-foreground;
	z-index: 1320;
	box-shadow: $container-box-shadow;
	margin: 'auto';
	//Set fixed width for popper
	left: 0;
	top: 125;
	right: auto;
	bottom: auto;

	&__filters {
		width: 80vw;
		@include breakpoint-up('sm') {
			width: 520px;
		}

		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		align-content: stretch;
		flex-direction: column;
		flex-wrap: wrap;
		box-sizing: border-box;
		max-height: 485px;
		flex-grow: 1;
		margin: #{$spacing-unit * 3}px 0;

		@include breakpoint-up('sm') {
			margin: #{$spacing-unit * 3}px;
		}

		&__overlay {
			top: 0;
			left: 0;
			position: fixed;
			width: 100vw;
			height: 100vh;
			background-color: #000000;
			opacity: 0.5;
		}

		.MuiListSubheader-root {
			margin: 0 0 #{$spacing-unit}px #{$spacing-unit}px;
		}

		&__list {
			padding-bottom: 16px;
			margin: 0 #{$spacing-unit * 3}px;

			@include breakpoint-up('sm') {
				margin: unset;
			}

			.MuiListSubheader-sticky {
				background-color: $app-background;
			}

			&__filter {
				padding: 1px #{$spacing-unit}px;
				&__icon {
					min-width: 20px;
					color: $font-light;
				}
				&__text {
					width: 50px;
				}
			}
			@include breakpoint-up('sm') {
				width: 50%;
			}

			@media (min-width: $screen-width-md-min) and (max-height: 730px) {
				width: 25%;
			}
		}
	}

	&__suggestions {
		width: 380px;
		padding: #{$spacing-unit}px 0px #{math.div($spacing-unit, 2)}px;
		box-sizing: border-box;
		&__list {
			&__list-item {
				&__icon {
					min-width: 24px;
					color: $font-light;
				}
				&__text {
					margin: 0;
					&__filter-label {
						font-size: $typography-small;
					}
					&__match-label {
						font-size: $typography-small;
						margin-left: #{math.div($spacing-unit, 2)}px;
						font-weight: bold;
					}
				}
				&--loading {
					padding-top: 0px;
					padding-bottom: 0px;
					> :first-child {
						margin-right: 8px;
					}
				}
			}
		}
	}
}
