.media-items-viewer-modal {
	&__container {
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;

		&__content {
			display: flex;
			flex-direction: column;
			background: $app-background;
			border: $border-foreground;
			border-radius: 3px;
			width: 85%;
			height: 90%;

			&__header {
				border-bottom: 1px solid $border-foreground;
				padding: #{$spacing-unit}px #{$spacing-unit}px #{$spacing-unit}px #{$spacing-unit * 2}px;

				.checkable-selection-toolbar {
					border-bottom: none;
					padding: 0;

					.filter-summary {
						width: 0;
						flex: 1;
					}
				}

				&__close-button.button {
					margin-right: #{$spacing-unit * 2}px;

					&:hover {
						background: $grey-5;
						color: $white;
					}
				}
			}

			&__body {
				height: 100%;
			}
		}
	}
}
