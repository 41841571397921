@mixin AssistantGlow {
	font-family: system, 'Roboto', san serif;
	font-size: 60px;
	background: linear-gradient(90deg, #5daa80, #3271e3, #123697, #5daa80, #3271e3, #123697, #5daa80, #3271e3, #123697);
	background-size: 150%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	animation: flow 1.5s ease-out;
	animation-iteration-count: 1;
	animation-fill-mode: both;
}

.assistant {
	height: calc(100% - $top-nav-height);
	display: flex;
	flex-direction: column;

	@media only screen and (min-width: $screen-width-md-min) {
		height: 100%;
	}

	.MuiInputBase-root {
		font-size: $typography-body;
	}

	&__link {
		color: $primary-color;
		white-space: nowrap;
		cursor: pointer;
		font-size: $typography-body;
	}

	&__summarize {
		&__toolbar-button {
			&__tooltip {
				&__body {
					display: flex;
					align-items: center;

					svg {
						margin-left: 5px;
					}
				}
			}
		}
	}

	&__header {
		border-bottom: 1px solid $border-foreground;
		padding: #{$spacing-unit * 1.5}px #{$spacing-unit * 3}px;
		@include breakpoint-up('md') {
			padding: #{$spacing-unit * 2}px #{$spacing-unit * 3}px;
		}

		&__title {
			white-space: nowrap;
		}

		.inline--horizontal--left {
			> :not(:first-child) {
				margin-left: #{$spacing-unit * 1}px;

				&.MuiChip-root {
					font-weight: bold;
				}
			}
		}
	}

	&__body {
		display: flex;
		height: 100%;
		background-color: $app-background-inset;
	}

	&__sidebar {
		padding: #{$spacing-unit}px 0;
		overflow: hidden;
		display: flex;
		flex-flow: column;

		&--expanded {
			flex: 0 100%;
			background-color: $app-background;
			border-right: 1px solid $border-foreground;

			@media only screen and (min-width: $screen-width-xs-max) {
				flex: 0 400px;
			}

			.assistant__sidebar__list {
				flex: 1;
			}
		}

		&__toggle {
			padding-left: #{$spacing-unit}px;

			svg {
				color: $font-light;
				border-radius: 100px;
				padding: #{$spacing-unit}px;
				line-height: 0;
				background-color: $app-background;
				cursor: pointer;
				transition: 0.3s color ease-in-out, 0.6s;
				height: 22px;
				width: 22px;

				&:hover {
					color: $primary-color;
				}
			}
		}

		&__list {
			flex: 0;
			overflow: hidden;

			&__conversation {
				border: 1px solid $border-foreground;
				border-left: 3px solid $app-background;
				padding: #{$spacing-unit * 2}px;
				display: flex;
				flex-direction: column;
				cursor: pointer;

				&--active {
					border-left: 3px solid $primary-color;
				}

				&__bottom-row {
					display: flex;
				}
			}
		}
	}

	&__content {
		min-height: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;

		&--empty {
			display: flex;
			flex-direction: column;
		}

		&__messages {
			min-height: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			margin-right: 10px;

			&__follow-up-questions {
				font-size: $typography-extra-large;
				padding: #{$spacing-unit}px;

				&__title {
					display: flex;
					align-items: center;
					margin-bottom: #{$spacing-unit * 2}px;

					p {
						font-size: $typography-extra-large;
					}

					svg {
						margin-right: #{$spacing-unit}px;
					}
				}

				&__question {
					margin-bottom: #{$spacing-unit * 2.5}px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: space-between;
					transition: color 0.3s ease-in-out;

					&:hover {
						color: $primary-color;
					}

					&__icon {
						font-size: 18px;
					}

					&--loading {
						cursor: default;

						&:hover {
							color: inherit;
						}

						svg {
							visibility: none;
						}
					}
				}
			}

			&__message {
				margin: #{$spacing-unit * 2}px #{$spacing-unit}px;
				display: flex;
				position: relative;
				z-index: 0;

				&--no-bottom-margin {
					margin-bottom: 0;
				}

				&__progress-wrapper {
					display: flex;
					align-items: center;

					.typography {
						margin-top: 0 !important;
					}

					.MuiCircularProgress-root {
						margin-right: #{$spacing-unit}px;
					}

					&__icon {
						margin-right: #{$spacing-unit}px;

						svg {
							background-color: $grey-4;
							padding: 5px;
							border-radius: 50px;
							color: $white;
							width: 15px;
							height: 15px;
						}
					}
				}

				&__container {
					background-color: $app-background;
					border: 1px solid $border-foreground;
					padding: #{$spacing-unit}px;
					border-radius: 8px;

					&.padding {
						padding: #{$spacing-unit * 3}px;
					}

					&.animated-border {
						&:before,
						&:after {
							border-radius: 8px;
						}
					}
				}

				&__progress {
					margin: #{$spacing-unit * 2}px #{$spacing-unit}px;
					display: flex;
					position: relative;
					z-index: 0;
					&__container {
						padding: 12px 17px;
						border-radius: 50px;
						background-color: $app-background;
						border: 1px solid $border-foreground;
					}
				}

				&__images {
					margin-top: #{$spacing-unit * 2}px;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-template-rows: repeat(3, 1fr);

					@media only screen and (min-width: $screen-width-sm-min) {
						grid-template-columns: repeat(3, 1fr);
						grid-template-rows: repeat(2, 1fr);
					}

					@media only screen and (min-width: $screen-width-md-min) {
						grid-template-columns: repeat(6, 1fr);
						grid-template-rows: repeat(1, 1fr);
					}

					div {
						flex: 1 0 50%;
						display: block;
						margin-left: 10px;
						margin-bottom: 10px;
						max-height: 120px;
						height: 120px;
						cursor: pointer;

						&:nth-child(2n + 1) {
							margin-left: 0px;
						}

						@media only screen and (min-width: $screen-width-sm-min) {
							&:nth-child(4n) {
								margin-left: 0px;
							}
							&:nth-child(2n + 1) {
								margin-left: 10px;
							}
						}

						@media only screen and (min-width: $screen-width-md-min) {
							margin-bottom: 0px;

							&:nth-child(2n + 1),
							&:nth-child(4n) {
								margin-left: 10px;
							}
						}

						&:first-child {
							margin-left: 0;
						}
					}

					img,
					.MuiSkeleton-root {
						height: 100%;
						object-fit: cover;
						width: 100%;
						border-radius: 20px;
					}
				}

				&__answer {
					.typography,
					li {
						font-size: $typography-extra-large;
					}

					li {
						line-height: 1.6rem;
					}

					.typography {
						margin-top: #{$spacing-unit * 2}px;

						&:first-child {
							margin-top: 0;
						}
					}

					&__footnotes {
						.footnote {
							white-space: pre-line;
							font-size: $typography-tiny;
							font-weight: bold;
							background-color: $font-light;
							color: $tooltip-text-color;
							border-radius: 115px;
							padding: 1px 5px;
							cursor: pointer;
						}
					}

					&__bottom-row {
						&__items {
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-top: 10px;

							&__left {
								.link {
									margin: 20px 0;
								}

								&__expand {
									display: flex;
									align-items: center;
								}
							}

							&__right {
								display: flex;

								svg {
									cursor: pointer;
									height: 16px;
									width: 16px;
									color: $font-color;
								}

								.copy {
									margin-left: 10px;
								}
							}

							.link {
								margin: 10px 0;
								display: flex;
								align-items: center;
								color: #0b5cce;
								cursor: pointer;

								svg {
									width: 18px;
									cursor: pointer;
								}
							}
						}
						.media-item-list-container {
							border-width: 1px 1px 1px 1px;
							border-color: $border-foreground;
							border-style: solid;
							border-radius: 5px;
							margin: 0 0 30px;
							&--closed {
								display: none;
							}
							.media-item-list {
								border-width: 0;
								min-height: 400px;
							}
						}
					}
				}

				&__question {
					margin-top: 16px;
				}
			}
		}
	}

	&__search-input-wrapper {
		padding: 10px 0 20px 0;
		z-index: 1;
		box-shadow: 1px -19px 22px -11px rgba($app-background-inset, 1);

		&__disclaimer {
			text-align: center;
			margin-top: 15px;
			margin-left: 2px;
		}

		&__input {
			display: flex;
			background-color: $app-background;
			border: 1px solid $border-foreground;
			border-radius: 10px;
			padding: 10px 15px;
			align-items: center;

			&__base,
			.MuiInputBase-root {
				width: 100%;
			}

			&__icon {
				cursor: pointer;
				background-color: $primary-color;
				border-radius: 100px;
				padding: 8px;
				line-height: 0;
				color: $white;
				transition: 0.6s background-color ease-in-out;
				align-self: flex-end;

				&--disabled {
					cursor: unset;
					background-color: $grey-4;
				}
			}
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		min-height: 100%;
		flex: 1;
		padding-bottom: 10px;
	}

	&__homepage {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		flex: 1;

		&__header {
			@include AssistantGlow();
		}

		&__subheading {
			font-size: 30px;
			font-weight: normal;
		}

		&__footer {
			justify-content: center;
			display: flex;

			&__text {
				font-size: $typography-extra-large;
				max-width: 500px;
				margin-top: 24px;
				text-align: center;
			}
		}

		@keyframes flow {
			0% {
				background-position: 100% 0;
			}
			100% {
				background-position: 0 0;
			}
		}

		.carousel {
			margin-top: 50px;
			margin-left: -50px;
			margin-right: -50px;

			&__arrow {
				font-size: 30px;
				color: $grey-3;

				&:hover {
					color: inherit;
				}
			}
		}

		&__carousel {
			&__item {
				margin: 15px;
				padding: 20px;
				border-radius: 10px;
				background-color: $app-background;
				height: 100%;
				cursor: pointer;
				border: 1px solid transparent;

				&:hover {
					border: 1px solid $border-foreground;
				}

				&__container {
					display: flex;
					flex-direction: column;
					height: 100%;

					&__title {
						flex: 1 0 auto;
						.typography--h3 {
							font-size: 17px;
							font-weight: normal;
							line-height: 1.25rem;
							word-break: break-word;
						}
					}

					&__icon {
						align-self: end;
						background-color: $app-background-inset;
						color: $grey-3;
						padding: 8px;
						border-radius: 100px;
						color: #8aa5b7;
						font-size: 1.2em;
						line-height: 0;
					}

					&__skeleton {
						margin-bottom: 10px;
						&__spacer {
							background-color: $app-background;
							animation: none;
						}
					}
				}
			}
		}
	}

	&__report {
		.modal__container__content__body__content {
			padding-left: #{$spacing-unit * 4}px;
			padding-right: #{$spacing-unit * 4}px;
		}

		.select {
			@include selectStyling;
			.select__placeholder {
				font-family: $font-family;
				font-size: $typography-body;
			}
			&__menu {
				.select__menu-list {
					font-family: $font-family;
					font-size: $typography-body;
				}
			}
		}
	}

	&__no-access {
		background-color: $app-background-inset;
		background-size: 90%;
		background-position-x: 450px;
		background-position-y: center;
		background-repeat: no-repeat;
		min-height: 100%;

		&__container {
			padding-top: 60px;
			padding-bottom: 60px;

			&__leadin {
				&__text {
					flex-basis: 80%;
					margin-bottom: 20px;

					&__title {
						display: flex;
						flex-direction: column;
						line-height: normal;
						margin-bottom: 20px;
					}

					&__subtitle {
						display: flex;
						flex-direction: column;
						margin-bottom: 20px;
						font-size: $typography-h3;
					}

					&__body {
						display: flex;
						flex-direction: column;
					}

					&__glow {
						@include AssistantGlow();
						font-size: $typography-h1;
						background-size: 350%;
					}
				}

				@include breakpoint-up('md') {
					display: flex;

					&__text {
						margin-bottom: 0;
					}
				}
			}

			&__cards {
				display: grid;
				grid-template-columns: repeat(1, minmax(0, 1fr));
				margin-top: 40px;
				column-gap: 20px;
				row-gap: 20px;

				@include breakpoint-up('sm') {
					grid-template-columns: repeat(2, minmax(0, 1fr));
				}
				@include breakpoint-up('md') {
					grid-template-columns: repeat(3, minmax(0, 1fr));
				}

				&__card {
					background: $app-background;
					padding: 20px 15px;
					border-radius: 10px;
					display: flex;
					flex-direction: column;
					position: relative;

					&__title {
						margin-bottom: 20px;
						.typography--h5 {
							font-size: $typography-large;
						}
					}

					&__body {
						max-width: 80%;
					}

					&__icon {
						align-self: flex-end;
						padding: 6px;
						line-height: 0;
						border-radius: 100px;
						margin-top: -12px;
						font-size: 13px;
						color: #fff;
						position: absolute;
						bottom: 10px;
						right: 15px;
					}
				}
			}

			&__coming-soon {
				background: $app-background;
				padding: 20px;
				border-radius: 10px;
				text-align: center;
				margin: 20px 0;
			}

			&__request-access {
				margin-top: 60px;

				.typography--h2 {
					margin-bottom: 20px;
				}

				&__body {
					display: flex;
					flex-direction: column;
					margin-bottom: 20px;
				}
			}

			&__request-access-button {
				display: flex;
				flex-direction: column;
				width: 200px;

				&__body {
					display: flex;
					flex-direction: column;
					margin-top: 20px;
				}

				button {
					position: relative;
					padding-left: 30px;
					padding-right: 30px;

					.MuiCircularProgress-root {
						position: absolute;
						right: 20px;
					}
				}
			}
		}
	}
}
