.join-form {
	&__left-panel {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: $white;

		&__header {
			display: flex;
			justify-content: center;
			margin-bottom: 16px;

			.logo-img {
				width: 250px;
			}
		}

		&__content {
			&__header {
				&__returning-user {
					font-size: 1.05em;
					margin-top: 30px;
				}
				&__new-user {
					line-height: 1.8;
					margin-bottom: 30px;
					font-size: 1.15em;
				}
			}

			&__text-field {
				position: relative;

				&--disabled {
					position: absolute;
					right: 12px;
					top: 8px;
					height: 18px;
					width: 18px;
					color: $grey-5;
				}
			}

			&__label {
				height: 100%;
				display: flex;
				justify-content: center;
			}

			.textfield-container__mui-control {
				.MuiInputBase-root {
					background-color: $app-background;
				}

				.MuiInputBase-input.Mui-disabled {
					background-color: $app-background-inset;
				}
			}

			.select__control {
				background-color: $app-background;
			}
		}

		&__footer {
			margin: 15px 0;
			width: 100%;
			padding-top: #{$spacing-unit * 3}px;

			&__join-button {
				padding: 20px 0;
				font-size: 1em;
				border-radius: 0;
				width: 100%;
			}
		}
	}
}

@include breakpoint-up(md) {
	.join-form__left-panel__header {
		padding: 100px 0 30px 0;
	}
}
