.table-container {
	th {
		height: 50px;
		font-size: $typography-body;
		color: $font-light;
		font-weight: $weight-regular;
		border-color: $border-foreground;
	}
	td {
		font-size: $typography-body;
		border-color: $border-foreground-light;
		color: $font-color;
	}

	&--bordered {
		border: 1px solid $border-foreground;
	}
}

.tab-table {
	border: 1px solid $border-foreground;
	background-color: $app-background-inset;
	height: 100%;

	> .form-panel {
		background-color: $app-background;
		margin-top: 0px;
		height: 100%;
	}
}

.table-dense {
	.sentiment {
		padding: 2px 8px 2px 0px;
	}
	td {
		border-bottom-color: transparent;
		padding-left: 0px;
		padding: 4px 8px 4px 0px;
	}
	.sentiment__color__single {
		width: 20px;
		height: 20px;
	}
	.sentiment__color__right,
	.sentiment__color__left {
		width: 10px;
		height: 20px;
	}
	.sentiment__text .typography--subtitle {
		color: $font-light;
	}
}
