.ai-icon {
	cursor: pointer;
	.text {
		fill: $app-background;
	}

	&--dark {
		.rectangle {
			fill: $font-color;
		}
	}
}

.tox-tinymce {
	.ai-icon {
		.text {
			//in dark mode, inside the text editor, the icon should remain white
			fill: #ffffff;
		}
	}
}
