.media-item-viewer {
	.media-item-toolbar {
		.inline {
			justify-content: space-between;
		}
		&__tools {
			display: block;
		}
	}

	&__image {
		width: 100%;
		border-radius: 10px;
	}

	&__item {
		&__date {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.channel-info-card-hover {
			display: inline-block;
		}
	}

	.heading-colour {
		color: $font-color !important;
		&:hover {
			opacity: 0.9;
			text-decoration: none;
		}
		&__highlight {
			background-color: $keyword-highlight-color !important;
			color: $font-color;
		}
	}
	.typography--h2 a:hover {
		text-decoration: none;
		color: $primary-color !important;
	}
	.source-logo {
		.typography {
			font-size: $typography-large;
		}
		.avatar,
		.avatar > img {
			width: 45px;
			height: 45px;
		}
	}
	.typography--header {
		font-size: $typography-large;
		background-color: $app-background-inset;
		color: $font-color;
		font-weight: $weight-bold;
		padding: 9px 10px;
		border-radius: $border-radius;
		margin-bottom: #{$spacing-unit}px;
	}
	.typography--subtitle {
		font-size: $typography-large;
	}
	.typography--body {
		color: $font-color;
		font-size: $typography-large;
	}

	.article-panel {
		&__control {
			display: none;
		}
	}

	.entity-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: #{$spacing-unit * -3}px;
		&__entity {
			margin-left: #{$spacing-unit * 3}px;
			display: flex;
			> *:not(:first-child) {
				margin-left: #{$spacing-unit * 1}px;
			}
		}
	}

	.tweet-container {
		display: flex;
		justify-content: center;
	}

	&__syndication-avatar {
		height: 30px;
		width: 30px;
	}

	&__show-all-syndications-toggle {
		display: block;
		margin-top: #{$spacing-unit * 2}px;
		margin-left: 10px;
	}
	.table-dense td {
		padding-left: 10px;
		padding-right: 10px;
	}

	.syndications-total-text {
		background-color: $app-background-inset;
		color: $font-color;
		font-weight: $weight-bold;
		padding: 9px 10px;
		border-radius: $border-radius;
		margin-top: #{$spacing-unit}px;
	}

	#query-keywords {
		.title-bar {
			display: flex;
			background: $app-background-inset;
			padding: 10px;
			align-items: center;
			.title {
				flex: 1 0;
				p {
					line-height: unset;
				}
			}
			.menu {
				cursor: pointer;
				line-height: 0;
				position: relative;
				z-index: 0;

				.menu-icon {
					&:after {
						content: '';
						left: 5px;
						top: 5px;
						height: 12px;
						width: 12px;
						position: absolute;
						background-color: $white;
						z-index: -1;
					}
				}
			}
		}
		.empty {
			text-align: center;
			padding: #{$spacing-unit * 3}px 0;
		}
		.row {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include breakpoint-up('sm') {
				flex-wrap: nowrap;
				justify-content: unset;
			}

			align-items: center;
			padding: #{$spacing-unit}px;

			&:last-child {
				border-bottom: none;
			}
			.query {
				flex: 1;

				@include breakpoint-up('md') {
					padding-right: #{$spacing-unit * 6}px;
					flex: 0 0 30%;
				}

				a {
					overflow-wrap: anywhere;
				}
			}
			.tags {
				order: 3;
				flex: 1 0 100%;
				@include breakpoint-up('sm') {
					order: unset;
					flex: 1;
				}

				margin-top: #{$spacing-unit}px;
				@media only screen and (min-width: $screen-width-sm-min) {
					margin-top: 0;
				}

				&__tag {
					font-size: $typography-small;
					padding: 6px 8px;
					border-radius: 17px;
					line-height: 1;
					text-transform: capitalize;
					border: 0px;
				}
			}
			.menu {
				order: 2;
				@include breakpoint-up('sm') {
					order: unset;
				}
			}
		}
	}
}
