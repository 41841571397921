.filter-summary {
	display: flex;
	align-items: center;

	&__container {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&__filter-container {
			color: $font-light;
			line-height: 1.5;
			&__filter {
				&__value-container {
					&__value {
						cursor: default;
						text-decoration: none;

						&--underline {
							border-bottom: 1px dotted $border-foreground;
							cursor: pointer;
							&:hover {
								border-color: $font-light;
							}
						}
					}
				}
			}

			&:first-child {
				.filter-summary__container__filter-container__filter__connector {
					&:first-child {
						text-transform: capitalize;
					}
				}
			}
		}
	}
}

.tooltip-link {
	cursor: pointer;
	text-decoration: underline;
}

.filter-summary-tooltip {
	.filter-summary__container__filter-container {
		color: $app-background;
		&__filter {
			&__value-container {
				&__value {
					&--underline {
						border-color: $app-background;
					}
				}
			}
		}
	}
}
