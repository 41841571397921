.view {
	flex-direction: column;
	display: flex;
	&__header {
		border-bottom: 1px solid $border-foreground;
		background-color: $app-background;
		width: 100%;
		padding: #{$spacing-unit * 2.5}px #{$spacing-unit * 2}px #{$spacing-unit * 2.5}px #{$spacing-unit * 4}px;
		box-sizing: border-box;
		z-index: 1;
	}
	&__content {
		flex-grow: 1;
	}
}
