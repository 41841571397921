.user-avatar {
	display: flex;
	.image-upload-cropper__container {
		height: 150px;
		width: 150px;
	}
	.image-upload {
		&__image-container {
			&__tools {
				right: 8px;
				bottom: 8px;
			}
		}
	}
	.avatar {
		height: 146px;
		width: 146px;
		font-size: 2.5rem;
	}
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		margin-left: 20px;
		&__controls {
			> :not(:first-child) {
				margin-left: 20px;
			}
		}
	}
}
