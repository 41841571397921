.error-state {
	background: $app-background-inset;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	&__center {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		max-width: 380px;
		background: $app-background;
		border-radius: 5px;
		padding: 40px;

		&__logo {
			height: auto;
			width: 185px;
			margin-bottom: 40px;
		}

		&__details {
			font-size: 16px;
		}

		&__image-box {
			background-color: $app-background-inset;
			border-radius: 50%;
			width: 100%;
			max-width: 290px;
			margin-bottom: #{$spacing-unit * 3}px;

			img {
				max-height: 100%;
				max-width: 100%;
			}
		}

		a {
			color: $primary-color;
			cursor: pointer;
		}

		.select-container {
			text-align: left;
		}
	}
}
