.spike-alert {
	@include workspaceSettings;

	&__section-body {
		&__label {
			margin-bottom: #{$spacing-unit * 2}px;
		}
		.select-theme__label {
			font-size: $typography-body;
		}
		&__chart {
			padding-top: 0;

			&__drag-indicator {
				cursor: grab;
				line-height: 0;
				padding: 5px;
				border-radius: 100px;

				path {
					stroke: #fff;
				}
			}
		}

		&__chart-metrics {
			display: flex;
			flex-direction: column;

			> div {
				&:first-child {
					padding-bottom: #{$spacing-unit * 3}px;
				}
			}

			@include breakpoint-up('md') {
				flex-direction: row;

				> div {
					flex: 1 1 50%;
					&:first-child {
						margin-right: #{$spacing-unit * 5}px;
						margin-left: 0;
					}
				}
			}
		}

		&__filters {
			padding-bottom: #{$spacing-unit * 3}px;
			.filters-container {
				.add-filter-button {
					background-color: unset;
				}
			}
		}
	}
}
