.inbox {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__toolbar {
		border-width: 0px 0px 1px 0px;
		border-color: $border-foreground;
		border-style: solid;
		box-sizing: border-box;
		flex-shrink: 0;
	}

	&__content {
		flex-grow: 1;
		display: flex;
		flex-direction: row;

		.checkable-selection-toolbar__selection {
			.flex {
				max-width: 180px;
			}
			.inline--horizontal--left {
				max-width: 260px;
			}
		}
	}

	&__create_query {
		transition: max-height 0.6s ease, min-height 0.6s ease;
		max-height: 0;
		min-height: 0;
		overflow: hidden;
		&--open {
			max-height: 100%;
			transition: min-height 0.6s ease, max-height 0.6s ease;
		}
	}
}
