//$font-family: Roboto, 'IBM Plex Sans', sans-serif;
//$typography-h1: 1.75rem; //28px
//$typography-h2: 1.5rem; //24px
//$typography-h3: 1.25rem; //20px
//$typography-h4: 1.25rem; //20px
//$typography-h5: 1.125rem; //18px
//$typography-h6: .875rem; //14px
$typography-extra-large: 1rem; //16px
$typography-large: 0.875rem; //14px
$typography-body: 0.85rem; //13.5px
$typography-small: 0.8125rem; //13px
$typography-tiny: 0.75rem; //12px
$typography-extra-tiny: 0.625rem; //10px
//$typography-icon-lg: 1.75rem;
//$typography-icon-md: 1.25rem;
//$typography-icon-sm: 0.875rem;
//$typography-icon-xs: 0.5rem;
//$weight-bolder: 800;
//$weight-bold: 700;
//$weight-semi: 500;
//$weight-regular: 400;
//$weight-light: 300;

.typography {
	&--article-title {
		font-size: $typography-large;
		color: $font-color;
	}
	&--h1,
	&--h2,
	&--h3,
	&--h4,
	&--h5 {
		font-weight: $weight-bold;
	}
	&--body {
		font-size: $typography-body;
		color: $font-color;
	}
	&--h1,
	&--h2,
	&--h3,
	&--h4,
	&--h5,
	&--h6 {
		color: $font-color;
	}
	&--h5 {
		font-size: $typography-h5;
	}
	&--widget_tooltip {
		color: $app-background;
		font-size: $typography-body;
		padding: 4px;
	}

	a.uncolored-link {
		color: $font-color;
		&:hover {
			text-decoration: none;
		}
	}

	&--disabled {
		color: $font-disabled;
	}

	&--chart-label {
		font-weight: $weight-bold;
	}
}

.MuiTypography-colorTextPrimary {
	color: $font-color;
}

.MuiFormControlLabel-label {
	font-size: $typography-body;
	color: $font-color;
	&.Mui-disabled {
		color: $font-disabled;
	}
}
